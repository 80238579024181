import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useEffect, useMemo, useState } from "react"
import { useWindowDimensions } from "react-native"
import {
    Button,
    Checkbox,
    Div,
    Icon,
    Overlay,
    ScrollDiv,
    Text
} from "react-native-magnus"
import {
    deleteAccreditation,
    editAccreditation,
    getAccreditations,
    ITEMS_PER_PAGE
} from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import DataTable, { DataTableProps } from "../../../components/DataTable"
import HomeButton from "../../../components/HomeButton"
import SearchBar from "../../../components/SearchBar"
import {
    eventAccreditationFilterAtomStorage,
    isLoaderVisibleAtom,
    tokenAtomStorage,
    userRoleAtomStorage
} from "../../../context/atom"
import useRoleVerifier from "../../../hooks/useRoleVerifier"
import { t } from "../../../strings"
import {
    Accreditation,
    AccreditationStatus,
    Event,
    UserRole
} from "../../../types/prisma/index"
import { json2Csv } from "../../../utils/csv"
import { downloadFile } from "../../../utils/downloadFile"

export type BackOfficeAccreditationTableProps = undefined

type BackOfficeAccreditationTableScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "BackOfficeAccreditationTable"
>

const generaReport = (accreditationList: Accreditation[]) => {
    const selectedUsers = accreditationList?.map((accreditation) => {
        return {
            Nome: accreditation.user.firstName,
            Cognome: accreditation.user.lastName,
            Email: accreditation.user.email,
            "Tipologia Utente": accreditation.user.role,
            Freelancer: accreditation.user.isFreelancer ? "Sì" : "No",
            "Pubblicazione/Agenzia": accreditation.user.agencyName,
            "Richiede Pettorina": accreditation.hasRequestedBib ? "Sì" : "No",
            "Numero di Telefono": "'" + accreditation.user.phoneNumber
        }
    })
    const csv = json2Csv(selectedUsers)

    downloadFile(csv, "Export.csv", { type: "text/plain;charset=utf8" })
}

export const ReactiveCheckbox = ({
    w,
    onChange,
    disabled,
    isChecked,
    onPress,
    confirmationMessage
}: {
    w: number
    onChange: (value: boolean) => void
    disabled: boolean
    isChecked: boolean
    onPress?: (e: any) => void
    confirmationMessage?: string
}) => {
    const [value, setValue] = useState<boolean>(isChecked)

    useEffect(() => {
        setValue(isChecked)
    }, [isChecked])

    return (
        <Checkbox
            onPress={(e) => onPress?.(e)}
            w={w}
            disabled={disabled}
            value={value}
            checked={value}
            onChange={(_value) => {
                if (confirmationMessage) {
                    if (confirm(confirmationMessage)) {
                        onChange(!value)
                        setValue(!value)
                    }
                } else {
                    onChange(!value)
                    setValue(!value)
                }
            }}
        />
    )
}

const BackOfficeAccreditationTable = ({
    navigation,
    route
}: BackOfficeAccreditationTableScreenRouteProp) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)
    const [userRoleStorage] = useAtom(userRoleAtomStorage)

    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    const [isLoadMoreButtonVisible, setIsLoadMoreButtonVisible] =
        useState<boolean>(true)

    const [confirmedAccreditations, setConfirmedAccreditations] = useState<
        Accreditation[]
    >([])
    const [refusedAccreditations, setRefusedAccreditations] = useState<
        Accreditation[]
    >([])

    const [accreditationsList, setAccreditationsList] =
        useState<Accreditation[]>()

    const [eventChoosen, setEventChoosen] = useState<Event>()

    const [
        eventAccreditationFilterStorage,
        setEventAccreditationFilterStorage
    ] = useAtom(eventAccreditationFilterAtomStorage)

    const dimension = useWindowDimensions()

    const [dialog, setDialog] = useState<string>()

    const dataTableUser = useMemo(() => {
        const props: DataTableProps<
            Accreditation & {
                userEmail: undefined
                eventName: undefined
                lastName: undefined
                firstName: undefined
                agencyName: undefined
            }
        > = {
            keys: {
                id: () => "Id",
                eventName: () => t("event_name"),
                status: () => t("status"),
                hasRequestedBib: () => t("bib"),
                userEmail: () => t("email"),
                lastName: () => t("surname"),
                firstName: () => t("name"),
                agencyName: () => t("agency_name")
            },
            cells: {
                id: (e) => e.id?.toString() || "No Id",
                eventName: (e) => e.event.name || "No Event Name",
                status: (e) =>
                    e.status === AccreditationStatus.PENDING
                        ? t("pending")
                        : e.status === AccreditationStatus.APPROVED
                        ? t("approved")
                        : e.status === AccreditationStatus.DENIED
                        ? t("denied")
                        : e.status === AccreditationStatus.NEEDS_USER_UPDATE
                        ? t("needs_user_update")
                        : t("confirmed"),
                hasRequestedBib: (e) =>
                    e.hasRequestedBib === true ? t("yes") : t("no"),
                userEmail: (e) => e.user.email || "No Email",
                lastName: (e) => e.user.lastName || "No Last Name",
                firstName: (e) => e.user.firstName || "No First Name",
                agencyName: (e) =>
                    e.user.isFreelancer
                        ? t("freelance")
                        : e.user.agencyName
                        ? e.user.agencyName
                        : "N.D."
            },
            data: (accreditationsList || []).map((a) => ({
                ...a,
                userEmail: undefined,
                eventName: undefined,
                lastName: undefined,
                firstName: undefined,
                role: undefined,
                phoneNumber: undefined,
                agencyName: undefined
            }))
        }
        return <DataTable {...props} />
    }, [accreditationsList, confirmedAccreditations])

    const dataTableAdmin = useMemo(() => {
        const props: DataTableProps<
            Accreditation & {
                conferma: undefined
                modifica: undefined
                elimina: undefined
                userEmail: undefined
                eventName: undefined
                lastName: undefined
                firstName: undefined
                role: undefined
                phoneNumber: undefined
                agencyName: undefined
            }
        > = {
            keys: {
                conferma: () => t("confirm"),
                id: () => "Id",
                eventName: () => t("event_name"),
                userEmail: () => t("email"),
                lastName: () => t("surname"),
                firstName: () => t("name"),
                role: () => t("user_role"),
                hasRequestedBib: () => t("bib"),
                agencyName: () => t("agency_name"),
                status: () => t("status"),
                phoneNumber: () => t("phone"),
                modifica: () => t("edit"),
                elimina: () => t("delete")
            },
            cells: {
                conferma: (e) => (
                    <ReactiveCheckbox
                        w={80}
                        isChecked={Boolean(
                            confirmedAccreditations.find(
                                (_ca) => _ca.id == e.id
                            )
                        )}
                        disabled={userRoleStorage != UserRole.ADMIN}
                        onChange={(value) => {
                            if (value) {
                                setConfirmedAccreditations((_ca) => [..._ca, e])
                                setRefusedAccreditations((_ca) =>
                                    _ca.filter((_a) => _a.id !== e.id)
                                )
                            } else {
                                setConfirmedAccreditations((_ca) =>
                                    _ca.filter((_a) => _a.id !== e.id)
                                )
                                setRefusedAccreditations((_ca) => [..._ca, e])
                            }
                            if (e.id && tokenStorage) {
                                editAccreditation(e.id, tokenStorage, {
                                    isReadyForConfirmationEmail: value
                                })
                            }
                        }}
                    />
                ),
                id: (e) => e.id?.toString() || "No Id",
                eventName: (e) => e.event.name || "No Event Name",
                userEmail: (e) => (
                    <Text
                        w={200}
                        textDecorLine={"underline"}
                        onPress={() => {
                            navigation.navigate("BackOfficeUserManagement", {
                                userId: e.user.id
                            })
                        }}
                    >
                        {e.user.email}
                    </Text>
                ),
                lastName: (e) => e.user.lastName || "No Last Name",
                firstName: (e) => e.user.firstName || "No First Name",
                role: (e) => e.user.role?.replaceAll("_", " ") || "No Role",
                hasRequestedBib: (e) =>
                    e.hasRequestedBib ? t("yes") : t("no"),
                agencyName: (e) =>
                    (e.user.isFreelancer
                        ? t("freelance")
                        : e.user.agencyName) || "No Agency",
                status: (e) =>
                    e.status === AccreditationStatus.PENDING
                        ? t("pending")
                        : e.status === AccreditationStatus.APPROVED
                        ? t("approved")
                        : e.status === AccreditationStatus.DENIED
                        ? t("denied")
                        : e.status === AccreditationStatus.NEEDS_USER_UPDATE
                        ? t("needs_user_update")
                        : t("confirmed"),
                phoneNumber: (e) => e.user.phoneNumber,
                modifica: (e) => (
                    <Div pr={12}>
                        <Button
                            w={150}
                            disabled={userRoleStorage != UserRole.ADMIN}
                            onPress={() => {
                                navigation.navigate(
                                    "BackOfficeAccreditationManagement",
                                    {
                                        accreditationId: e.id
                                    }
                                )
                            }}
                        >
                            {t("edit")}
                        </Button>
                    </Div>
                ),
                elimina: (e) => (
                    <Div pr={12}>
                        <Button
                            w={150}
                            disabled={userRoleStorage != UserRole.ADMIN}
                            onPress={() => {
                                if (confirm("Sei Sicuro?")) {
                                    deleteAccreditation(e.id!, tokenStorage!)
                                    navigation.replace(
                                        "BackOfficeAccreditationTable"
                                    )
                                }
                            }}
                        >
                            {t("delete")}
                        </Button>
                    </Div>
                )
            },
            data: (accreditationsList || []).map((a, index) => ({
                ...a,
                conferma: undefined,
                modifica: undefined,
                elimina: undefined,
                userEmail: undefined,
                eventName: undefined,
                lastName: undefined,
                firstName: undefined,
                role: undefined,
                phoneNumber: undefined,
                agencyName: undefined
            }))
        }
        return <DataTable {...props} />
    }, [accreditationsList, confirmedAccreditations])

    const loadAccreditation = (eventId?: number) => {
        if (tokenStorage) {
            setIsLoaderVisible(true)

            getAccreditations(tokenStorage, { eventId, first: ITEMS_PER_PAGE })
                .then((res) => {
                    if (res.success) {
                        setAccreditationsList(res.data)
                        if (res.data.length < ITEMS_PER_PAGE) {
                            setIsLoadMoreButtonVisible(false)
                        }
                    }
                })
                .catch((e) => {
                    setDialog("Error: " + e)
                })
                .finally(() => {
                    setIsLoaderVisible(false)
                })
        }
    }

    useEffect(() => {
        if (!tokenStorage) {
            navigation.navigate("Login")
        }
    }, [tokenStorage])

    useEffect(() => {
        loadAccreditation(eventAccreditationFilterStorage?.id)
        setEventChoosen(eventAccreditationFilterStorage)
    }, [route.path])

    useEffect(() => {
        if (accreditationsList) {
            setRefusedAccreditations(
                accreditationsList.filter(
                    (_ac) => _ac.status !== AccreditationStatus.APPROVED
                )
            )

            setConfirmedAccreditations(
                accreditationsList.filter(
                    (_ac) => _ac.isReadyForConfirmationEmail === true
                )
            )
        }
    }, [accreditationsList])

    return (
        <ScrollDiv>
            <Content>
                <Overlay
                    visible={Boolean(dialog)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    setDialog(undefined)
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {dialog}
                        </Text>
                    </Div>
                </Overlay>
                <Text fontSize={52} fontWeight="bold" my={24}>
                    {userRoleStorage === UserRole.ADMIN
                        ? t("accreditations_management")
                        : t("accreditations_history")}
                </Text>

                <Div mb={12} row={dimension.width < 900 ? false : true}>
                    {userRoleStorage === UserRole.ADMIN &&
                        dimension.width < 530.0 && (
                            <HomeButton
                                w={dimension.width < 530 ? "100%" : undefined}
                                mt={
                                    dimension.width < 900 &&
                                    dimension.width > 530
                                        ? 12
                                        : undefined
                                }
                                mb={dimension.width < 530 ? 12 : undefined}
                                navigateHome={() => {
                                    navigation.navigate("BackOfficeHome")
                                }}
                            />
                        )}

                    {userRoleStorage === UserRole.ADMIN && (
                        <Button
                            w={dimension.width < 530 ? "100%" : undefined}
                            mb={dimension.width < 900 ? 12 : undefined}
                            mr={12}
                            fontWeight="bold"
                            fontSize="2xl"
                            alignSelf="flex-start"
                            style={{}}
                            onPress={() => {
                                navigation.navigate(
                                    "BackOfficeAccreditationManagement",
                                    {}
                                )
                            }}
                        >
                            {t("add")}
                        </Button>
                    )}

                    {confirmedAccreditations.length > 0 && eventChoosen && (
                        <Button
                            w={dimension.width < 530 ? "100%" : undefined}
                            mr={12}
                            mb={dimension.width < 900 ? 12 : undefined}
                            fontWeight="bold"
                            fontSize="2xl"
                            alignSelf="flex-start"
                            style={{}}
                            onPress={() => {
                                generaReport(confirmedAccreditations)
                            }}
                        >
                            Esporta
                        </Button>
                    )}

                    {userRoleStorage === UserRole.ADMIN && (
                        <Button
                            w={dimension.width < 530 ? "100%" : undefined}
                            mr={12}
                            fontWeight="bold"
                            fontSize="2xl"
                            alignSelf="flex-start"
                            style={{}}
                            onPress={() => {
                                if (accreditationsList && eventChoosen) {
                                    const newConfirmedAccreditations =
                                        accreditationsList.filter(
                                            (_ac) =>
                                                _ac.status ===
                                                AccreditationStatus.APPROVED
                                        )
                                    setConfirmedAccreditations(
                                        newConfirmedAccreditations
                                    )

                                    const newRefusedAccreditations =
                                        accreditationsList.filter(
                                            (_ac) =>
                                                _ac.status !==
                                                AccreditationStatus.APPROVED
                                        )
                                    setRefusedAccreditations(
                                        newRefusedAccreditations
                                    )

                                    for (const confirmedAccreditation of newConfirmedAccreditations) {
                                        if (
                                            tokenStorage &&
                                            confirmedAccreditation.id
                                        ) {
                                            editAccreditation(
                                                confirmedAccreditation.id,
                                                tokenStorage,
                                                {
                                                    isReadyForConfirmationEmail:
                                                        true
                                                }
                                            )
                                        }
                                    }

                                    for (const refusedAccreditation of newRefusedAccreditations) {
                                        if (
                                            tokenStorage &&
                                            refusedAccreditation.id
                                        ) {
                                            editAccreditation(
                                                refusedAccreditation.id,
                                                tokenStorage,
                                                {
                                                    isReadyForConfirmationEmail:
                                                        false
                                                }
                                            )
                                        }
                                    }
                                } else {
                                    setDialog(
                                        "Scegliere prima un evento tramite la barra di ricerca prima di confermare in automatico le richieste degli utenti approvati"
                                    )
                                }
                            }}
                        >
                            Seleziona Accrediti Approvati
                        </Button>
                    )}

                    {userRoleStorage !== UserRole.ADMIN ? (
                        <HomeButton
                            mt={
                                dimension.width < 900 && dimension.width > 530
                                    ? 12
                                    : undefined
                            }
                            navigateHome={() => {
                                navigation.navigate("Home")
                            }}
                        />
                    ) : dimension.width > 530.0 ? (
                        <HomeButton
                            mt={
                                dimension.width < 900 && dimension.width > 530
                                    ? 12
                                    : undefined
                            }
                            navigateHome={() => {
                                navigation.navigate("BackOfficeHome")
                            }}
                        />
                    ) : null}
                </Div>

                <Div mb={24} row={dimension.width < 900 ? false : true}>
                    {userRoleStorage === UserRole.ADMIN && (
                        <Button
                            disabled={confirmedAccreditations.length === 0}
                            mr={12}
                            mb={12}
                            w={dimension.width < 530 ? "100%" : undefined}
                            fontWeight="bold"
                            fontSize={dimension.width > 530 ? "2xl" : "1xl"}
                            alignSelf="flex-start"
                            style={{}}
                            onPress={() => {
                                if (
                                    eventChoosen &&
                                    accreditationsList &&
                                    confirmedAccreditations
                                ) {
                                    const userIds = confirmedAccreditations.map(
                                        (accreditation) => accreditation.userId
                                    )
                                    navigation.navigate(
                                        "BackOfficeClosingAccreditationConfirmed",
                                        {
                                            eventName: eventChoosen.name,
                                            eventStartTime:
                                                eventChoosen.startTime,
                                            eventEndTime: eventChoosen.endTime,
                                            userIds
                                        }
                                    )
                                } else {
                                    setDialog(
                                        "Scegliere prima un evento tramite la barra di ricerca prima di muoversi alla pagina di chiusura accrediti"
                                    )
                                }
                            }}
                        >
                            Invia Email per Chiusura Accrediti Confermati
                        </Button>
                    )}

                    {userRoleStorage === UserRole.ADMIN && (
                        <Button
                            disabled={refusedAccreditations.length === 0}
                            w={dimension.width < 530 ? "100%" : undefined}
                            fontWeight="bold"
                            fontSize={dimension.width > 530 ? "2xl" : "1xl"}
                            alignSelf="flex-start"
                            style={{}}
                            onPress={() => {
                                if (
                                    eventChoosen &&
                                    accreditationsList &&
                                    refusedAccreditations
                                ) {
                                    const userIds = refusedAccreditations.map(
                                        (accreditation) => accreditation.userId
                                    )
                                    navigation.navigate(
                                        "BackOfficeClosingAccreditationRefused",
                                        {
                                            eventName: eventChoosen.name,
                                            eventStartTime:
                                                eventChoosen.startTime,
                                            eventEndTime: eventChoosen.endTime,
                                            userIds
                                        }
                                    )
                                } else {
                                    setDialog(
                                        "Scegliere prima un evento tramite la barra di ricerca prima di muoversi alla pagina di chiusura accrediti"
                                    )
                                }
                            }}
                        >
                            Invia Email per Chiusura Accrediti Rifiutati
                        </Button>
                    )}
                </Div>

                <SearchBar
                    style={{ marginTop: 6 }}
                    label={
                        eventAccreditationFilterStorage
                            ? `${t("filter")}: ${
                                  eventAccreditationFilterStorage.name
                              } ×`
                            : t("filter_by_event")
                    }
                    searchType={"events"}
                    onChange={(q) => {
                        if (
                            q.length === 0 &&
                            !eventAccreditationFilterStorage
                        ) {
                            loadAccreditation()
                        }
                        setEventAccreditationFilterStorage(undefined)
                    }}
                    onClickLabel={() => {
                        setEventAccreditationFilterStorage(undefined)
                        loadAccreditation()
                    }}
                    result={(item) => (
                        <p
                            key={`data-search-suggestion-${item.id}`}
                            style={{
                                fontWeight: "bold",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                setEventAccreditationFilterStorage(item)

                                loadAccreditation(item.id)
                                setEventChoosen(item)
                            }}
                        >
                            <Text>{item.name}</Text>
                        </p>
                    )}
                />

                {userRoleStorage === UserRole.ADMIN
                    ? dataTableAdmin
                    : dataTableUser}

                {isLoadMoreButtonVisible && (
                    <Button
                        mt={12}
                        mb={12}
                        alignSelf="center"
                        onPress={() => {
                            if (tokenStorage) {
                                getAccreditations(tokenStorage, {
                                    first: ITEMS_PER_PAGE,
                                    eventId: eventChoosen?.id,
                                    lastAccreditationId:
                                        accreditationsList?.[
                                            accreditationsList.length - 1
                                        ].id
                                }).then((res) => {
                                    const newAccreditationsList = (
                                        accreditationsList || []
                                    ).concat(res.data)
                                    setAccreditationsList(newAccreditationsList)
                                    if (res.data.length < ITEMS_PER_PAGE) {
                                        setIsLoadMoreButtonVisible(false)
                                    }
                                })
                            }
                        }}
                    >
                        {t("load_more")}
                    </Button>
                )}
            </Content>
        </ScrollDiv>
    )
}

export default BackOfficeAccreditationTable
