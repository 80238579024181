import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import {
    Button,
    Checkbox,
    Div,
    Icon,
    Image,
    Overlay,
    ScrollDiv,
    Text
} from "react-native-magnus"
import {
    getEvent,
    getEventImage,
    requestAccreditation
} from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import HomeButton from "../../../components/HomeButton"
import Row from "../../../components/Row"
import {
    isLoaderVisibleAtom,
    tokenAtomStorage,
    userIdAtomStorage,
    userRoleAtomStorage
} from "../../../context/atom"
import { t } from "../../../strings"
import { Event, UserRole } from "../../../types/prisma/index"

export type MonzaEventProps = {
    eventId: number
}

type MonzaEventScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "MonzaEvent"
>

const MonzaEvent = ({ navigation, route }: MonzaEventScreenRouteProp) => {
    const [imageContainerWidth, setImageContainerWidth] = useState<number>(0)
    const [imgAspectRatio, setImgAspectRatio] = useState({
        w: 0,
        h: 0
    })

    const [tokenStorage] = useAtom(tokenAtomStorage)
    const [userRoleStorage] = useAtom(userRoleAtomStorage)
    const [userIdStorage] = useAtom(userIdAtomStorage)

    const [urlEventImage, setUrlEventImage] = useState<string>()

    const [monzaEvent, setMonzaEvent] = useState<Event>()
    const [hasRequestedBib, setHasRequestedBib] = useState<boolean>(false)

    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    const dimension = useWindowDimensions()

    const [
        comunicationsRequestAccreditation,
        setComunicationsRequestAccreditation
    ] = useState<string>()

    useEffect(() => {
        setImgAspectRatio({
            w: imageContainerWidth,
            h: (imageContainerWidth / 16) * 9
        })
    }, [imageContainerWidth])

    useEffect(() => {
        if (!tokenStorage) {
            return navigation.navigate("Login")
        }

        getEventImage(route.params.eventId, "EVENT_IMAGE").then((res) => {
            setUrlEventImage(res)
        })

        setIsLoaderVisible(true)

        getEvent(route.params.eventId, tokenStorage)
            .then((res) => {
                setMonzaEvent(res.data)
            })
            .finally(() => {
                setIsLoaderVisible(false)
            })
    }, [tokenStorage])

    return (
        <ScrollDiv>
            <Content>
                <Overlay
                    visible={Boolean(comunicationsRequestAccreditation)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    setComunicationsRequestAccreditation(
                                        undefined
                                    )
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {comunicationsRequestAccreditation}
                        </Text>
                    </Div>
                </Overlay>

                <Div w="100%" justifyContent="center" alignItems="center">
                    <Text fontSize={52} fontWeight="bold" my={24}>
                        {monzaEvent?.name || t("loading")}
                    </Text>

                    <Row rowStyles={{ alignItems: "center", marginBottom: 24 }}>
                        <Text fontSize={24}>
                            {monzaEvent?.description || t("loading")}
                        </Text>
                    </Row>

                    <Row
                        rowStyles={{
                            justifyContent: "center",
                            textAlign: "center",
                            padding: 0,
                            marginBottom: 12,
                            width: "90vw",
                            maxWidth: 912,
                            height: "auto"
                        }}
                        onLayout={({ nativeEvent }) => {
                            setImageContainerWidth(nativeEvent.layout.width)
                        }}
                    >
                        <Image
                            style={{ borderRadius: 25 }}
                            w={imgAspectRatio.w}
                            h={imgAspectRatio.h}
                            source={{ uri: urlEventImage }}
                        />
                    </Row>

                    <Row
                        rowStyles={{
                            alignItems: "center",
                            marginBottom: 24,
                            marginTop: 16
                        }}
                    >
                        <Text fontSize={16} textAlign={"center"}>
                            {monzaEvent?.notes || t("loading")}
                        </Text>
                    </Row>

                    {tokenStorage && (
                        <Div justifyContent="center">
                            {userRoleStorage === UserRole.MEDIA_PHOTOGRAPHER ||
                            userRoleStorage ===
                                UserRole.MEDIA_VIDEO_OPERATOR ? (
                                <Checkbox
                                    alignSelf="center"
                                    mb="xl"
                                    value={hasRequestedBib}
                                    onChecked={() => {
                                        setHasRequestedBib(!hasRequestedBib)
                                    }}
                                    suffix={
                                        <Text
                                            flex={1}
                                            fontSize={
                                                dimension.width < 430 ? 20 : 24
                                            }
                                            fontWeight={"bold"}
                                            ml={6}
                                        >
                                            {t("request_bib")}
                                        </Text>
                                    }
                                />
                            ) : null}

                            <Button
                                mb={16}
                                fontSize="2xl"
                                fontWeight="bold"
                                alignSelf="center"
                                maxW={"100%"}
                                onPress={() => {
                                    if (userIdStorage && tokenStorage) {
                                        setIsLoaderVisible(true)

                                        requestAccreditation(
                                            {
                                                user: {
                                                    connect: {
                                                        id: userIdStorage
                                                    }
                                                },
                                                event: {
                                                    connect: {
                                                        id: Number(
                                                            route.params.eventId
                                                        )
                                                    }
                                                },
                                                hasRequestedBib
                                            },
                                            tokenStorage
                                        )
                                            .then((res) => {
                                                if (res.success) {
                                                    setComunicationsRequestAccreditation(
                                                        `${t("done")} ${t(
                                                            "check_email_for_event"
                                                        )}`
                                                    )
                                                    return
                                                }
                                            })
                                            .catch((err) => {
                                                if (
                                                    err === "EVENT NOT ACTIVE"
                                                ) {
                                                    const actualDate =
                                                        new Date()
                                                    let accreditationStartTimeDate =
                                                        new Date(
                                                            monzaEvent!.accreditationStartTime
                                                        )
                                                    let accreditationEndTimeDate =
                                                        new Date(
                                                            monzaEvent!.accreditationEndTime
                                                        )

                                                    if (
                                                        monzaEvent &&
                                                        actualDate.getTime() >
                                                            accreditationEndTimeDate.getTime()
                                                    ) {
                                                        setComunicationsRequestAccreditation(
                                                            t(
                                                                "event_accreditation_closed"
                                                            )
                                                        )
                                                    } else if (
                                                        monzaEvent &&
                                                        actualDate.getTime() <
                                                            accreditationStartTimeDate.getTime()
                                                    ) {
                                                        setComunicationsRequestAccreditation(
                                                            t(
                                                                "event_not_opened_yet"
                                                            )
                                                        )
                                                    }
                                                } else if (
                                                    err === "USER NOT VALIDATED"
                                                ) {
                                                    setComunicationsRequestAccreditation(
                                                        t("user_not_validated")
                                                    )
                                                } else if (
                                                    err === "UNAUTHORIZED"
                                                ) {
                                                    setComunicationsRequestAccreditation(
                                                        t("unauthorized")
                                                    )
                                                } else if (
                                                    err === "DUPLICATED RECORD"
                                                ) {
                                                    setComunicationsRequestAccreditation(
                                                        t(
                                                            "already_accreditated"
                                                        )
                                                    )
                                                } else if (
                                                    err ===
                                                    "DOCUMENT CAUSE OF DEATH EXPIRY DATE NOT MET"
                                                ) {
                                                    setComunicationsRequestAccreditation(
                                                        t(
                                                            "doc_cause_of_death_expiry_date_not_met"
                                                        )
                                                    )
                                                } else if (
                                                    err ===
                                                    "DOCUMENT INJURIES EXPIRY DATE NOT MET"
                                                ) {
                                                    setComunicationsRequestAccreditation(
                                                        t(
                                                            "doc_injuries_expiry_date_not_met"
                                                        )
                                                    )
                                                } else if (
                                                    err ===
                                                    "DOCUMENT RC EXPIRY DATE NOT MET"
                                                ) {
                                                    setComunicationsRequestAccreditation(
                                                        t(
                                                            "doc_rc_expiry_date_not_met"
                                                        )
                                                    )
                                                } else if (
                                                    err ===
                                                    "DOCUMENT IDENTITY EXPIRY DATE NOT MET"
                                                ) {
                                                    setComunicationsRequestAccreditation(
                                                        t(
                                                            "doc_identity_expiry_date_not_met"
                                                        )
                                                    )
                                                } else {
                                                    setComunicationsRequestAccreditation(
                                                        t(
                                                            "request_accreditation_generic_error"
                                                        ) + err
                                                    )
                                                }
                                            })
                                            .finally(() => {
                                                setIsLoaderVisible(false)
                                            })
                                    }
                                }}
                            >
                                {t("request_accreditation")}
                            </Button>

                            <HomeButton
                                mb={24}
                                alignSelf="center"
                                navigateHome={() => {
                                    navigation.navigate("Home")
                                }}
                            />
                        </Div>
                    )}
                </Div>
            </Content>
        </ScrollDiv>
    )
}

export default MonzaEvent
