import { atom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { Event, UserRole } from "../types/prisma"

export const isLoaderVisibleAtom = atom<boolean>(false)

export const userFirstNameAtomStorage = atomWithStorage<string | undefined>(
    "userFirstName",
    undefined
)
export const tokenAtomStorage = atomWithStorage<string | undefined>(
    "token",
    undefined
)
export const userRoleAtomStorage = atomWithStorage<UserRole | undefined>(
    "userRole",
    undefined
)
export const userIdAtomStorage = atomWithStorage<number | undefined>(
    "userId",
    undefined
)
export const userFilterAtomStorage = atomWithStorage<string | undefined>(
    "userFilter",
    undefined
)
export const eventFilterAtomStorage = atomWithStorage<number | undefined>(
    "eventFilter",
    undefined
)
export const eventAccreditationFilterAtomStorage = atomWithStorage<
    Event | undefined
>("eventAccreditationFilter", undefined)
