import { useAtom } from "jotai"
import React from "react"
import { ActivityIndicator } from "react-native"
import { Div, Text } from "react-native-magnus"
import { isLoaderVisibleAtom } from "../context/atom"
import { t } from "../strings"

function Loader() {
    const [isLoaderVisible] = useAtom(isLoaderVisibleAtom)

    return !isLoaderVisible ? null : (
        <Div
            style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                zIndex: 999,
                backgroundColor: "rgba(255, 255, 255, 0.5)"
            }}
        >
            <ActivityIndicator size={56} />
            <Text mt="xl" textAlign="center" fontSize={24} color="#424242">
                {t("loading")}
            </Text>
        </Div>
    )
}

export default Loader
