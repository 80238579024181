import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useEffect } from "react"
import { useWindowDimensions } from "react-native"
import { Button, Div, Icon, Image, ScrollDiv, Text } from "react-native-magnus"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import Notifications from "../../../components/Notifications"
import {
    eventAccreditationFilterAtomStorage,
    eventFilterAtomStorage,
    tokenAtomStorage,
    userFilterAtomStorage,
    userFirstNameAtomStorage,
    userIdAtomStorage,
    userRoleAtomStorage
} from "../../../context/atom"
import useRoleVerifier from "../../../hooks/useRoleVerifier"
import { toCapitalCase } from "../../../utils/capitalCaser"

export type BackOfficeHomeProps = undefined

type BackOfficeHomeScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "BackOfficeHome"
>

const BackOfficeHome = ({ navigation }: BackOfficeHomeScreenRouteProp) => {
    const windowDimensions = useWindowDimensions()
    const [tokenStorage, setTokenStorage] = useAtom(tokenAtomStorage)
    const [userRoleStorage, setUserRoleStorage] = useAtom(userRoleAtomStorage)
    const [userFirstNameStorage, setUserFirstNameStorage] = useAtom(
        userFirstNameAtomStorage
    )
    const [userIdStorage, setUserIdStorage] = useAtom(userIdAtomStorage)
    const [userFilterStorage, setUserFilterStorage] = useAtom(
        userFilterAtomStorage
    )
    const [eventFilterStorage, setEventFilterStorage] = useAtom(
        eventFilterAtomStorage
    )
    const [
        eventAccreditationFilterStorage,
        setEventAccreditationFilterStorage
    ] = useAtom(eventAccreditationFilterAtomStorage)

    const dimension = useWindowDimensions()

    useEffect(() => {
        if (!tokenStorage) {
            navigation.navigate("Login")
        }
    }, [tokenStorage])

    useRoleVerifier()

    return (
        <ScrollDiv h="100%">
            <Div h="100%" w="100%" alignSelf="center">
                <Image
                    w="100%"
                    h={windowDimensions.height * 0.3}
                    resizeMode="cover"
                    source={require("../../../assets/home-header.jpg")}
                />

                <Content style={{ alignItems: "center" }}>
                    <Div
                        w={"100%"}
                        justifyContent="space-between"
                        alignItems="center"
                        row={dimension.width < 530 ? false : true}
                    >
                        <Div alignItems="center">
                            {userFirstNameStorage && (
                                <Text
                                    mt="xl"
                                    w="100%"
                                    fontWeight="bold"
                                    fontSize="5xl"
                                >
                                    Ciao {toCapitalCase(userFirstNameStorage)}
                                </Text>
                            )}
                            <Text
                                mt="md"
                                w="100%"
                                fontSize="lg"
                                color="gray600"
                            >
                                Cosa vuoi fare?
                            </Text>
                        </Div>

                        <Div row>
                            <Notifications
                                wrapperStyle={{ marginRight: 8 }}
                                buttonStyle={{ height: 49.6 }}
                            />

                            <Button
                                mt="xl"
                                mr={8}
                                fontWeight="bold"
                                fontSize="2xl"
                                alignSelf="flex-end"
                                onPress={() => {
                                    if (tokenStorage) {
                                        navigation.navigate("UserSettings")
                                    }
                                }}
                            >
                                Profilo
                            </Button>
                            <Button
                                mt="xl"
                                fontWeight="bold"
                                fontSize="2xl"
                                alignSelf="flex-end"
                                onPress={() => {
                                    setTokenStorage(undefined)
                                    setUserRoleStorage(undefined)
                                    setUserFirstNameStorage(undefined)
                                    setUserIdStorage(undefined)
                                    setUserFilterStorage(undefined)
                                    setEventFilterStorage(undefined)
                                    setEventAccreditationFilterStorage(
                                        undefined
                                    )

                                    navigation.navigate("Login")
                                }}
                            >
                                Esci
                            </Button>
                        </Div>
                    </Div>

                    <Div w={"100%"}>
                        <Button
                            w={"100%"}
                            h={100}
                            rounded="2xl"
                            mt="xl"
                            shadow="md"
                            block
                            bg="blue700"
                            onPress={() => {
                                navigation.navigate("BackOfficeUserTable")
                            }}
                        >
                            <Div
                                row
                                h={"100%"}
                                flex={1}
                                justifyContent="space-between"
                                alignItems="center"
                                px="xl"
                            >
                                <Text
                                    color="white"
                                    fontWeight="bold"
                                    fontSize={24}
                                >
                                    Gestione Utenti
                                </Text>

                                <Icon
                                    name="users"
                                    fontFamily="FontAwesome5"
                                    fontSize={32}
                                    color="white"
                                    h={60}
                                    w={60}
                                    rounded="md"
                                />
                            </Div>
                        </Button>
                        <Button
                            w={"100%"}
                            h={100}
                            rounded="2xl"
                            mt="xl"
                            block
                            bg="red700"
                            shadow="md"
                            onPress={() => {
                                navigation.navigate("BackOfficeEventTable")
                            }}
                        >
                            <Div
                                row
                                h={"100%"}
                                flex={1}
                                justifyContent="space-between"
                                alignItems="center"
                                px="xl"
                            >
                                <Text
                                    color="white"
                                    fontWeight="bold"
                                    fontSize={24}
                                >
                                    Gestione Eventi
                                </Text>

                                <Icon
                                    name="calendar-alt"
                                    fontFamily="FontAwesome5"
                                    fontSize={32}
                                    color="white"
                                    h={60}
                                    w={60}
                                    rounded="md"
                                />
                            </Div>
                        </Button>
                        <Button
                            w={"100%"}
                            h={100}
                            rounded="2xl"
                            mt="xl"
                            block
                            bg="gray700"
                            shadow="md"
                            onPress={() => {
                                navigation.navigate(
                                    "BackOfficeAccreditationTable"
                                )
                            }}
                        >
                            <Div
                                row
                                h={"100%"}
                                flex={1}
                                justifyContent="space-between"
                                alignItems="center"
                                px="xl"
                            >
                                <Text
                                    color="white"
                                    fontWeight="bold"
                                    fontSize={24}
                                >
                                    Gestione Accrediti
                                </Text>

                                <Icon
                                    name="user-check"
                                    fontFamily="FontAwesome5"
                                    fontSize={32}
                                    color="white"
                                    h={60}
                                    w={60}
                                    rounded="md"
                                />
                            </Div>
                        </Button>
                    </Div>
                </Content>
            </Div>
        </ScrollDiv>
    )
}

export default BackOfficeHome
