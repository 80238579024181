import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import {
    Badge,
    Button,
    Collapse,
    Div,
    Icon,
    Overlay,
    Text
} from "react-native-magnus"
import { getNotifications, UserFiles } from "../api/fetches"
import {
    tokenAtomStorage,
    userFirstNameAtomStorage,
    userIdAtomStorage,
    userRoleAtomStorage
} from "../context/atom"
import { t } from "../strings"
import { User, UserRole } from "../types/prisma"

export interface Notification {
    type: UserFiles
    expiringDate: Date
    user: Pick<User, "id" | "firstName" | "lastName">
}

interface IProps {
    wrapperStyle?: any
    buttonStyle?: any
    overlayStyle?: any
}

function Notifications(props: IProps) {
    const [tokenStorage, setTokenStorage] = useAtom(tokenAtomStorage)
    const [userRoleStorage, setUserRoleStorage] = useAtom(userRoleAtomStorage)
    const [userFirstNameStorage, setUserFirstNameStorage] = useAtom(
        userFirstNameAtomStorage
    )

    const [userIdStorage, setUserIdStorage] = useAtom(userIdAtomStorage)
    const [notificationsOverlayVisible, setNotificationsOverlayVisible] =
        useState<boolean>()
    const [notifications, setNotifications] = useState<
        Map<number, Notification[]>
    >(new Map())

    const dimension = useWindowDimensions()

    useEffect(() => {
        if (tokenStorage) {
            getNotifications(tokenStorage).then((res) => {
                const newNotifications: Array<Notification> = res.data
                const userToNotifications = new Map<number, Notification[]>()

                for (const notification of newNotifications) {
                    if (!userToNotifications.has(notification.user.id!)) {
                        userToNotifications.set(notification.user.id!, [])
                    }
                    userToNotifications
                        .get(notification.user.id!)
                        ?.push(notification)
                }

                setNotifications(userToNotifications)
            })
        }
    }, [])

    return (
        <>
            <Div style={props.wrapperStyle}>
                <Button
                    style={props.buttonStyle}
                    mt={24}
                    h={props.buttonStyle.height}
                    alignSelf={
                        dimension.width < 530 ? "flex-start" : "flex-end"
                    }
                    w={dimension.width < 530 ? "100%" : undefined}
                    onPress={() => {
                        setNotificationsOverlayVisible(true)
                    }}
                >
                    {dimension.width < 530 ? (
                        <Text color="white" fontSize={16} mr={8}>
                            Notifiche
                        </Text>
                    ) : null}
                    {Array.from(notifications.keys()).length ? (
                        <Badge
                            fontSize={16}
                            bg="red500"
                            right={-8}
                            top={-10}
                            h={10}
                            w={10}
                        >
                            <Icon
                                name="bell"
                                color="white"
                                fontFamily="FontAwesome5"
                                fontSize="16px"
                            />
                        </Badge>
                    ) : (
                        <Icon
                            name="bell"
                            color="white"
                            fontFamily="FontAwesome5"
                            fontSize="16px"
                        />
                    )}
                </Button>
            </Div>

            <Overlay
                visible={notificationsOverlayVisible}
                p="xl"
                w={"80%"}
                style={{ overflow: "scroll", maxHeight: "80vh", maxWidth: 768 }}
            >
                {notifications ? (
                    userRoleStorage === UserRole.ADMIN ? (
                        <Div>
                            <Div
                                row
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text mr={12} fontSize={18} fontWeight="bold">
                                    Notifiche di ogni Utente con Date di
                                    Scadenza Assicurazioni
                                </Text>
                                <Button
                                    bg="transparent"
                                    alignSelf="flex-end"
                                    onPress={() => {
                                        setNotificationsOverlayVisible(false)
                                    }}
                                >
                                    <Icon
                                        name="times"
                                        color="#424242"
                                        fontFamily="FontAwesome5"
                                        fontSize="16px"
                                    />
                                </Button>
                            </Div>

                            {Array.from(notifications.keys()).length == 0 ? (
                                <Text>Nessuna nuova notifica ✅</Text>
                            ) : (
                                Array.from(notifications.keys()).map((key) => {
                                    const notesOfUser = notifications.get(
                                        Number(key)
                                    )

                                    return (
                                        <Collapse>
                                            <Collapse.Header
                                                active
                                                color="white"
                                                fontWeight="bold"
                                                fontSize="md"
                                                p="xl"
                                                px="md"
                                                style={{ height: 16 }}
                                            >
                                                {notesOfUser &&
                                                    notesOfUser[0].user
                                                        .firstName +
                                                        " " +
                                                        notesOfUser[0].user
                                                            .lastName}
                                            </Collapse.Header>
                                            <Collapse.Body pb="xl" bg="#d7d7d7">
                                                {notesOfUser?.map((note) => {
                                                    return (
                                                        <Text>
                                                            {note.type?.replaceAll?.(
                                                                "_",
                                                                " "
                                                            )}{" "}
                                                            –{" "}
                                                            {new Date(
                                                                note.expiringDate
                                                            ).toLocaleDateString()}
                                                        </Text>
                                                    )
                                                })}
                                            </Collapse.Body>
                                        </Collapse>
                                    )
                                })
                            )}
                        </Div>
                    ) : (
                        <>
                            <Div
                                row
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text mr={12} fontSize={18} fontWeight="bold">
                                    {t("notifications")}
                                </Text>
                                <Button
                                    alignSelf="flex-end"
                                    onPress={() => {
                                        setNotificationsOverlayVisible(false)
                                    }}
                                >
                                    <Icon
                                        name="times"
                                        color="white"
                                        fontFamily="FontAwesome5"
                                        fontSize="16px"
                                    />
                                </Button>
                            </Div>
                            {Array.from(notifications.keys()).length > 0 ? (
                                Array.from(notifications.keys()).map((key) => {
                                    const notifs = notifications.get(key)

                                    return notifs?.map((note) => {
                                        return (
                                            <Text>
                                                {(note.type ===
                                                UserFiles.CAUSE_OF_DEATH
                                                    ? t("doc_cause_of_death")
                                                    : note.type ===
                                                      UserFiles.INJURIES
                                                    ? t("doc_injuries")
                                                    : note.type === UserFiles.RC
                                                    ? t("doc_rc")
                                                    : null) +
                                                    " " +
                                                    (!note.expiringDate
                                                        ? t("not_set")
                                                        : new Date(
                                                              note.expiringDate
                                                          ).toLocaleDateString())}
                                            </Text>
                                        )
                                    })
                                })
                            ) : (
                                <Text fontSize={20} mt={16}>
                                    {t("no_notifications")}
                                </Text>
                            )}
                        </>
                    )
                ) : null}
            </Overlay>
        </>
    )
}

export default Notifications
