import React, { useEffect, useState } from "react"
import { Button, Div, Image, Text } from "react-native-magnus"
import { getEventImage } from "../api/fetches"
import { t } from "../strings"
import { Event } from "../types/prisma"
import Row from "./Row"

interface IProps {
    event: Event
    navigateToEventScreen: (monzaEvent: Event) => void
}

const EventToShow = ({ event, navigateToEventScreen }: IProps) => {
    const [imageUrl, setImageUrl] = useState<string>()

    useEffect(() => {
        if (!event.id) {
            return
        }
        getEventImage(event.id, "EVENT_IMAGE").then(setImageUrl)
    }, [event])

    return (
        <Div>
            <Row rowStyles={{ height: "auto", marginBottom: 12 }}>
                <Text fontSize={32} fontWeight="bold" w={"100%"}>
                    {event.name}
                </Text>
            </Row>

            <Row rowStyles={{ height: "auto", marginBottom: 12 }}>
                <Image
                    bg="#ddd"
                    source={{ uri: imageUrl }}
                    w="100%"
                    h={200}
                    style={{ borderRadius: 10 }}
                />
            </Row>

            <Row rowStyles={{ height: "auto", marginBottom: 12 }}>
                <Text fontSize={12}>{event.description}</Text>
            </Row>

            <Row rowStyles={{ height: "auto", marginBottom: 12 }}>
                <Button
                    alignSelf="center"
                    onPress={() => {
                        navigateToEventScreen(event)
                    }}
                >
                    {t("go_to_event")}
                </Button>
            </Row>
        </Div>
    )
}

export default EventToShow
