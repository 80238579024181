import { NavigationContainer } from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { Provider } from "jotai"
import React from "react"
import { ThemeProvider } from "react-native-magnus"
import Loader from "./components/Loader"
import useTokenExpirationVerifier from "./hooks/useTokenExpirationVerifier"
import BackOfficeAccreditationManagement, {
    BackOfficeAccreditationManagementTableProps
} from "./screens/BackOffice/BackOfficeAccreditationManagement"
import BackOfficeAccreditationTable, {
    BackOfficeAccreditationTableProps
} from "./screens/BackOffice/BackOfficeAccreditationTable"
import BackOfficeClosingAccreditationConfirmed, {
    BackOfficeClosingAccreditationConfirmedProps
} from "./screens/BackOffice/BackOfficeClosingAccreditationConfirmed"
import BackOfficeClosingAccreditationRefused, {
    BackOfficeClosingAccreditationRefusedProps
} from "./screens/BackOffice/BackOfficeClosingAccreditationRefused"
import BackOfficeEventManagement, {
    BackOfficeEventManagementTableProps
} from "./screens/BackOffice/BackOfficeEventManagement"
import BackOfficeEventTable, {
    BackOfficeEventTableProps
} from "./screens/BackOffice/BackOfficeEventTable"
import BackOfficeHome, {
    BackOfficeHomeProps
} from "./screens/BackOffice/BackOfficeHome"
import BackOfficeUserManagement, {
    BackOfficeUserManagementTableProps
} from "./screens/BackOffice/BackOfficeUserManagement"
import BackOfficeUserTable, {
    BackOfficeUserTableProps
} from "./screens/BackOffice/BackOfficeUserTable"
import ForgotPassword, {
    ForgotPasswordProps
} from "./screens/UserFrontend/ForgotPassword"
import ForgotPasswordUpdate, {
    ForgotPasswordUpdateProps
} from "./screens/UserFrontend/ForgotPasswordUpdate"
import Home, { HomeProps } from "./screens/UserFrontend/Home"
import Login, { LoginProps } from "./screens/UserFrontend/Login"
import MonzaEvent, { MonzaEventProps } from "./screens/UserFrontend/MonzaEvent"
import SignUp, { SignUpProps } from "./screens/UserFrontend/SignUp"
import Splash, { SplashProps } from "./screens/UserFrontend/Splash"
import { ThanksPage, ThanksPageProps } from "./screens/UserFrontend/ThanksPage"
import UserSettings, { UserSettingsProps } from "./screens/UserSettings"

export const DEBUG = true

export type RootStackParamList = {
    Splash: SplashProps
    Login: LoginProps
    SignUp: SignUpProps
    ThanksPage: ThanksPageProps
    Home: HomeProps
    UserSettings: UserSettingsProps
    MonzaEvent: MonzaEventProps
    BackOfficeHome: BackOfficeHomeProps
    BackOfficeUserTable: BackOfficeUserTableProps
    BackOfficeEventTable: BackOfficeEventTableProps
    BackOfficeAccreditationTable: BackOfficeAccreditationTableProps
    BackOfficeUserManagement: BackOfficeUserManagementTableProps
    BackOfficeEventManagement: BackOfficeEventManagementTableProps
    BackOfficeAccreditationManagement: BackOfficeAccreditationManagementTableProps
    BackOfficeClosingAccreditationConfirmed: BackOfficeClosingAccreditationConfirmedProps
    BackOfficeClosingAccreditationRefused: BackOfficeClosingAccreditationRefusedProps
    ForgotPassword: ForgotPasswordProps
    ForgotPasswordUpdate: ForgotPasswordUpdateProps
}

const Stack = createNativeStackNavigator<RootStackParamList>()

export default function App() {
    useTokenExpirationVerifier()

    return (
        <Provider>
            <ThemeProvider>
                <Loader />

                <NavigationContainer
                    linking={{
                        prefixes: ["monzapass://"]
                    }}
                >
                    <Stack.Navigator initialRouteName="Splash">
                        <Stack.Screen
                            name="Splash"
                            component={Splash}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="Login"
                            component={Login}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="ForgotPassword"
                            component={ForgotPassword}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="ForgotPasswordUpdate"
                            component={ForgotPasswordUpdate}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="SignUp"
                            component={SignUp}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="ThanksPage"
                            component={ThanksPage}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="Home"
                            component={Home}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="UserSettings"
                            component={UserSettings}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="MonzaEvent"
                            component={MonzaEvent}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="BackOfficeHome"
                            component={BackOfficeHome}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="BackOfficeUserTable"
                            component={BackOfficeUserTable}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="BackOfficeEventTable"
                            component={BackOfficeEventTable}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="BackOfficeAccreditationTable"
                            component={BackOfficeAccreditationTable}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="BackOfficeUserManagement"
                            component={BackOfficeUserManagement}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="BackOfficeEventManagement"
                            component={BackOfficeEventManagement}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="BackOfficeAccreditationManagement"
                            component={BackOfficeAccreditationManagement}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="BackOfficeClosingAccreditationConfirmed"
                            component={BackOfficeClosingAccreditationConfirmed}
                            options={{ header: () => null }}
                        />
                        <Stack.Screen
                            name="BackOfficeClosingAccreditationRefused"
                            component={BackOfficeClosingAccreditationRefused}
                            options={{ header: () => null }}
                        />
                    </Stack.Navigator>
                </NavigationContainer>
            </ThemeProvider>
        </Provider>
    )
}
