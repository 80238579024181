import { Picker } from "@react-native-picker/picker"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import {
    Button,
    Collapse,
    Div,
    Icon,
    Input,
    Overlay,
    ScrollDiv,
    Text
} from "react-native-magnus"
import {
    downloadUserFiles,
    editUser,
    getUser,
    getUserFileExistance,
    signUp,
    uploadUserFiles,
    UserFiles
} from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Col from "../../../components/Col"
import Content from "../../../components/Content"
import DateTimePicker from "../../../components/DateTimePicker"
import HomeButton from "../../../components/HomeButton"
import Row from "../../../components/Row"
import {
    isLoaderVisibleAtom,
    tokenAtomStorage,
    userIdAtomStorage
} from "../../../context/atom"
import useRoleVerifier from "../../../hooks/useRoleVerifier"
import { t } from "../../../strings"
import { User, UserRole } from "../../../types/prisma/index"
import { pickFile } from "../../UserSettings"

export enum EntityWork {
    AGENCY = "AGENCY",
    FREELANCER = "FREELANCE"
}

interface IProps {
    userId?: number
}

export type BackOfficeUserManagementTableProps = IProps

type BackOfficeUserManagementTableScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "BackOfficeUserManagement"
>

const BackOfficeUserManagement = ({
    navigation,
    route
}: BackOfficeUserManagementTableScreenRouteProp) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)
    const [userIdStorage] = useAtom(userIdAtomStorage)

    const [docIdentityFrontExist, setDocIdentityFrontExist] =
        useState<boolean>()
    const [docIdentityBackExist, setDocIdentityBackExist] = useState<boolean>()
    const [docCauseOfDeathExist, setDocCauseOfDeathExist] = useState<boolean>()
    const [docInjuriesExist, setDocInjuriesExist] = useState<boolean>()
    const [docRcExist, setDocRcExist] = useState<boolean>()

    const [
        identityDocFrontUploadPercentage,
        setIdentityDocFrontUploadPercentage
    ] = useState<number>()
    const [
        identityDocBackUploadPercentage,
        setIdentityDocBackUploadPercentage
    ] = useState<number>()
    const [
        causeOfDeathDocUploadPercentage,
        setCauseOfDeathDocUploadPercentage
    ] = useState<number>()
    const [injuriesDocUploadPercentage, setInjuriesDocUploadPercentage] =
        useState<number>()
    const [rcDocUploadPercentage, setRcDocUploadPercentage] = useState<number>()

    const [identityDocFrontPreviewUrl, setIdentityDocFrontPreviewUrl] =
        useState<string>()
    const [identityDocBackPreviewUrl, setIdentityDocBackPreviewUrl] =
        useState<string>()
    const [causeOfDeathDocPreviewUrl, setCauseOfDeathDocPreviewUrl] =
        useState<string>()
    const [injuriesDocPreviewUrl, setInjuriesDocPreviewUrl] = useState<string>()
    const [rcDocPreviewUrl, setRcDocPreviewUrl] = useState<string>()

    const [newUser, setNewUser] = useState<Partial<User>>({
        role: UserRole.ADMIN
    })
    const [newPublishedUrl, setNewPublishedUrl] = useState<string>()

    const [dialog, setDialog] = useState<string>()

    const dimension = useWindowDimensions()

    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    useEffect(() => {
        if (route.params.userId && tokenStorage) {
            setIsLoaderVisible(true)

            getUser(route.params.userId, tokenStorage)
                .then((res) => {
                    setNewUser(res.data)
                })
                .finally(async () => {
                    await new Promise((res) => setTimeout(res, 1000))
                    setIsLoaderVisible(false)
                })

            getUserFileExistance(
                route.params.userId,
                tokenStorage,
                UserFiles.IDENTITY_DOCUMENT_FRONT
            ).then((res) => {
                setDocIdentityFrontExist(res.data?.exists)
            })

            getUserFileExistance(
                route.params.userId,
                tokenStorage,
                UserFiles.IDENTITY_DOCUMENT_BACK
            ).then((res) => {
                setDocIdentityBackExist(res.data?.exists)
            })

            getUserFileExistance(
                route.params.userId,
                tokenStorage,
                UserFiles.CAUSE_OF_DEATH
            ).then((res) => {
                setDocCauseOfDeathExist(res.data?.exists)
            })

            getUserFileExistance(
                route.params.userId,
                tokenStorage,
                UserFiles.INJURIES
            ).then((res) => {
                setDocInjuriesExist(res.data?.exists)
            })

            getUserFileExistance(
                route.params.userId,
                tokenStorage,
                UserFiles.RC
            ).then((res) => {
                setDocRcExist(res.data?.exists)
            })

            downloadUserFiles(
                route.params.userId,
                tokenStorage,
                UserFiles.IDENTITY_DOCUMENT_FRONT
            ).then((res) => {
                const fileUrl = URL.createObjectURL(res)
                if (res) {
                    setIdentityDocFrontPreviewUrl(fileUrl)
                } else {
                    alert("Documento Identità Fronte non trovato")
                }
            })

            downloadUserFiles(
                route.params.userId,
                tokenStorage,
                UserFiles.IDENTITY_DOCUMENT_BACK
            ).then((res) => {
                const fileUrl = URL.createObjectURL(res)
                if (res) {
                    setIdentityDocBackPreviewUrl(fileUrl)
                } else {
                    alert("Documento Identità Retro non trovato")
                }
            })

            downloadUserFiles(
                route.params.userId,
                tokenStorage,
                UserFiles.CAUSE_OF_DEATH
            ).then((res) => {
                const fileUrl = URL.createObjectURL(res)
                if (res) {
                    setCauseOfDeathDocPreviewUrl(fileUrl)
                } else {
                    alert("Documento Causa Morte non trovato")
                }
            })

            downloadUserFiles(
                route.params.userId,
                tokenStorage,
                UserFiles.INJURIES
            ).then((res) => {
                const fileUrl = URL.createObjectURL(res)
                if (res) {
                    setInjuriesDocPreviewUrl(fileUrl)
                } else {
                    alert("Documento Infortuni non trovato")
                }
            })

            downloadUserFiles(
                route.params.userId,
                tokenStorage,
                UserFiles.RC
            ).then((res) => {
                const fileUrl = URL.createObjectURL(res)
                if (res) {
                    setRcDocPreviewUrl(fileUrl)
                } else {
                    alert("Documento RC non trovato")
                }
            })
        }
    }, [route.params.userId])

    useRoleVerifier()

    return (
        <ScrollDiv>
            <Content>
                <Overlay
                    visible={Boolean(dialog)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    if (
                                        dialog ===
                                            "Utente Creato con Successo" ||
                                        dialog ===
                                            "Utente Modificato con Successo"
                                    ) {
                                        setDialog(undefined)
                                        navigation.replace(
                                            "BackOfficeUserTable"
                                        )
                                    } else if (
                                        dialog ===
                                        "Caricamento del File avvenuto con successo"
                                    ) {
                                        setDialog(undefined)
                                        navigation.replace(
                                            "BackOfficeUserManagement",
                                            {
                                                userId: route.params.userId
                                            }
                                        )
                                    } else {
                                        setDialog(undefined)
                                    }
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {dialog}
                        </Text>
                    </Div>
                </Overlay>

                <Text fontSize={52} fontWeight="bold" my={24}>
                    {route.params.userId
                        ? "Modifica Utente"
                        : "Crea Nuovo Utente"}
                </Text>

                <Text fontSize={24} mb={6}>
                    Nome
                </Text>
                <Input
                    mb={18}
                    placeholder="Inserisci Nome"
                    value={newUser?.firstName}
                    p={10}
                    focusBorderColor="blue700"
                    mr={12}
                    onChangeText={(e: string) => {
                        setNewUser({
                            ...newUser,
                            firstName: e
                        })
                    }}
                />

                <Text fontSize={24} mb={6}>
                    Cognome
                </Text>
                <Input
                    mb={18}
                    placeholder="Inserisci Cognome"
                    value={newUser?.lastName}
                    p={10}
                    focusBorderColor="blue700"
                    mr={12}
                    onChangeText={(e: string) => {
                        setNewUser({
                            ...newUser,
                            lastName: e
                        })
                    }}
                />

                <Text fontSize={24} mb={6}>
                    Email
                </Text>

                <Input
                    mb={18}
                    placeholder="Inserisci Email"
                    value={newUser?.email}
                    p={10}
                    focusBorderColor="blue700"
                    mr={12}
                    onChangeText={(e: string) => {
                        setNewUser({
                            ...newUser,
                            email: e
                        })
                    }}
                />

                {!route.params.userId && (
                    <Text fontSize={24} mb={6}>
                        Password:{" "}
                    </Text>
                )}

                {!route.params.userId && (
                    <Input
                        mb={18}
                        placeholder="Inserisci Password"
                        value={newUser?.password}
                        p={10}
                        focusBorderColor="blue700"
                        mr={12}
                        onChangeText={(e: string) => {
                            setNewUser({
                                ...newUser,
                                password: e
                            })
                        }}
                    />
                )}

                {newUser.role !== UserRole.ADMIN && (
                    <>
                        <Text fontSize={24} mb={6}>
                            Data di Nascita
                        </Text>
                        {newUser?.birthDate && (
                            <Text fontSize={24} mb={6}>
                                {newUser &&
                                    new Date(
                                        newUser?.birthDate
                                    ).toLocaleDateString?.()}
                            </Text>
                        )}

                        <DateTimePicker
                            style={{
                                textAlign: "center",
                                marginBottom: "12px"
                            }}
                            onChange={(e) => {
                                const actualE = { ...e }
                                setNewUser((_ne) => ({
                                    ..._ne,
                                    birthDate: new Date(actualE.target.value)
                                }))
                            }}
                        />

                        <Text fontSize={24} mb={6}>
                            Luogo di Nascita
                        </Text>

                        <Input
                            mb={18}
                            placeholder="Inserisci Luogo di Nascita"
                            value={newUser?.birthPlace}
                            p={10}
                            focusBorderColor="blue700"
                            mr={12}
                            onChangeText={(e: string) => {
                                setNewUser({
                                    ...newUser,
                                    birthPlace: e
                                })
                            }}
                        />

                        <Text fontSize={24} mb={6}>
                            Nazione di Residenza
                        </Text>

                        <Input
                            mb={18}
                            placeholder="Inserisci Nazione di Residenza"
                            value={newUser?.country}
                            p={10}
                            focusBorderColor="blue700"
                            mr={12}
                            onChangeText={(e: string) => {
                                setNewUser({
                                    ...newUser,
                                    country: e
                                })
                            }}
                        />

                        <Text fontSize={24} mb={6}>
                            Città
                        </Text>

                        <Input
                            mb={18}
                            placeholder="Inserisci Città"
                            value={newUser?.city}
                            p={10}
                            focusBorderColor="blue700"
                            mr={12}
                            onChangeText={(e: string) => {
                                setNewUser({
                                    ...newUser,
                                    city: e
                                })
                            }}
                        />

                        <Text fontSize={24} mb={6}>
                            Indirizzo
                        </Text>

                        <Input
                            mb={18}
                            placeholder="Inserisci Indirizzo con Civico"
                            value={newUser?.address}
                            p={10}
                            focusBorderColor="blue700"
                            mr={12}
                            onChangeText={(e: string) => {
                                setNewUser({
                                    ...newUser,
                                    address: e
                                })
                            }}
                        />

                        <Text fontSize={24} mb={6}>
                            CAP
                        </Text>

                        <Input
                            mb={18}
                            placeholder="Inserisci CAP"
                            value={newUser?.postalCode}
                            p={10}
                            focusBorderColor="blue700"
                            mr={12}
                            onChangeText={(e: string) => {
                                setNewUser({
                                    ...newUser,
                                    postalCode: e
                                })
                            }}
                        />

                        <Text fontSize={24} mb={6}>
                            Codice Fiscale
                        </Text>

                        <Input
                            mb={18}
                            placeholder="Inserisci Codice Fiscale"
                            value={newUser?.fiscalCode}
                            p={10}
                            focusBorderColor="blue700"
                            mr={12}
                            onChangeText={(e: string) => {
                                setNewUser({
                                    ...newUser,
                                    fiscalCode: e
                                })
                            }}
                        />

                        <Text fontSize={24} mb={6}>
                            Numero di Telefono
                        </Text>

                        <Input
                            mb={18}
                            placeholder="Inserisci Numero di Telefono"
                            value={newUser?.phoneNumber}
                            p={10}
                            focusBorderColor="blue700"
                            mr={12}
                            onChangeText={(e: string) => {
                                setNewUser({
                                    ...newUser,
                                    phoneNumber: e
                                })
                            }}
                        />
                    </>
                )}

                <Text fontSize={24} mb={6}>
                    Ruolo
                </Text>

                <Picker
                    style={{
                        marginBottom: "12px",
                        marginRight: "12px",
                        height: "48px",
                        fontSize: 18,
                        borderRadius: 5,
                        borderColor: "lightgray"
                    }}
                    selectedValue={newUser?.role}
                    onValueChange={(itemValue, itemIndex) => {
                        setNewUser({
                            ...newUser,
                            role: itemValue
                        })
                    }}
                >
                    <Picker.Item label="Admin" value={UserRole.ADMIN} />
                    <Picker.Item
                        label="Giornalista"
                        value={UserRole.MEDIA_JOURNALIST}
                    />
                    <Picker.Item
                        label="Fotografo"
                        value={UserRole.MEDIA_PHOTOGRAPHER}
                    />
                    <Picker.Item
                        label="Video Operatore"
                        value={UserRole.MEDIA_VIDEO_OPERATOR}
                    />
                    {/* <Picker.Item
                        label="Disabile"
                        value={UserRole.HANDICAPPED}
                    /> */}
                </Picker>

                {newUser.role !== UserRole.HANDICAPPED &&
                newUser.role !== UserRole.ADMIN ? (
                    <>
                        <Text fontSize={24} mb={6}>
                            Scegli Ente di Lavoro
                        </Text>
                        <Picker
                            style={{
                                marginBottom: "12px",
                                marginRight: "12px",
                                height: "48px",
                                fontSize: 18,
                                borderRadius: 5,
                                borderColor: "lightgray"
                            }}
                            selectedValue={
                                newUser.isFreelancer === true
                                    ? EntityWork.FREELANCER
                                    : EntityWork.AGENCY
                            }
                            onValueChange={(itemValue, itemIndex) => {
                                if (itemValue === EntityWork.FREELANCER) {
                                    setNewUser({
                                        ...newUser,
                                        isFreelancer: true
                                    })
                                } else {
                                    setNewUser({
                                        ...newUser,
                                        isFreelancer: false
                                    })
                                }
                            }}
                        >
                            <Picker.Item
                                label={t("freelance")}
                                value={EntityWork.FREELANCER}
                            />
                            <Picker.Item
                                label={t("agency")}
                                value={EntityWork.AGENCY}
                            />
                        </Picker>

                        {!newUser.isFreelancer && (
                            <>
                                <Text fontSize={24} mb={6}>
                                    Nome Ente di Lavoro
                                </Text>
                                <Input
                                    mb={18}
                                    placeholder="Inserisci Nome Agenzia o Pubblicazione"
                                    value={newUser?.agencyName}
                                    p={10}
                                    focusBorderColor="blue700"
                                    mr={12}
                                    onChangeText={(e: string) => {
                                        setNewUser({
                                            ...newUser,
                                            agencyName: e
                                        })
                                    }}
                                />

                                <Text fontSize={24} mb={6}>
                                    Email Ente di Lavoro
                                </Text>

                                <Input
                                    mb={18}
                                    placeholder="Inserisci Email Agenzia o Pubblicazione"
                                    value={newUser?.agencyEmail}
                                    p={10}
                                    focusBorderColor="blue700"
                                    mr={12}
                                    onChangeText={(e: string) => {
                                        setNewUser({
                                            ...newUser,
                                            agencyEmail: e
                                        })
                                    }}
                                />
                            </>
                        )}

                        <Text fontSize={24} mb={6}>
                            Documento Professionale
                        </Text>

                        <Input
                            mb={18}
                            placeholder="Inserisci codice del Documento Professionale"
                            value={newUser?.orderOfJournalistsCardNumber}
                            p={10}
                            focusBorderColor="blue700"
                            mr={12}
                            onChangeText={(e: string) => {
                                setNewUser({
                                    ...newUser,
                                    orderOfJournalistsCardNumber: e
                                })
                            }}
                        />
                    </>
                ) : null}

                {newUser?.role != UserRole.HANDICAPPED &&
                newUser.role !== UserRole.ADMIN ? (
                    <>
                        {" "}
                        <Text fontSize={24} mb={6}>
                            Lavori Pubblicati
                        </Text>
                        <Row rowStyles={{ marginRight: 12 }}>
                            <Col xs={10} sm={10} md={11} lg={11}>
                                <Input
                                    mb={6}
                                    placeholder="Inserisci l'Url di un Lavoro Pubblicato"
                                    p={10}
                                    mr={12}
                                    onChangeText={(e: string) => {
                                        setNewPublishedUrl(e)
                                    }}
                                />
                            </Col>
                            <Col xs={2} sm={2} md={1} lg={1}>
                                <Button
                                    onPress={() => {
                                        if (newPublishedUrl) {
                                            let newPublishedDocUrls =
                                                newUser.publishedDocUrls || []
                                            newPublishedDocUrls.push(
                                                newPublishedUrl
                                            )
                                            setNewUser({
                                                ...newUser,
                                                publishedDocUrls:
                                                    newPublishedDocUrls
                                            })
                                        }
                                    }}
                                >
                                    Aggiungi
                                </Button>
                            </Col>
                        </Row>
                        <Collapse mb={18} mr={12}>
                            <Collapse.Header
                                active
                                justifyContent="center"
                                fontSize="md"
                                color="black"
                                bg="white"
                                p="sm"
                                px="none"
                                borderStyle="solid"
                                borderColor="lightgray"
                                h={30}
                                borderWidth={1}
                            >
                                Mostra Lavori
                            </Collapse.Header>
                            <Collapse.Body pb="xl" mb={12}>
                                {newUser &&
                                    Array.isArray(newUser.publishedDocUrls) &&
                                    newUser.publishedDocUrls?.map((url) => {
                                        return (
                                            <Div row mb={6}>
                                                <Button
                                                    mr={6}
                                                    h={20}
                                                    w={20}
                                                    bg="#dd5a5a"
                                                    onPress={() => {
                                                        let newPublishedDocUrls =
                                                            newUser.publishedDocUrls ||
                                                            []
                                                        newPublishedDocUrls =
                                                            newPublishedDocUrls.filter(
                                                                (element) => {
                                                                    return (
                                                                        element !=
                                                                        url
                                                                    )
                                                                }
                                                            )
                                                        setNewUser({
                                                            ...newUser,
                                                            publishedDocUrls:
                                                                newPublishedDocUrls
                                                        })
                                                    }}
                                                >
                                                    <Icon
                                                        name="trash"
                                                        fontFamily="FontAwesome5"
                                                        fontSize={12}
                                                        color="black"
                                                        h={20}
                                                        w={20}
                                                        rounded="md"
                                                    />
                                                </Button>

                                                <Text
                                                    style={{
                                                        marginBottom: 6,
                                                        backgroundColor:
                                                            "lightred"
                                                    }}
                                                >
                                                    - {url}
                                                </Text>
                                            </Div>
                                        )
                                    })}
                            </Collapse.Body>
                        </Collapse>
                    </>
                ) : null}

                {route.params.userId && newUser.role !== UserRole.ADMIN && (
                    <>
                        <Div row>
                            <Text fontSize={24} mb={6} mr={12}>
                                Data di Scadenza Carta d'Identità:{" "}
                            </Text>

                            {newUser?.expiringIdentityDocument && (
                                <Text fontSize={24} mb={6}>
                                    {newUser &&
                                        new Date(
                                            newUser?.expiringIdentityDocument
                                        ).toLocaleDateString?.()}
                                </Text>
                            )}
                        </Div>
                        <DateTimePicker
                            style={{
                                textAlign: "center",
                                marginBottom: "12px"
                            }}
                            onChange={(e) => {
                                const actualE = { ...e }
                                setNewUser((_ne) => ({
                                    ..._ne,
                                    expiringIdentityDocument: new Date(
                                        actualE.target.value
                                    )
                                }))
                            }}
                        />
                        {newUser?.role != UserRole.HANDICAPPED ? (
                            <>
                                <Div row>
                                    <Text fontSize={24} mb={6} mr={12}>
                                        Data di Scadenza Documento Morte:{" "}
                                    </Text>

                                    {newUser?.expiringDateDocCauseOfDeath && (
                                        <Text fontSize={24} mb={6}>
                                            {newUser &&
                                                new Date(
                                                    newUser?.expiringDateDocCauseOfDeath
                                                ).toLocaleDateString?.()}
                                        </Text>
                                    )}
                                </Div>

                                <DateTimePicker
                                    style={{
                                        textAlign: "center",
                                        marginBottom: "12px"
                                    }}
                                    onChange={(e) => {
                                        const actualE = { ...e }
                                        setNewUser((_ne) => ({
                                            ..._ne,
                                            expiringDateDocCauseOfDeath:
                                                new Date(actualE.target.value)
                                        }))
                                    }}
                                />
                            </>
                        ) : null}
                        {newUser?.role != UserRole.HANDICAPPED ? (
                            <>
                                <Div row>
                                    <Text fontSize={24} mb={6} mr={12}>
                                        Data di Scadenza Documento Infortuni:{" "}
                                    </Text>
                                    {newUser?.expiringDateDocInjuries && (
                                        <Text fontSize={24} mb={6}>
                                            {newUser &&
                                                new Date(
                                                    newUser?.expiringDateDocInjuries
                                                ).toLocaleDateString?.()}
                                        </Text>
                                    )}
                                </Div>

                                <DateTimePicker
                                    style={{
                                        textAlign: "center",
                                        marginBottom: "12px"
                                    }}
                                    onChange={(e) => {
                                        const actualE = { ...e }
                                        setNewUser((_ne) => ({
                                            ..._ne,
                                            expiringDateDocInjuries: new Date(
                                                actualE.target.value
                                            )
                                        }))
                                    }}
                                />
                            </>
                        ) : null}
                        {newUser?.role != UserRole.HANDICAPPED ? (
                            <>
                                <Div row>
                                    <Text fontSize={24} mb={6} mr={12}>
                                        Data di Scadenza Documento RC:{" "}
                                    </Text>
                                    {newUser?.expiringDateDocRc && (
                                        <Text fontSize={24} mb={6}>
                                            {newUser &&
                                                new Date(
                                                    newUser?.expiringDateDocRc
                                                ).toLocaleDateString?.()}
                                        </Text>
                                    )}
                                </Div>

                                <DateTimePicker
                                    style={{
                                        textAlign: "center",
                                        marginBottom: "12px"
                                    }}
                                    onChange={(e) => {
                                        const actualE = { ...e }
                                        setNewUser((_ne) => ({
                                            ..._ne,
                                            expiringDateDocRc: new Date(
                                                actualE.target.value
                                            )
                                        }))
                                    }}
                                />
                            </>
                        ) : null}
                        <Text fontSize={24}>
                            File Documento d'Identità Fronte:{" "}
                            {docIdentityFrontExist
                                ? "Documento Presente"
                                : "Documento non Presente"}
                        </Text>
                        <Text fontSize={12} mb={6}>
                            {t("file_format_label")}
                        </Text>
                        <Div row={dimension.width > 530 ? true : false}>
                            <Button
                                mr={12}
                                mb={dimension.width > 530 ? 18 : 12}
                                onPress={() => {
                                    pickFile("*/*")
                                        .then((b64) => {
                                            if (
                                                userIdStorage &&
                                                tokenStorage &&
                                                newUser.id
                                            ) {
                                                uploadUserFiles(
                                                    newUser.id,
                                                    tokenStorage,
                                                    {
                                                        [UserFiles.IDENTITY_DOCUMENT_FRONT]:
                                                            b64
                                                    },
                                                    (progressEvent: any) => {
                                                        const percentage =
                                                            Math.round(
                                                                (progressEvent.loaded *
                                                                    100) /
                                                                    progressEvent.total
                                                            )
                                                        setIdentityDocFrontUploadPercentage(
                                                            percentage == 100
                                                                ? undefined
                                                                : percentage
                                                        )
                                                    }
                                                )
                                                    .then((res) => {
                                                        if (res.success) {
                                                            setDialog(
                                                                "Caricamento del File avvenuto con successo"
                                                            )
                                                        } else {
                                                            setDialog(
                                                                "Errore: " +
                                                                    res.error
                                                            )
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        setDialog(
                                                            "Errore: " + err
                                                        )
                                                    })
                                            }
                                        })
                                        .catch((err) => {
                                            setDialog(
                                                err?.message.toString?.() ||
                                                    "Errore durante il caricamente"
                                            )
                                        })
                                }}
                            >
                                {identityDocFrontUploadPercentage
                                    ? `Caricamento... ${identityDocFrontUploadPercentage}%`
                                    : "Carica Documento d'Identità Fronte"}
                            </Button>

                            {identityDocFrontPreviewUrl && (
                                <>
                                    <details
                                        style={{
                                            marginBottom:
                                                dimension.width > 530
                                                    ? undefined
                                                    : 18
                                        }}
                                    >
                                        <summary
                                            style={{
                                                backgroundColor:
                                                    "rgb(49, 130, 206)",
                                                height: 44,
                                                display: "flex",
                                                alignItems: "center",
                                                borderRadius: 5,
                                                border: 2,
                                                padding: "0px 12px 0px 12px",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <Text fontSize={15} color="white">
                                                ▶ Mostra File
                                            </Text>
                                        </summary>
                                        <iframe
                                            style={{
                                                marginTop: 4,
                                                borderRadius: 5
                                            }}
                                            frameBorder={0}
                                            src={identityDocFrontPreviewUrl}
                                            height={300}
                                            width={200}
                                        ></iframe>
                                        <Button
                                            onPress={() => {
                                                window.open(
                                                    identityDocFrontPreviewUrl,
                                                    "_blank"
                                                )
                                            }}
                                        >
                                            Mostra Anteprima
                                        </Button>
                                    </details>
                                </>
                            )}
                        </Div>
                        <Text fontSize={24}>
                            File Documento d'Identità Retro:{" "}
                            {docIdentityBackExist
                                ? "Documento Presente"
                                : "Documento non Presente"}
                        </Text>
                        <Text fontSize={12} mb={6}>
                            {t("file_format_label")}
                        </Text>
                        <Div row={dimension.width > 530 ? true : false}>
                            <Button
                                mr={12}
                                mb={dimension.width > 530 ? 18 : 12}
                                onPress={() => {
                                    pickFile("*/*")
                                        .then((b64) => {
                                            if (
                                                userIdStorage &&
                                                tokenStorage &&
                                                newUser.id
                                            ) {
                                                uploadUserFiles(
                                                    newUser.id,
                                                    tokenStorage,
                                                    {
                                                        [UserFiles.IDENTITY_DOCUMENT_BACK]:
                                                            b64
                                                    },
                                                    (progressEvent: any) => {
                                                        const percentage =
                                                            Math.round(
                                                                (progressEvent.loaded *
                                                                    100) /
                                                                    progressEvent.total
                                                            )
                                                        setIdentityDocBackUploadPercentage(
                                                            percentage == 100
                                                                ? undefined
                                                                : percentage
                                                        )
                                                    }
                                                )
                                                    .then((res) => {
                                                        if (res.success) {
                                                            setDialog(
                                                                "Caricamento del File avvenuto con successo"
                                                            )
                                                        } else {
                                                            setDialog(
                                                                "Errore: " +
                                                                    res.error
                                                            )
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        setDialog(
                                                            "Errore: " + err
                                                        )
                                                    })
                                            }
                                        })
                                        .catch((err) => {
                                            setDialog(
                                                err?.message.toString?.() ||
                                                    "Errore durante il caricamente"
                                            )
                                        })
                                }}
                            >
                                {identityDocBackUploadPercentage
                                    ? `Caricamento... ${identityDocBackUploadPercentage}%`
                                    : "Carica Documento d'Identità Retro"}
                            </Button>

                            {identityDocBackPreviewUrl && (
                                <>
                                    <details
                                        style={{
                                            marginBottom:
                                                dimension.width > 530
                                                    ? undefined
                                                    : 18
                                        }}
                                    >
                                        <summary
                                            style={{
                                                backgroundColor:
                                                    "rgb(49, 130, 206)",
                                                height: 44,
                                                display: "flex",
                                                alignItems: "center",
                                                borderRadius: 5,
                                                border: 2,
                                                padding: "0px 12px 0px 12px",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <Text fontSize={15} color="white">
                                                ▶ Mostra File
                                            </Text>
                                        </summary>
                                        <iframe
                                            style={{
                                                marginTop: 4,
                                                borderRadius: 5
                                            }}
                                            frameBorder={0}
                                            src={identityDocBackPreviewUrl}
                                            height={300}
                                            width={200}
                                        ></iframe>
                                        <Button
                                            onPress={() => {
                                                window.open(
                                                    identityDocBackPreviewUrl,
                                                    "_blank"
                                                )
                                            }}
                                        >
                                            Mostra Anteprima
                                        </Button>
                                    </details>
                                </>
                            )}
                        </Div>
                        {newUser && newUser?.role != UserRole.HANDICAPPED ? (
                            <>
                                <Text fontSize={24}>
                                    File Documento di Morte:{" "}
                                    {docCauseOfDeathExist
                                        ? "Documento Presente"
                                        : "Documento non Presente"}
                                </Text>
                                <Text fontSize={12} mb={6}>
                                    {t("file_format_label")}
                                </Text>
                                <Div row={dimension.width > 530 ? true : false}>
                                    <Button
                                        mr={12}
                                        mb={dimension.width > 530 ? 18 : 12}
                                        onPress={() => {
                                            pickFile("*/*")
                                                .then((b64) => {
                                                    if (
                                                        userIdStorage &&
                                                        tokenStorage &&
                                                        newUser.id
                                                    ) {
                                                        uploadUserFiles(
                                                            newUser.id,
                                                            tokenStorage,
                                                            {
                                                                [UserFiles.CAUSE_OF_DEATH]:
                                                                    b64
                                                            },
                                                            (
                                                                progressEvent: any
                                                            ) => {
                                                                const percentage =
                                                                    Math.round(
                                                                        (progressEvent.loaded *
                                                                            100) /
                                                                            progressEvent.total
                                                                    )
                                                                setCauseOfDeathDocUploadPercentage(
                                                                    percentage ==
                                                                        100
                                                                        ? undefined
                                                                        : percentage
                                                                )
                                                            }
                                                        )
                                                            .then((res) => {
                                                                if (
                                                                    res.success
                                                                ) {
                                                                    setDialog(
                                                                        "Caricamento del File avvenuto con successo"
                                                                    )
                                                                } else {
                                                                    setDialog(
                                                                        "Errore: " +
                                                                            res.error
                                                                    )
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                setDialog(
                                                                    "Errore: " +
                                                                        err
                                                                )
                                                            })
                                                    }
                                                })
                                                .catch((err) => {
                                                    setDialog(
                                                        err?.message.toString?.() ||
                                                            "Errore durante il caricamente"
                                                    )
                                                })
                                        }}
                                    >
                                        {causeOfDeathDocUploadPercentage
                                            ? `Caricamento... ${causeOfDeathDocUploadPercentage}%`
                                            : "Carica Documento Causa Morte"}
                                    </Button>

                                    {causeOfDeathDocPreviewUrl && (
                                        <>
                                            <details
                                                style={{
                                                    marginBottom:
                                                        dimension.width > 530
                                                            ? undefined
                                                            : 18
                                                }}
                                            >
                                                <summary
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(49, 130, 206)",
                                                        height: 44,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderRadius: 5,
                                                        border: 2,
                                                        padding:
                                                            "0px 12px 0px 12px",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <Text
                                                        fontSize={15}
                                                        color="white"
                                                    >
                                                        ▶ Mostra File
                                                    </Text>
                                                </summary>
                                                <iframe
                                                    style={{
                                                        marginTop: 4,
                                                        borderRadius: 5
                                                    }}
                                                    frameBorder={0}
                                                    src={
                                                        causeOfDeathDocPreviewUrl
                                                    }
                                                    height={300}
                                                    width={200}
                                                ></iframe>
                                                <Button
                                                    onPress={() => {
                                                        window.open(
                                                            causeOfDeathDocPreviewUrl,
                                                            "_blank"
                                                        )
                                                    }}
                                                >
                                                    Mostra Anteprima
                                                </Button>
                                            </details>
                                        </>
                                    )}
                                </Div>
                            </>
                        ) : null}
                        {newUser && newUser?.role != UserRole.HANDICAPPED ? (
                            <>
                                <Text fontSize={24}>
                                    File Documento Infortuni:{" "}
                                    {docInjuriesExist
                                        ? "Documento Presente"
                                        : "Documento non Presente"}
                                </Text>
                                <Text fontSize={12} mb={6}>
                                    {t("file_format_label")}
                                </Text>
                                <Div row={dimension.width > 530 ? true : false}>
                                    <Button
                                        mr={12}
                                        mb={dimension.width > 530 ? 18 : 12}
                                        onPress={() => {
                                            pickFile("*/*")
                                                .then((b64) => {
                                                    if (
                                                        userIdStorage &&
                                                        tokenStorage &&
                                                        newUser.id
                                                    ) {
                                                        uploadUserFiles(
                                                            newUser.id,
                                                            tokenStorage,
                                                            {
                                                                [UserFiles.INJURIES]:
                                                                    b64
                                                            },
                                                            (
                                                                progressEvent: any
                                                            ) => {
                                                                const percentage =
                                                                    Math.round(
                                                                        (progressEvent.loaded *
                                                                            100) /
                                                                            progressEvent.total
                                                                    )
                                                                setInjuriesDocUploadPercentage(
                                                                    percentage ==
                                                                        100
                                                                        ? undefined
                                                                        : percentage
                                                                )
                                                            }
                                                        )
                                                            .then((res) => {
                                                                if (
                                                                    res.success
                                                                ) {
                                                                    setDialog(
                                                                        "Caricamento del File avvenuto con successo"
                                                                    )
                                                                } else {
                                                                    setDialog(
                                                                        "Errore: " +
                                                                            res.error
                                                                    )
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                setDialog(
                                                                    "Errore: " +
                                                                        err
                                                                )
                                                            })
                                                    }
                                                })
                                                .catch((err) => {
                                                    setDialog(
                                                        err?.message.toString?.() ||
                                                            "Errore durante il caricamente"
                                                    )
                                                })
                                        }}
                                    >
                                        {injuriesDocUploadPercentage
                                            ? `Caricamento... ${injuriesDocUploadPercentage}%`
                                            : "Carica Documento Infortuni"}
                                    </Button>

                                    {injuriesDocPreviewUrl && (
                                        <>
                                            <details
                                                style={{
                                                    marginBottom:
                                                        dimension.width > 530
                                                            ? undefined
                                                            : 18
                                                }}
                                            >
                                                <summary
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(49, 130, 206)",
                                                        height: 44,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderRadius: 5,
                                                        border: 2,
                                                        padding:
                                                            "0px 12px 0px 12px",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <Text
                                                        fontSize={15}
                                                        color="white"
                                                    >
                                                        ▶ Mostra File
                                                    </Text>
                                                </summary>
                                                <iframe
                                                    style={{
                                                        marginTop: 4,
                                                        borderRadius: 5
                                                    }}
                                                    frameBorder={0}
                                                    src={injuriesDocPreviewUrl}
                                                    height={300}
                                                    width={200}
                                                ></iframe>
                                                <Button
                                                    onPress={() => {
                                                        window.open(
                                                            injuriesDocPreviewUrl,
                                                            "_blank"
                                                        )
                                                    }}
                                                >
                                                    Mostra Anteprima
                                                </Button>
                                            </details>
                                        </>
                                    )}
                                </Div>
                            </>
                        ) : null}
                        {newUser && newUser?.role != UserRole.HANDICAPPED ? (
                            <>
                                <Text fontSize={24}>
                                    File Documento RC:{" "}
                                    {docRcExist
                                        ? "Documento Presente"
                                        : "Documento non Presente"}
                                </Text>
                                <Text fontSize={12} mb={6}>
                                    {t("file_format_label")}
                                </Text>
                                <Div row={dimension.width > 530 ? true : false}>
                                    <Button
                                        mr={12}
                                        mb={dimension.width > 530 ? 18 : 12}
                                        onPress={() => {
                                            pickFile("*/*")
                                                .then((b64) => {
                                                    if (
                                                        userIdStorage &&
                                                        tokenStorage &&
                                                        newUser.id
                                                    ) {
                                                        uploadUserFiles(
                                                            newUser.id,
                                                            tokenStorage,
                                                            {
                                                                [UserFiles.RC]:
                                                                    b64
                                                            },
                                                            (
                                                                progressEvent: any
                                                            ) => {
                                                                const percentage =
                                                                    Math.round(
                                                                        (progressEvent.loaded *
                                                                            100) /
                                                                            progressEvent.total
                                                                    )
                                                                setRcDocUploadPercentage(
                                                                    percentage ==
                                                                        100
                                                                        ? undefined
                                                                        : percentage
                                                                )
                                                            }
                                                        )
                                                            .then((res) => {
                                                                if (
                                                                    res.success
                                                                ) {
                                                                    setDialog(
                                                                        "Caricamento del File avvenuto con successo"
                                                                    )
                                                                } else {
                                                                    setDialog(
                                                                        "Errore: " +
                                                                            res.error
                                                                    )
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                setDialog(
                                                                    "Errore: " +
                                                                        err
                                                                )
                                                            })
                                                    }
                                                })
                                                .catch((err) => {
                                                    setDialog(
                                                        err?.message.toString?.() ||
                                                            "Errore durante il caricamente"
                                                    )
                                                })
                                        }}
                                    >
                                        {rcDocUploadPercentage
                                            ? `Caricamento... ${rcDocUploadPercentage}%`
                                            : "Carica Documento Responsabilità Civile"}
                                    </Button>

                                    {rcDocPreviewUrl && (
                                        <>
                                            <details
                                                style={{
                                                    marginBottom:
                                                        dimension.width > 530
                                                            ? undefined
                                                            : 18
                                                }}
                                            >
                                                <summary
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(49, 130, 206)",
                                                        height: 44,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderRadius: 5,
                                                        border: 2,
                                                        padding:
                                                            "0px 12px 0px 12px",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <Text
                                                        fontSize={15}
                                                        color="white"
                                                    >
                                                        ▶ Mostra File
                                                    </Text>
                                                </summary>
                                                <iframe
                                                    style={{
                                                        marginTop: 4,
                                                        borderRadius: 5
                                                    }}
                                                    frameBorder={0}
                                                    src={rcDocPreviewUrl}
                                                    height={300}
                                                    width={200}
                                                ></iframe>
                                                <Button
                                                    onPress={() => {
                                                        window.open(
                                                            rcDocPreviewUrl,
                                                            "_blank"
                                                        )
                                                    }}
                                                >
                                                    Mostra Anteprima
                                                </Button>
                                            </details>
                                        </>
                                    )}
                                </Div>
                            </>
                        ) : null}{" "}
                    </>
                )}

                <Div row justifyContent="center" mt={24}>
                    <Button
                        mb={24}
                        fontWeight="bold"
                        fontSize="2xl"
                        alignSelf="center"
                        style={{ marginLeft: 6, marginRight: 6 }}
                        onPress={() => {
                            if (route.params.userId) {
                                if (
                                    newUser.firstName &&
                                    newUser.lastName &&
                                    newUser.email &&
                                    newUser.birthDate &&
                                    newUser.birthPlace &&
                                    newUser.country &&
                                    newUser.city &&
                                    newUser.address &&
                                    newUser.postalCode &&
                                    newUser.phoneNumber &&
                                    newUser.fiscalCode &&
                                    newUser.role &&
                                    tokenStorage
                                ) {
                                    editUser(
                                        route.params.userId,
                                        tokenStorage,
                                        newUser
                                    )
                                        .then((res) => {
                                            if (res.success) {
                                                setDialog(
                                                    "Utente Modificato con Successo"
                                                )
                                            } else {
                                                setDialog(
                                                    "Errore: " + res.error
                                                )
                                            }
                                        })
                                        .catch((err) => {
                                            setDialog("Errore: " + err)
                                        })
                                }
                            } else {
                                if (newUser.role === UserRole.ADMIN) {
                                    if (
                                        newUser.firstName &&
                                        newUser.lastName &&
                                        newUser.email &&
                                        newUser.password &&
                                        newUser.role &&
                                        tokenStorage
                                    ) {
                                        let createdUser: User = {
                                            firstName: newUser.firstName,
                                            lastName: newUser.lastName,
                                            email: newUser.email,
                                            password: newUser.password,
                                            birthDate: new Date(),
                                            birthPlace: "MonzaOffice",
                                            country: "Lombardia",
                                            city: "Monza",
                                            address: "MonzaAutodromo",
                                            postalCode: "0000",
                                            phoneNumber: "N.D.",
                                            fiscalCode: "0000",
                                            role: newUser.role,
                                            hasBeenValidated: true,
                                            agencyName: "Autodromo di Monza",
                                            hasIdentityDocumentFrontDocUpdated:
                                                false,
                                            hasIdentityDocumentBackDocUpdated:
                                                false,
                                            hasCauseOfDeathDocUpdated: false,
                                            hasInjuriesDocUpdated: false,
                                            hasRcDocUpdated: false
                                        }

                                        if (createdUser) {
                                            signUp(createdUser, tokenStorage)
                                                .then((res) => {
                                                    if (res.success) {
                                                        setDialog(
                                                            "Utente Creato con Successo"
                                                        )
                                                    } else {
                                                        setDialog(
                                                            "Errore: " +
                                                                res.error
                                                        )
                                                    }
                                                })
                                                .catch((err) => {
                                                    setDialog("Errore: " + err)
                                                })
                                        }
                                    }
                                } else {
                                    if (
                                        newUser.firstName &&
                                        newUser.lastName &&
                                        newUser.email &&
                                        newUser.password &&
                                        newUser.birthDate &&
                                        newUser.birthPlace &&
                                        newUser.country &&
                                        newUser.city &&
                                        newUser.address &&
                                        newUser.postalCode &&
                                        newUser.phoneNumber &&
                                        newUser.fiscalCode &&
                                        newUser.role &&
                                        tokenStorage
                                    ) {
                                        let createdUser: User = {
                                            firstName: newUser.firstName,
                                            lastName: newUser.lastName,
                                            email: newUser.email,
                                            password: newUser.password,
                                            birthDate: newUser.birthDate,
                                            birthPlace: newUser.birthPlace,
                                            country: newUser.country,
                                            city: newUser.city,
                                            address: newUser.address,
                                            postalCode: newUser.postalCode,
                                            phoneNumber: newUser.phoneNumber,
                                            fiscalCode: newUser.fiscalCode,
                                            role: newUser.role,
                                            isFreelancer: newUser.isFreelancer,
                                            agencyEmail: newUser.agencyEmail,
                                            agencyName: newUser.agencyName,
                                            expiringIdentityDocument:
                                                newUser.expiringIdentityDocument,
                                            expiringDateDocCauseOfDeath:
                                                newUser.expiringDateDocCauseOfDeath,
                                            expiringDateDocInjuries:
                                                newUser.expiringDateDocInjuries,
                                            expiringDateDocRc:
                                                newUser.expiringDateDocRc,
                                            publishedDocUrls:
                                                newUser.publishedDocUrls,
                                            orderOfJournalistsCardNumber:
                                                newUser.orderOfJournalistsCardNumber,
                                            hasBeenValidated: false,
                                            hasIdentityDocumentFrontDocUpdated:
                                                false,
                                            hasIdentityDocumentBackDocUpdated:
                                                false,
                                            hasCauseOfDeathDocUpdated: false,
                                            hasInjuriesDocUpdated: false,
                                            hasRcDocUpdated: false
                                        }

                                        if (createdUser) {
                                            signUp(createdUser, tokenStorage)
                                                .then((res) => {
                                                    if (res.success) {
                                                        setDialog(
                                                            "Utente Creato con Successo"
                                                        )
                                                    } else {
                                                        setDialog(
                                                            "Errore: " +
                                                                res.error
                                                        )
                                                    }
                                                })
                                                .catch((err) => {
                                                    setDialog("Errore: " + err)
                                                })
                                        }
                                    }
                                }
                            }
                        }}
                    >
                        Conferma
                    </Button>

                    <HomeButton
                        ml={12}
                        mb={24}
                        navigateHome={() => {
                            navigation.navigate("BackOfficeHome")
                        }}
                    />
                </Div>
            </Content>
        </ScrollDiv>
    )
}

export default BackOfficeUserManagement
