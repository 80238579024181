import { Picker } from "@react-native-picker/picker"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useState } from "react"
import { View } from "react-native-animatable"
import {
    Button,
    Checkbox,
    Collapse,
    Div,
    Icon,
    Image,
    Input,
    Overlay,
    ScrollDiv,
    StatusBar,
    Text
} from "react-native-magnus"
import { SafeAreaView } from "react-native-safe-area-context"
import { signUp } from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import DateTimePicker from "../../../components/DateTimePicker"
import {
    tokenAtomStorage,
    userFirstNameAtomStorage,
    userIdAtomStorage,
    userRoleAtomStorage
} from "../../../context/atom"
import { t } from "../../../strings"
import { UserRole } from "../../../types/prisma"
import { allowedSymbols, numbers } from "../../../utils/constants"

export enum EntityWork {
    AGENCY = "AGENCY",
    FREELANCER = "FREELANCE"
}

export type SignUpProps = undefined

type SignUpScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "SignUp"
>

const SignUp = ({ navigation }: SignUpScreenRouteProp) => {
    const [tokenStorage, setTokenStorage] = useAtom(tokenAtomStorage)
    const [userRoleStorage, setUserRoleStorage] = useAtom(userRoleAtomStorage)
    const [userIdStorage, setUserIdStorage] = useAtom(userIdAtomStorage)
    const [userFirstNameStorage, setUserFirstNameStorage] = useAtom(
        userFirstNameAtomStorage
    )

    const [email, setEmail] = useState<string>()
    const [confirmEmail, setConfirmEmail] = useState<string>()
    const [password, setPassword] = useState<string>()
    const [firstName, setFirstName] = useState<string>()
    const [lastName, setLastName] = useState<string>()
    const [birthDate, setBirthDate] = useState<Date>()
    const [fiscalCode, setFiscalCode] = useState<string>()
    const [role, setRole] = useState<UserRole>(UserRole.MEDIA_JOURNALIST)
    const [birthPlace, setBirthPlace] = useState<string>()
    const [postalCode, setPostalCode] = useState<string>()
    const [city, setCity] = useState<string>()
    const [country, setCountry] = useState<string>()
    const [address, setAddress] = useState<string>()
    const [phoneNumber, setPhoneNumber] = useState<string>()
    const [expiringIdentityDocument, setExpiringIdentityDocument] =
        useState<Date>()
    const [expiringDateDocCauseOfDeath, setExpiringDateDocCauseOfDeath] =
        useState<Date>()
    const [expiringDateDocInjuries, setExpiringDateDocInjuries] =
        useState<Date>()
    const [expiringDateDocRc, setExpiringDateDocRc] = useState<Date>()
    const [publishedDocUrlsInput, setPublishedDocUrlsInput] = useState<string>()
    const [publishedDocUrls, setPublishedDocUrls] = useState<string[]>()
    const [isFreelancer, setIsFreelancer] = useState<boolean>(true)
    const [agencyName, setAgencyName] = useState<string>()
    const [agencyEmail, setAgencyEmail] = useState<string>()
    const [orderOfJournalistsCardNumber, setOrderOfJournalistsCode] =
        useState<string>()
    const [showUserRole, setShowUserRole] = useState<boolean>(true)
    const [showOperatorData, setShowOperatorData] = useState<boolean>(false)

    const [showConfirmEmailTooltip, setShowConfirmEmailTooltip] =
        useState<boolean>()
    const [showNotValidPasswordTooltip, setShowNotValidPasswordTooltip] =
        useState<boolean>()

    const [responsabilityAcceptance, setResponsabilityAcceptance] =
        useState<boolean>(false)
    const [
        generalRegulamentationAcceptance,
        setGeneralRegulamentationAcceptance
    ] = useState<boolean>(false)
    const [contractualConditionAcceptance, setContractualConditionAcceptance] =
        useState<boolean>(false)
    const [privacyAcceptance, setPrivacyAcceptance] = useState<boolean>(false)

    const [comunicationSignUp, setComunicationSignUp] = useState<string>()

    return (
        <SafeAreaView
            style={{
                backgroundColor: "white",
                paddingTop: StatusBar.currentHeight
            }}
        >
            <Content style={{}}>
                <ScrollDiv h="100%">
                    <Overlay
                        visible={Boolean(comunicationSignUp)}
                        p="xl"
                        w={"80%"}
                        style={{
                            maxHeight: "60vh",
                            maxWidth: 350
                        }}
                    >
                        <Div>
                            <Div
                                row
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text mr={12} fontSize={18} fontWeight="bold">
                                    Avviso
                                </Text>
                                <Button
                                    bg="transparent"
                                    alignSelf="flex-end"
                                    onPress={() => {
                                        setComunicationSignUp(undefined)
                                    }}
                                >
                                    <Icon
                                        name="times"
                                        color="#424242"
                                        fontFamily="FontAwesome5"
                                        fontSize="16px"
                                    />
                                </Button>
                            </Div>
                        </Div>
                        <Div
                            mt={24}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Text w={"100%"} textAlign="center">
                                {comunicationSignUp}
                            </Text>
                        </Div>
                    </Overlay>

                    <Div flex={1}>
                        <Image
                            w={220}
                            h={220}
                            alignSelf="center"
                            source={require("../../../assets/icon.png")}
                        />

                        <Text mt="xl" w="100%" fontWeight="bold" fontSize="5xl">
                            {t("welcome")}
                        </Text>
                        <Text mt="md" w="100%" fontSize="lg" color="gray600">
                            {t("create_credentials")}
                        </Text>

                        <Text mt="xl" mb="xs">
                            {t("email")} *
                        </Text>
                        <Input
                            placeholder={t("email")}
                            onBlur={() => {
                                if (email !== confirmEmail) {
                                    setShowConfirmEmailTooltip(true)
                                } else {
                                    setShowConfirmEmailTooltip(false)
                                }
                            }}
                            onChangeText={(e) => {
                                setEmail(e)
                            }}
                        />

                        <Div>
                            <Text mt="xl" mb="xs">
                                {t("email_confirmation")} *
                            </Text>
                            <Input
                                placeholder={t("email_confirmation")}
                                onBlur={() => {
                                    if (email !== confirmEmail) {
                                        setShowConfirmEmailTooltip(true)
                                    } else {
                                        setShowConfirmEmailTooltip(false)
                                    }
                                }}
                                onChangeText={(e) => {
                                    setConfirmEmail(e)
                                }}
                            />

                            {showConfirmEmailTooltip && (
                                <p
                                    style={{
                                        marginTop: 4,
                                        marginBottom: 4,
                                        color: "#d01f3a",
                                        fontFamily: "arial",
                                        fontSize: 12
                                    }}
                                >
                                    {t("email_mismatch")}
                                </p>
                            )}
                        </Div>

                        <Div>
                            <Text mt="xl" mb="xs">
                                {t("password")} *
                            </Text>
                            <Input
                                placeholder={t("password")}
                                textContentType="password"
                                onChangeText={(e) => {
                                    const regex =
                                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
                                    const isValid = regex.test(e)
                                    console.log("P: ", e, " ", isValid)
                                    if (isValid) {
                                        console.log("true")
                                        setPassword(e)
                                        setShowNotValidPasswordTooltip(false)
                                    } else {
                                        setShowNotValidPasswordTooltip(true)
                                    }
                                }}
                            />

                            {showNotValidPasswordTooltip && (
                                <p
                                    style={{
                                        marginTop: 4,
                                        marginBottom: 4,
                                        color: "#d01f3a",
                                        fontFamily: "arial",
                                        fontSize: 12
                                    }}
                                >
                                    {t("password_not_valid")}
                                </p>
                            )}
                        </Div>

                        <Text mt="xl" mb="xs">
                            {t("name")} *
                        </Text>
                        <Input
                            placeholder={t("name")}
                            onChangeText={(e) => {
                                setFirstName(e)
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("surname")} *
                        </Text>
                        <Input
                            placeholder={t("surname")}
                            onChangeText={(e) => {
                                setLastName(e)
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("birthdate")} *
                        </Text>
                        <DateTimePicker
                            style={{ textAlign: "center" }}
                            onChange={(e) => {
                                const actualE = { ...e }
                                setBirthDate(
                                    () => new Date(actualE.target.value)
                                )
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("birthplace")} *
                        </Text>
                        <Input
                            placeholder={t("birthplace")}
                            onChangeText={(e) => {
                                setBirthPlace(e)
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("fiscal_code")} *
                        </Text>
                        <Input
                            placeholder={t("fiscal_code")}
                            onChangeText={(e) => {
                                setFiscalCode(e)
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("nationality")} *
                        </Text>
                        <Input
                            placeholder={t("nationality")}
                            onChangeText={(e) => {
                                setCountry(e)
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("city")} *
                        </Text>
                        <Input
                            placeholder={t("city")}
                            onChangeText={(e) => {
                                setCity(e)
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("postal_code")} *
                        </Text>
                        <Input
                            placeholder={t("postal_code")}
                            onChangeText={(e) => {
                                setPostalCode(e)
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("address")} *
                        </Text>
                        <Input
                            placeholder={t("address")}
                            onChangeText={(e) => {
                                setAddress(e)
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("phone")} *
                        </Text>
                        <Input
                            placeholder={t("phone")}
                            onChangeText={(e) => {
                                setPhoneNumber(e)
                            }}
                        />

                        <Text mt="xl" mb="xs">
                            {t("expiration_date_id")} *
                        </Text>
                        <DateTimePicker
                            style={{ textAlign: "center" }}
                            onChange={(e) => {
                                const actualE = { ...e }
                                setExpiringIdentityDocument(
                                    () => new Date(actualE.target.value)
                                )
                            }}
                        />

                        {/* <Text mt="xl" mb="xs">
                            {t("user_type")} *
                        </Text>
                        <Picker
                            style={{
                                height: 43,
                                borderRadius: 6,
                                borderColor: "rgb(203, 213, 224)"
                            }}
                            selectedValue={role}
                            onValueChange={(itemValue, itemIndex) => {
                                if (itemValue === UserRole.MEDIA_PR) {
                                    setShowUserRole(true)
                                } else {
                                    setShowUserRole(false)
                                    setShowOperatorData(false)
                                }
                                setRole(itemValue)
                            }}
                        >
                            <Picker.Item
                                label={t("media_pr")}
                                value={UserRole.MEDIA_PR}
                            />
                            <Picker.Item
                                label={t("handicapped")}
                                value={UserRole.HANDICAPPED}
                            />
                            <Picker.Item label="Admin" value={UserRole.ADMIN} />
                        </Picker> */}

                        {showUserRole && (
                            <>
                                <Text mt="xl" mb="xs">
                                    {t("user_role")} *
                                </Text>
                                <Picker
                                    style={{
                                        height: 43,
                                        borderRadius: 6,
                                        borderColor: "rgb(203, 213, 224)"
                                    }}
                                    selectedValue={role}
                                    onValueChange={(itemValue, itemIndex) => {
                                        if (
                                            itemValue ===
                                                UserRole.MEDIA_PHOTOGRAPHER ||
                                            itemValue ===
                                                UserRole.MEDIA_VIDEO_OPERATOR
                                        ) {
                                            setShowOperatorData(true)
                                        } else {
                                            setShowOperatorData(false)
                                        }
                                        setRole(itemValue)
                                    }}
                                >
                                    <Picker.Item
                                        label={t("journalist")}
                                        value={UserRole.MEDIA_JOURNALIST}
                                    />
                                    <Picker.Item
                                        label={t("photographer")}
                                        value={UserRole.MEDIA_PHOTOGRAPHER}
                                    />
                                    <Picker.Item
                                        label={t("video_operator")}
                                        value={UserRole.MEDIA_VIDEO_OPERATOR}
                                    />
                                    <Picker.Item
                                        label={t("media_pr")}
                                        value={UserRole.MEDIA_PR}
                                    />
                                </Picker>

                                <Text mt="xl" mb="xs">
                                    {t("work_entity")} *
                                </Text>
                                <Picker
                                    style={{
                                        height: 43,
                                        borderRadius: 6,
                                        borderColor: "rgb(203, 213, 224)"
                                    }}
                                    selectedValue={
                                        isFreelancer === true
                                            ? EntityWork.FREELANCER
                                            : EntityWork.AGENCY
                                    }
                                    onValueChange={(itemValue, itemIndex) => {
                                        if (
                                            itemValue === EntityWork.FREELANCER
                                        ) {
                                            setIsFreelancer(true)
                                        } else {
                                            setIsFreelancer(false)
                                        }
                                    }}
                                >
                                    <Picker.Item
                                        label={t("freelance")}
                                        value={EntityWork.FREELANCER}
                                    />
                                    <Picker.Item
                                        label={t("agency")}
                                        value={EntityWork.AGENCY}
                                    />
                                </Picker>

                                {!isFreelancer && (
                                    <>
                                        <Input
                                            mt="xl"
                                            placeholder={t("agency_name")}
                                            onChangeText={(e) => {
                                                setAgencyName(e)
                                            }}
                                        />

                                        <Input
                                            mt="xl"
                                            placeholder={t("agency_email")}
                                            onChangeText={(e) => {
                                                setAgencyEmail(e)
                                            }}
                                        />
                                    </>
                                )}

                                <Text mt="xl" mb="xs">
                                    {t("number_card_order_of_journalists")}
                                </Text>
                                <Input
                                    placeholder={t(
                                        "number_card_order_of_journalists"
                                    )}
                                    onChangeText={(e) => {
                                        setOrderOfJournalistsCode(e)
                                    }}
                                />

                                <Text mt="xl" mb="xs">
                                    {t("list_published_docs")}
                                </Text>
                                <Div row>
                                    <Input
                                        placeholder={t("add_new")}
                                        value={publishedDocUrlsInput}
                                        style={{
                                            width: "100%"
                                        }}
                                        p={10}
                                        bg="white"
                                        focusBorderColor="blue700"
                                        mr={12}
                                        onChangeText={(e) => {
                                            setPublishedDocUrlsInput(e)
                                        }}
                                    />
                                </Div>
                                <Button
                                    mt={12}
                                    mb={4}
                                    onPress={() => {
                                        if (publishedDocUrlsInput) {
                                            let newPublishedDocUrls =
                                                publishedDocUrls || []

                                            let notDoubledUrls =
                                                newPublishedDocUrls.filter(
                                                    (element) => {
                                                        return (
                                                            element ==
                                                            publishedDocUrlsInput
                                                        )
                                                    }
                                                )
                                            if (notDoubledUrls.length > 0) {
                                                setComunicationSignUp(
                                                    t("duplicated_work_link")
                                                )
                                                return
                                            }

                                            newPublishedDocUrls.push(
                                                publishedDocUrlsInput
                                            )
                                            setPublishedDocUrls(
                                                newPublishedDocUrls
                                            )
                                            setPublishedDocUrlsInput("")
                                        }
                                    }}
                                >
                                    {t("add")}
                                </Button>

                                <Collapse>
                                    <Collapse.Header
                                        active
                                        justifyContent="center"
                                        fontSize="md"
                                        color="black"
                                        bg="white"
                                        p="sm"
                                        px="none"
                                        borderStyle="solid"
                                        borderColor="lightgray"
                                        h={30}
                                        borderWidth={1}
                                    >
                                        {t("show")}
                                    </Collapse.Header>
                                    <Collapse.Body pb="xl" mb={12}>
                                        {publishedDocUrls &&
                                            Array.isArray(publishedDocUrls) &&
                                            publishedDocUrls?.map((url) => {
                                                return (
                                                    <Div row mb={6}>
                                                        <Button
                                                            mr={6}
                                                            h={20}
                                                            w={20}
                                                            bg="#dd5a5a"
                                                            onPress={() => {
                                                                let newPublishedDocUrls =
                                                                    publishedDocUrls ||
                                                                    []
                                                                newPublishedDocUrls =
                                                                    newPublishedDocUrls.filter(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                element !=
                                                                                url
                                                                            )
                                                                        }
                                                                    )
                                                                setPublishedDocUrls(
                                                                    newPublishedDocUrls
                                                                )
                                                            }}
                                                        >
                                                            <Icon
                                                                name="trash"
                                                                fontFamily="FontAwesome5"
                                                                fontSize={12}
                                                                color="black"
                                                                h={20}
                                                                w={20}
                                                                rounded="md"
                                                            />
                                                        </Button>

                                                        <Text
                                                            style={{
                                                                marginBottom: 6,
                                                                backgroundColor:
                                                                    "lightred"
                                                            }}
                                                        >
                                                            - {url}
                                                        </Text>
                                                    </Div>
                                                )
                                            })}
                                    </Collapse.Body>
                                </Collapse>
                            </>
                        )}

                        {showOperatorData && (
                            <>
                                <Text mt="xl" mb="xs">
                                    {t("expiration_date_doc_cause_of_death")} *
                                </Text>
                                <DateTimePicker
                                    style={{ textAlign: "center" }}
                                    onChange={(e) => {
                                        const actualE = { ...e }
                                        setExpiringDateDocCauseOfDeath(
                                            () => new Date(actualE.target.value)
                                        )
                                    }}
                                />

                                <Text mt="xl" mb="xs">
                                    {t("expiration_date_doc_injuries")} *
                                </Text>
                                <DateTimePicker
                                    style={{ textAlign: "center" }}
                                    onChange={(e) => {
                                        const actualE = { ...e }
                                        setExpiringDateDocInjuries(
                                            () => new Date(actualE.target.value)
                                        )
                                    }}
                                />

                                <Text mt="xl" mb="xs">
                                    {t("expiration_date_doc_rc")} *
                                </Text>
                                <DateTimePicker
                                    style={{ textAlign: "center" }}
                                    onChange={(e) => {
                                        const actualE = { ...e }
                                        setExpiringDateDocRc(
                                            () => new Date(actualE.target.value)
                                        )
                                    }}
                                />
                            </>
                        )}

                        {role !== UserRole.ADMIN ? (
                            <>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginTop: 24
                                    }}
                                >
                                    <Checkbox
                                        mt="md"
                                        value={responsabilityAcceptance}
                                        onChecked={() => {
                                            setResponsabilityAcceptance(
                                                !responsabilityAcceptance
                                            )
                                        }}
                                    />
                                    <Text
                                        mt={8}
                                        textDecorLine="underline"
                                        onPress={() => {
                                            window.open(
                                                require("../../../assets/Scarico_Responsabilita.pdf"),
                                                "_blank"
                                            )
                                        }}
                                    >
                                        {t(
                                            "notice_discharge_of_responsibility"
                                        )}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <Checkbox
                                        mt="md"
                                        value={generalRegulamentationAcceptance}
                                        onChecked={() => {
                                            setGeneralRegulamentationAcceptance(
                                                !generalRegulamentationAcceptance
                                            )
                                        }}
                                    />
                                    <Text
                                        mt={8}
                                        textDecorLine="underline"
                                        onPress={() => {
                                            window.open(
                                                "https://www.monzanet.it/media/2022/02/Regolamento-Generale-Autodromo-Nazionale-Monza_V01-2016.pdf.pdf",
                                                "_blank"
                                            )
                                        }}
                                    >
                                        {t("notice_general_rules")}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <Checkbox
                                        mt="md"
                                        value={contractualConditionAcceptance}
                                        onChecked={() => {
                                            setContractualConditionAcceptance(
                                                !contractualConditionAcceptance
                                            )
                                        }}
                                    />
                                    <Text
                                        mt={8}
                                        textDecorLine="underline"
                                        onPress={() => {
                                            window.open(
                                                require("../../../assets/Condizioni_Contrattuali.pdf"),
                                                "_blank"
                                            )
                                        }}
                                    >
                                        {t("notice_contract")}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}
                                >
                                    <Checkbox
                                        mt="md"
                                        value={privacyAcceptance}
                                        onChecked={() => {
                                            setPrivacyAcceptance(
                                                !privacyAcceptance
                                            )
                                        }}
                                    />
                                    <Text
                                        mt={8}
                                        textDecorLine="underline"
                                        onPress={() => {
                                            window.open(
                                                "https://www.monzanet.it/media/2021/09/INFORMATIVA-PRIVACY.pdf",
                                                "_blank"
                                            )
                                        }}
                                    >
                                        {t("notice_privacy_policy")}
                                    </Text>
                                </View>
                            </>
                        ) : null}

                        <Button
                            w="100%"
                            mt="xl"
                            bg="red700"
                            borderWidth={1}
                            borderColor="red500"
                            color="white"
                            underlayColor="red400"
                            onPress={() => {
                                if (
                                    role === UserRole.HANDICAPPED ||
                                    role === UserRole.MEDIA_JOURNALIST ||
                                    role === UserRole.MEDIA_PR
                                ) {
                                    if (
                                        email &&
                                        email === confirmEmail &&
                                        password &&
                                        fiscalCode &&
                                        birthDate &&
                                        firstName &&
                                        lastName &&
                                        role &&
                                        city &&
                                        country &&
                                        postalCode &&
                                        address &&
                                        phoneNumber &&
                                        birthPlace &&
                                        expiringIdentityDocument &&
                                        responsabilityAcceptance &&
                                        generalRegulamentationAcceptance &&
                                        contractualConditionAcceptance &&
                                        privacyAcceptance
                                    ) {
                                        signUp({
                                            email,
                                            password,
                                            firstName,
                                            lastName,
                                            fiscalCode,
                                            birthDate,
                                            role,
                                            city,
                                            country,
                                            postalCode,
                                            address,
                                            phoneNumber,
                                            birthPlace,
                                            isFreelancer,
                                            agencyName,
                                            agencyEmail,
                                            orderOfJournalistsCardNumber,
                                            publishedDocUrls,
                                            expiringIdentityDocument,
                                            expiringDateDocCauseOfDeath,
                                            expiringDateDocInjuries,
                                            expiringDateDocRc,
                                            hasCauseOfDeathDocUpdated: false,
                                            hasIdentityDocumentBackDocUpdated:
                                                false,
                                            hasIdentityDocumentFrontDocUpdated:
                                                false,
                                            hasInjuriesDocUpdated: false,
                                            hasRcDocUpdated: false,
                                            hasBeenValidated: false
                                        })
                                            .then((res) => {
                                                if (res.success) {
                                                    setTokenStorage(
                                                        res.data.token
                                                    )
                                                    setUserRoleStorage(role)
                                                    setUserIdStorage(
                                                        res.data.id
                                                    )
                                                    setUserFirstNameStorage(
                                                        res.data.firstName
                                                    )

                                                    navigation.navigate(
                                                        "ThanksPage"
                                                    )
                                                }
                                            })
                                            .catch((err) => {
                                                setComunicationSignUp(
                                                    err || t("unknown_error")
                                                )
                                            })
                                    } else {
                                        setComunicationSignUp(
                                            t("fill_all_fields")
                                        )
                                    }
                                } else {
                                    if (
                                        email &&
                                        email === confirmEmail &&
                                        password &&
                                        fiscalCode &&
                                        birthDate &&
                                        firstName &&
                                        lastName &&
                                        role &&
                                        city &&
                                        country &&
                                        postalCode &&
                                        address &&
                                        phoneNumber &&
                                        birthPlace &&
                                        expiringIdentityDocument &&
                                        expiringDateDocCauseOfDeath &&
                                        expiringDateDocInjuries &&
                                        expiringDateDocRc &&
                                        responsabilityAcceptance &&
                                        generalRegulamentationAcceptance &&
                                        contractualConditionAcceptance &&
                                        privacyAcceptance
                                    ) {
                                        signUp({
                                            email,
                                            password,
                                            firstName,
                                            lastName,
                                            fiscalCode,
                                            birthDate,
                                            role,
                                            city,
                                            country,
                                            postalCode,
                                            address,
                                            phoneNumber,
                                            birthPlace,
                                            isFreelancer,
                                            agencyName,
                                            agencyEmail,
                                            orderOfJournalistsCardNumber,
                                            publishedDocUrls,
                                            expiringIdentityDocument,
                                            expiringDateDocCauseOfDeath,
                                            expiringDateDocInjuries,
                                            expiringDateDocRc,
                                            hasCauseOfDeathDocUpdated: false,
                                            hasIdentityDocumentBackDocUpdated:
                                                false,
                                            hasIdentityDocumentFrontDocUpdated:
                                                false,
                                            hasInjuriesDocUpdated: false,
                                            hasRcDocUpdated: false,
                                            hasBeenValidated: false
                                        })
                                            .then((res) => {
                                                if (res.success) {
                                                    setTokenStorage(
                                                        res.data.token
                                                    )
                                                    setUserRoleStorage(role)
                                                    setUserIdStorage(
                                                        res.data.id
                                                    )
                                                    setUserFirstNameStorage(
                                                        res.data.firstName
                                                    )
                                                    if (
                                                        role === UserRole.ADMIN
                                                    ) {
                                                        navigation.navigate(
                                                            "BackOfficeHome"
                                                        )
                                                    } else {
                                                        navigation.navigate(
                                                            "ThanksPage"
                                                        )
                                                    }
                                                }
                                            })
                                            .catch((err) => {
                                                setComunicationSignUp(
                                                    err || t("unknown_error")
                                                )
                                            })
                                    } else {
                                        setComunicationSignUp(
                                            t("fill_all_fields")
                                        )
                                    }
                                }
                            }}
                        >
                            {t("signup")}
                        </Button>

                        <Text
                            mt="xl"
                            w="100%"
                            fontWeight="bold"
                            fontSize="3xl"
                            textAlign="center"
                        >
                            {t("already_have_account")}
                        </Text>

                        <Button
                            w="100%"
                            mt="xl"
                            mb="xl"
                            bg="white"
                            borderWidth={1}
                            borderColor="red200"
                            color="red500"
                            underlayColor="red100"
                            onPress={() => {
                                navigation.navigate("Login")
                            }}
                        >
                            {t("login_button")}
                        </Button>
                    </Div>
                </ScrollDiv>
            </Content>
        </SafeAreaView>
    )
}

export default SignUp
