import React from "react"
import { useWindowDimensions } from "react-native"
import { Button, Div, Icon, Text } from "react-native-magnus"

interface IProps {
    navigateHome: () => void
    style?: any
    mb?: number
    mt?: number
    w?: string | number
    mr?: number
    ml?: number
    alignSelf?:
        | "auto"
        | "baseline"
        | "center"
        | "stretch"
        | "flex-end"
        | "flex-start"
    hasMaxWidth?: boolean
}

function HomeButton({
    navigateHome,
    style,
    mb,
    mt,
    mr,
    ml,
    w,
    alignSelf,
    hasMaxWidth
}: IProps) {
    const dimension = useWindowDimensions()

    return (
        <Button
            w={w}
            maxW={
                !hasMaxWidth
                    ? dimension.width < 530
                        ? undefined
                        : 120
                    : undefined
            }
            mt={mt}
            mb={mb}
            mr={mr}
            ml={ml}
            style={{ ...style }}
            fontWeight="bold"
            onPress={() => {
                navigateHome()
            }}
            alignSelf={alignSelf}
        >
            <Div
                row
                h={"100%"}
                flex={1}
                justifyContent="space-between"
                alignItems="center"
                px="m"
            >
                <Text color="white" fontWeight="bold" fontSize={20} mr={8}>
                    Home
                </Text>

                <Icon
                    name="home"
                    fontFamily="FontAwesome5"
                    fontSize={24}
                    color="white"
                    h={24}
                    w={24}
                    rounded="md"
                />
            </Div>
        </Button>
    )
}

export default HomeButton
