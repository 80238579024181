import { useAtom } from "jotai"
import jwtDecode, { JwtPayload } from "jwt-decode"
import { useEffect } from "react"
import { refreshToken } from "../api/fetches"
import { tokenAtomStorage } from "../context/atom"

export default function useTokenExpirationVerifier() {
    const [tokenStorage, setTokenStorage] = useAtom(tokenAtomStorage)

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout> | null = null

        if (tokenStorage) {
            timeout = setTimeout(() => {
                const decoded = jwtDecode<JwtPayload>(tokenStorage)
                const today = new Date()
                today.setMinutes(today.getMinutes() + 10)

                if (!decoded.exp || decoded.exp * 1000 < today.getTime()) {
                    if (tokenStorage) {
                        refreshToken(tokenStorage)
                            .then((res) => {
                                setTokenStorage(res.data)
                            })
                            .catch((err) => {
                                alert(
                                    "Aggiornamento Token non riuscito, effettuare nuovo login \n" +
                                        "Errore: " +
                                        err
                                )
                            })
                    }
                }
            }, 1000 * 60 * 9)
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [tokenStorage])
}
