import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useState } from "react"
import {
    Button,
    Div,
    Icon,
    Image,
    Input,
    Overlay,
    ScrollDiv,
    StatusBar,
    Text
} from "react-native-magnus"
import { SafeAreaView } from "react-native-safe-area-context"
import { login } from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import {
    isLoaderVisibleAtom,
    tokenAtomStorage,
    userFirstNameAtomStorage,
    userIdAtomStorage,
    userRoleAtomStorage
} from "../../../context/atom"
import { t } from "../../../strings"
import { UserRole } from "../../../types/prisma"

export type LoginProps = undefined

type LoginScreenRouteProp = NativeStackScreenProps<RootStackParamList, "Login">

const Login = ({ navigation }: LoginScreenRouteProp) => {
    const [tokenStorage, setTokenStorage] = useAtom(tokenAtomStorage)
    const [userRoleStorage, setUserRoleStorage] = useAtom(userRoleAtomStorage)
    const [userIdStorage, setUserIdStorage] = useAtom(userIdAtomStorage)
    const [userFirstNameStorage, setUserFirstNameStorage] = useAtom(
        userFirstNameAtomStorage
    )
    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    const [email, setEmail] = useState<string>()
    const [password, setPassword] = useState<string>()

    const [dialog, setDialog] = useState<string>()

    const onFormSubmit = () => {
        if (email && password) {
            setIsLoaderVisible(true)

            login(email, password)
                .then((res) => {
                    if (res.success) {
                        setTokenStorage(res.data.token)
                        setUserRoleStorage(res.data.role)
                        setUserIdStorage(res.data.id)
                        setUserFirstNameStorage(res.data.firstName)
                        if (res.data.role == UserRole.ADMIN) {
                            navigation.navigate("BackOfficeHome")
                        } else {
                            navigation.navigate("Home")
                        }
                    }
                })
                .catch((err) => {
                    if (err === "INVALID INPUT") {
                        setDialog(t("invalid_login"))
                    } else {
                        setDialog(err || t("unknown_error"))
                    }
                })
                .finally(() => {
                    setIsLoaderVisible(false)
                })
        }
    }

    return (
        <SafeAreaView style={{ paddingTop: StatusBar.currentHeight }}>
            <Content>
                <Overlay
                    visible={Boolean(dialog)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    setDialog(undefined)
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {dialog}
                        </Text>
                    </Div>
                </Overlay>

                <ScrollDiv h="100%">
                    <Div flex={1} mt={72}>
                        <Image
                            w={220}
                            h={220}
                            alignSelf="center"
                            source={require("../../../assets/icon.png")}
                        />

                        <Text mt="xl" w="100%" fontWeight="bold" fontSize="5xl">
                            {t("welcome")}
                        </Text>
                        <Text mt="md" w="100%" fontSize="lg" color="gray600">
                            {t("insert_credentials")}
                        </Text>
                        <Input
                            mt="xl"
                            placeholder={t("email")}
                            onChangeText={(e) => {
                                setEmail(e)
                            }}
                        />
                        <Input
                            mt="sm"
                            secureTextEntry
                            placeholder={t("password")}
                            textContentType="password"
                            onChangeText={(e) => {
                                setPassword(e)
                            }}
                        />
                        <Button
                            w="100%"
                            mt="xl"
                            bg="red700"
                            borderWidth={1}
                            borderColor="red500"
                            color="white"
                            underlayColor="red400"
                            onPress={() => {
                                onFormSubmit()
                            }}
                        >
                            {t("login_button")}
                        </Button>

                        <Text
                            mt="xl"
                            w="100%"
                            fontWeight="bold"
                            fontSize="3xl"
                            textAlign="center"
                        >
                            {t("no_account")}
                        </Text>

                        <Button
                            w="100%"
                            mt="xl"
                            bg="white"
                            borderWidth={1}
                            borderColor="red200"
                            color="red500"
                            underlayColor="red100"
                            onPress={() => {
                                navigation.navigate("SignUp")
                            }}
                        >
                            {t("signup")}
                        </Button>

                        <Text
                            mt={12}
                            fontSize={16}
                            textAlign={"center"}
                            textDecorLine="underline"
                            onPress={() => {
                                navigation.navigate("ForgotPassword")
                            }}
                        >
                            {t("forgot_password")}
                        </Text>
                    </Div>
                </ScrollDiv>
            </Content>
        </SafeAreaView>
    )
}

export default Login
