import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useEffect, useMemo, useState } from "react"
import { useWindowDimensions } from "react-native"
import {
    Button,
    Div,
    Icon,
    Overlay,
    ScrollDiv,
    Text
} from "react-native-magnus"
import {
    deleteUser,
    editUser,
    getUser,
    getUsers,
    getUsersWithoutCompleteDocs,
    ITEMS_PER_PAGE
} from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import DataTable, { DataTableProps } from "../../../components/DataTable"
import HomeButton from "../../../components/HomeButton"
import SearchBar from "../../../components/SearchBar"
import { isLoaderVisibleAtom, tokenAtomStorage } from "../../../context/atom"
import useRoleVerifier from "../../../hooks/useRoleVerifier"
import { User, UserRole } from "../../../types/prisma/index"
import { ReactiveCheckbox } from "../BackOfficeAccreditationTable"

export type BackOfficeUserTableProps = undefined

type BackOfficeUserTableScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "BackOfficeUserTable"
>

const BackOfficeUserTable = ({
    navigation,
    route
}: BackOfficeUserTableScreenRouteProp) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)

    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    const [usersList, setUsersList] = useState<User[]>()

    const [noDocumentUsersList, setNoDocumentUsersList] = useState<User[]>()

    const [isLoadMoreButtonVisible, setIsLoadMoreButtonVisible] =
        useState<boolean>(true)

    const [dialog, setDialog] = useState<string>()

    const [userIdToValidate, setUserIdToValidate] = useState<number>()

    const dimension = useWindowDimensions()

    const loadUsers = () => {
        if (tokenStorage) {
            setIsLoaderVisible(true)

            getUsers(tokenStorage, { first: ITEMS_PER_PAGE })
                .then((res) => {
                    if (res.success) {
                        setUsersList(res.data)
                        if (res.data.length < ITEMS_PER_PAGE) {
                            setIsLoadMoreButtonVisible(false)
                        }
                    }
                })
                .catch((e) => {
                    setDialog("Errore nel caricamento dei dati: " + e)
                })
                .finally(() => {
                    setIsLoaderVisible(false)
                })
        }
    }

    const loadUsersWithoutCompleteDocs = () => {
        if (tokenStorage) {
            setIsLoaderVisible(true)

            getUsersWithoutCompleteDocs(tokenStorage, { first: ITEMS_PER_PAGE })
                .then((res) => {
                    if (res.success) {
                        setNoDocumentUsersList(res.data)
                        setUsersList(undefined)
                        if (res.data.length < ITEMS_PER_PAGE) {
                            setIsLoadMoreButtonVisible(false)
                        }
                    }
                })
                .catch((e) => {
                    setDialog("Errore nel caricamento dei dati: " + e)
                })
                .finally(() => {
                    setIsLoaderVisible(false)
                })
        }
    }

    useEffect(() => {
        if (!tokenStorage) {
            navigation.navigate("Login")
        }
    }, [tokenStorage])

    useEffect(() => {
        loadUsers()
    }, [route.path])

    const dataTable = useMemo(() => {
        const props: DataTableProps<
            User & {
                modifica: undefined
                elimina: undefined
                validazione: undefined
            }
        > = {
            keys: {
                validazione: () => "Validazione",
                id: () => "Id",
                lastName: () => "Cognome",
                firstName: () => "Nome",
                role: () => "Ruolo",
                email: () => "Email",
                phoneNumber: () => "Numero di Telefono",
                agencyName: () => "Ente Lavorativo",
                modifica: () => "Modifica",
                elimina: () => "Elimina"
            },
            cells: {
                validazione: (e) => (
                    <ReactiveCheckbox
                        w={100}
                        isChecked={e.hasBeenValidated}
                        confirmationMessage={`Procedere alla Modifica dell'Utente ${e.lastName} ${e.firstName} con ID: ${e.id}?`}
                        onChange={(newValue) => {
                            if (tokenStorage && e.id) {
                                const userAttributesToEdit = {
                                    hasBeenValidated: newValue
                                }
                                editUser(
                                    e.id,
                                    tokenStorage,
                                    userAttributesToEdit
                                )
                            }
                        }}
                        disabled={false}
                    />
                ),
                id: (e) => e.id?.toString() || "No Id",
                lastName: (e) => e.lastName || "No Last Name",
                firstName: (e) => e.firstName || "No First Name",
                role: (e) =>
                    e.role == UserRole.ADMIN
                        ? "Admin"
                        : e.role == UserRole.MEDIA_JOURNALIST
                        ? "Giornalista"
                        : e.role == UserRole.MEDIA_PHOTOGRAPHER
                        ? "Fotografo"
                        : e.role == UserRole.MEDIA_VIDEO_OPERATOR
                        ? "Video Operatore"
                        : e.role == UserRole.MEDIA_PR
                        ? "PR/Addetto Stampa"
                        : "Disabile",
                email: (e) => e.email || "No Email",
                phoneNumber: (e) => e.phoneNumber || "No Phone Number",
                agencyName: (e) =>
                    (e.isFreelancer ? "Freelance" : e.agencyName) ||
                    "No Agency",
                modifica: (e) => (
                    <Div pr={12}>
                        <Button
                            w={150}
                            onPress={() => {
                                getUser(e.id!, tokenStorage!)
                                    .then((res) => {
                                        if (res.success) {
                                            navigation.navigate(
                                                "BackOfficeUserManagement",
                                                {
                                                    userId: e.id
                                                }
                                            )
                                            return
                                        }
                                    })
                                    .catch((e) => {
                                        setDialog(
                                            "Errore nel caricamento dell'utente: " +
                                                e
                                        )
                                    })
                            }}
                        >
                            Modifica
                        </Button>
                    </Div>
                ),
                elimina: (e) => (
                    <Div pr={12}>
                        <Button
                            w={150}
                            onPress={() => {
                                if (
                                    confirm(
                                        `Sei sicuro di voler eliminare l'utente "${e.id}"?`
                                    )
                                ) {
                                    deleteUser(e.id!, tokenStorage!)
                                    navigation.replace("BackOfficeUserTable")
                                }
                            }}
                        >
                            Elimina
                        </Button>
                    </Div>
                )
            },
            data: (usersList || []).map((u) => ({
                ...u,
                modifica: undefined,
                elimina: undefined,
                validazione: undefined
            }))
        }
        return <DataTable {...props} />
    }, [usersList])

    const dataTableWithNoDocs = useMemo(() => {
        const props: DataTableProps<
            User & {
                checkUpdateBox: undefined
                hasCauseOfDeathDoc: boolean
                hasIdentityDocumentBackDoc: boolean
                hasIdentityDocumentFrontDoc: boolean
                hasInjuriesDoc: boolean
                hasRcDoc: boolean
                modifica: undefined
            }
        > = {
            keys: {
                checkUpdateBox: () => "Presa Visione",
                id: () => "Id",
                lastName: () => "Cognome",
                firstName: () => "Nome",
                role: () => "Ruolo",
                email: () => "Email",
                phoneNumber: () => "Numero di Telefono",
                agencyName: () => "Ente Lavorativo",
                hasIdentityDocumentFrontDoc: () => "Carta di Identità Fronte",
                hasIdentityDocumentBackDoc: () => "Carta di Identità Retro",
                hasCauseOfDeathDoc: () => "Cause di Morte",
                hasInjuriesDoc: () => "Infortuni",
                hasRcDoc: () => "RC",
                modifica: () => "Modifica"
            },
            cells: {
                checkUpdateBox: (e) => (
                    <Div row mr={6} ml={6} w={100}>
                        <ReactiveCheckbox
                            w={40}
                            disabled={
                                !(
                                    e.hasIdentityDocumentBackDocUpdated ||
                                    e.hasCauseOfDeathDocUpdated ||
                                    e.hasIdentityDocumentFrontDocUpdated ||
                                    e.hasInjuriesDocUpdated ||
                                    e.hasRcDocUpdated
                                )
                            }
                            isChecked={
                                e.hasIdentityDocumentBackDocUpdated ||
                                e.hasCauseOfDeathDocUpdated ||
                                e.hasIdentityDocumentFrontDocUpdated ||
                                e.hasInjuriesDocUpdated ||
                                e.hasRcDocUpdated
                            }
                            confirmationMessage={`Procedere alla Conferma di Presa Visione delle Modifiche ai documenti dell'utente ${e.lastName} ${e.firstName} con ID: ${e.id}?`}
                            onChange={(newValue) => {
                                if (tokenStorage && e.id) {
                                    const userAttributesToEdit: Partial<User> =
                                        {
                                            hasCauseOfDeathDocUpdated: false,
                                            hasIdentityDocumentBackDocUpdated:
                                                false,
                                            hasIdentityDocumentFrontDocUpdated:
                                                false,
                                            hasInjuriesDocUpdated: false,
                                            hasRcDocUpdated: false
                                        }
                                    editUser(
                                        e.id,
                                        tokenStorage,
                                        userAttributesToEdit
                                    )
                                }
                            }}
                        />
                        <Button
                            w={20}
                            h={20}
                            p={6}
                            mt={4}
                            onPress={() => {
                                if (e.id && tokenStorage) {
                                    getUser(e.id, tokenStorage).then((res) => {
                                        const hasIdentityDocumentBackDoc =
                                            res.data
                                                .hasIdentityDocumentBackDocUpdated &&
                                            "Documento d'Identità Retro"
                                        const hasIdentityDocumentFrontDoc =
                                            res.data
                                                .hasIdentityDocumentFrontDocUpdated &&
                                            "Documento d'Identità Fronte"
                                        const hasCauseOfDeathDoc =
                                            res.data
                                                .hasCauseOfDeathDocUpdated &&
                                            "Documento Assicurazione Morte"
                                        const hasInjuriesDoc =
                                            res.data.hasInjuriesDocUpdated &&
                                            "Documento Assicurazione Infortuni"
                                        const hasRcDoc =
                                            res.data.hasRcDocUpdated &&
                                            "Documento Responsabilità Civile"
                                        const message = [
                                            hasIdentityDocumentFrontDoc,
                                            hasIdentityDocumentBackDoc,
                                            hasCauseOfDeathDoc,
                                            hasInjuriesDoc,
                                            hasRcDoc
                                        ]
                                            .filter(Boolean)
                                            .join("\n")
                                        setDialog(
                                            `Documenti Aggiornati:\n\n${
                                                message || "Nessun Documento"
                                            }`
                                        )
                                    })
                                }
                            }}
                        >
                            <Icon
                                name="eye"
                                color="#ffffff"
                                fontFamily="FontAwesome5"
                                fontSize="12px"
                            />
                        </Button>
                    </Div>
                ),
                id: (e) => e.id?.toString() || "No ID",
                lastName: (e) => e.lastName || "No Surname",
                firstName: (e) => e.firstName || "No First Name",
                role: (e) =>
                    e.role == UserRole.ADMIN
                        ? "Admin"
                        : e.role == UserRole.MEDIA_JOURNALIST
                        ? "Giornalista"
                        : e.role == UserRole.MEDIA_PHOTOGRAPHER
                        ? "Fotografo"
                        : e.role == UserRole.MEDIA_VIDEO_OPERATOR
                        ? "Video Operatore"
                        : e.role == UserRole.MEDIA_PR
                        ? "PR/Addetto Stampa"
                        : "Disabile",
                email: (e) => e.email || "No Email",
                phoneNumber: (e) => e.phoneNumber || "No Phone Number",
                agencyName: (e) =>
                    (e.isFreelancer ? "Freelance" : e.agencyName) ||
                    "No Agency",
                hasIdentityDocumentBackDoc: (e) =>
                    e.hasIdentityDocumentBackDoc ? "Sì" : "No",
                hasIdentityDocumentFrontDoc: (e) =>
                    e.hasIdentityDocumentFrontDoc ? "Sì" : "No",
                hasCauseOfDeathDoc: (e) =>
                    e.role === UserRole.MEDIA_JOURNALIST ||
                    e.role === UserRole.MEDIA_PR
                        ? "//"
                        : e.hasCauseOfDeathDoc
                        ? "Sì"
                        : "No",
                hasInjuriesDoc: (e) =>
                    e.role === UserRole.MEDIA_JOURNALIST ||
                    e.role === UserRole.MEDIA_PR
                        ? "//"
                        : e.hasInjuriesDoc
                        ? "Sì"
                        : "No",
                hasRcDoc: (e) =>
                    e.role === UserRole.MEDIA_JOURNALIST ||
                    e.role === UserRole.MEDIA_PR
                        ? "//"
                        : e.hasRcDoc
                        ? "Sì"
                        : "No",
                modifica: (e) => (
                    <Div pr={12}>
                        <Button
                            w={150}
                            onPress={() => {
                                getUser(e.id!, tokenStorage!)
                                    .then((res) => {
                                        if (res.success) {
                                            navigation.navigate(
                                                "BackOfficeUserManagement",
                                                {
                                                    userId: e.id
                                                }
                                            )
                                            return
                                        }
                                    })
                                    .catch((e) => {
                                        setDialog(
                                            "Errore nel caricamento dell'utente: " +
                                                e
                                        )
                                    })
                            }}
                        >
                            Modifica
                        </Button>
                    </Div>
                )
            },
            data: (noDocumentUsersList || []).map((u: any) => ({
                ...u,
                checkUpdateBox: undefined,
                hasCauseOfDeathDoc: u.hasCauseOfDeathDoc,
                hasIdentityDocumentBackDoc: u.hasIdentityDocumentBackDoc,
                hasIdentityDocumentFrontDoc: u.hasIdentityDocumentFrontDoc,
                hasInjuriesDoc: u.hasInjuriesDoc,
                hasRcDoc: u.hasRcDoc,
                modifica: undefined
            }))
        }
        return <DataTable {...props} />
    }, [noDocumentUsersList])

    useRoleVerifier()

    return (
        <ScrollDiv>
            <Content>
                <Overlay
                    visible={Boolean(dialog)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    setDialog(undefined)
                                    setUserIdToValidate(undefined)
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>

                            {dialog?.startsWith("Procedere") && (
                                <Button
                                    bg="transparent"
                                    alignSelf="center"
                                    onPress={() => {
                                        console.log("test")
                                    }}
                                >
                                    Ok
                                </Button>
                            )}
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {dialog}
                        </Text>
                    </Div>
                </Overlay>

                <Text fontSize={52} fontWeight="bold" my={24}>
                    Gestione Utenti
                </Text>

                <Div row={dimension.width < 600 ? false : true}>
                    <Button
                        w={dimension.width < 600 ? "100%" : undefined}
                        mb={24}
                        fontWeight="bold"
                        fontSize="2xl"
                        alignSelf="flex-start"
                        mr={12}
                        style={{}}
                        onPress={() => {
                            navigation.navigate("BackOfficeUserManagement", {
                                userId: undefined
                            })
                        }}
                    >
                        Aggiungi
                    </Button>

                    <Button
                        w={dimension.width < 600 ? "100%" : undefined}
                        mb={24}
                        fontWeight="bold"
                        fontSize="2xl"
                        alignSelf="flex-start"
                        mr={12}
                        style={{}}
                        onPress={() => {
                            if (usersList) {
                                loadUsersWithoutCompleteDocs()
                            } else {
                                loadUsers()
                            }
                        }}
                    >
                        {usersList
                            ? "Carica Utenti Senza Documenti"
                            : "Carica Tutti gli Utenti"}
                    </Button>

                    <HomeButton
                        w={dimension.width < 600 ? "100%" : undefined}
                        hasMaxWidth={true}
                        mb={24}
                        alignSelf={"flex-end"}
                        navigateHome={() => {
                            navigation.navigate("BackOfficeHome")
                        }}
                    />
                </Div>

                <SearchBar
                    style={{ marginTop: 6 }}
                    label="Cerca Utente"
                    searchType={"users"}
                    onChange={(q) => {
                        if (q.length === 0) {
                            loadUsers()
                        }
                    }}
                    result={(item) => (
                        <p
                            key={`data-search-suggestion-${item.id}`}
                            style={{
                                fontWeight: "bold",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                if (usersList) {
                                    console.log(item)
                                    setUsersList([item])
                                } else {
                                    setNoDocumentUsersList([item])
                                }
                            }}
                        >
                            <Text>
                                {item.firstName} {item.lastName}, {item.email}
                            </Text>
                        </p>
                    )}
                />

                {usersList ? dataTable : dataTableWithNoDocs}

                {isLoadMoreButtonVisible && (
                    <Button
                        mt={12}
                        mb={12}
                        alignSelf="center"
                        onPress={() => {
                            if (tokenStorage) {
                                if (usersList) {
                                    getUsers(tokenStorage, {
                                        first: ITEMS_PER_PAGE,
                                        lastUserId:
                                            usersList?.[usersList.length - 1].id
                                    }).then((res) => {
                                        const newUsersList = (
                                            usersList || []
                                        ).concat(res.data)
                                        setUsersList(newUsersList)

                                        if (res.data.length < ITEMS_PER_PAGE) {
                                            setIsLoadMoreButtonVisible(false)
                                        }
                                    })
                                } else {
                                    getUsersWithoutCompleteDocs(tokenStorage, {
                                        first: ITEMS_PER_PAGE,
                                        lastUserId:
                                            noDocumentUsersList?.[
                                                noDocumentUsersList.length - 1
                                            ].id
                                    }).then((res) => {
                                        const newNoDocumentUsersList = (
                                            noDocumentUsersList || []
                                        ).concat(res.data)
                                        setNoDocumentUsersList(
                                            newNoDocumentUsersList
                                        )

                                        if (res.data.length < ITEMS_PER_PAGE) {
                                            setIsLoadMoreButtonVisible(false)
                                        }
                                    })
                                }
                            }
                        }}
                    >
                        Carica Altri Utenti
                    </Button>
                )}
            </Content>
        </ScrollDiv>
    )
}

export default BackOfficeUserTable
