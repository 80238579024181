export const json2Csv = (json: any) => {
    const fields = Object.keys(json[0])
    const replacer = (key: any, value: any) => {
        return value === null ? "" : value
    }
    let csv = json.map((row: any) =>
        fields
            .map((fieldName: any) => JSON.stringify(row[fieldName], replacer))
            .join(";")
    )
    csv.unshift(fields.join(";")) // add header column
    csv = csv.join("\r\n")
    return csv
}
