import React from "react"
import { useWindowDimensions } from "react-native"
import { Div } from "react-native-magnus"

const MAX_CONTENT_WIDTH = 1200

interface IProps {
    children: any
    style?: any
}

const Content = ({ children, style }: IProps) => {
    const windowDimensions = useWindowDimensions()

    let mx: number | string = "xl"
    if (windowDimensions.width > MAX_CONTENT_WIDTH) {
        mx = (windowDimensions.width - MAX_CONTENT_WIDTH) / 2
    }

    return (
        <Div mx={mx} style={style}>
            {children}
        </Div>
    )
}

export default Content
