import { useMemo } from "react"
import { createElement } from "react-native"

interface IProps {
    value?: Date
    onChange: (e: any) => void
    style?: any
}

export default function DateTimePicker({
    value,
    onChange,
    style: styleProp
}: IProps) {
    const e = useMemo(() => {
        return createElement("input", {
            type: "date",
            value,
            onInput: onChange,
            style: {
                width: "calc(100% - 12px)",
                height: "38px",
                border: "1px solid rgb(203, 213, 224)",
                borderRadius: "8px",
                ...styleProp
            }
        })
    }, [value])

    return e
}
