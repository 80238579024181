import i18n from "i18n-js"

i18n.translations = {
    it: {
        // Login
        login_button: "Login",
        welcome: "Benvenuto!",
        insert_credentials:
            "Inserisci le tue credenziali per effettuare l'accesso",
        no_account: "Non hai un account?",
        forgot_password: "Hai dimenticato la password?",
        incomplete_login: "Campi incompleti",
        invalid_login: "Combinazione email e password non corretta",

        // Password Dimenticata
        create_new_password: "Crea nuova password",
        check_email: "Controlla le tue email",
        check_email_for_event:
            "Ti arriverà una mail di conferma o di rifiuto dell'accredito a ridosso dell'evento",
        request_password_reset_link: "Richiedi link di reset password",
        done: "Fatto!",
        incorrect_email_forgot_password:
            "L'Email inserita non è corretta, riprovare",
        error_forgot_password_request: "Richiesta non riuscita. Errore: ",

        // Registrazione
        already_have_account: "Hai già un account?",
        signup: "Registrati",
        create_credentials: "Crea le tue credenziali",
        email: "Email",
        email_confirmation: "Conferma email",
        email_mismatch: "Le email non corrispondono",
        password_not_valid:
            "La Password dovrebbe essere di almeno 9 caratteri, contenere almeno un carattere maiuscolo, almeno uno minuscolo e almeno un numero.",
        insert_email: "Inserisci la tua email",
        password: "Password",
        name: "Nome",
        surname: "Cognome",
        birthdate: "Data di nascita",
        birthplace: "Luogo di nascita",
        fiscal_code: "Codice fiscale",
        nationality: "Nazione di residenza",
        city: "Città",
        postal_code: "CAP",
        address: "Indirizzo",
        phone: "Telefono",
        user_type: "Tipologia utente per il pass",
        user_role: "Qualifica",
        media_pr: "PR/addetto stampa",
        handicapped: "Disabile",
        journalist: "Giornalista",
        video_operator: "Videoperatore",
        photographer: "Fotografo",
        work_entity: "Seleziona Ente di Lavoro",
        agency: "Pubblicazione/Agenzia",
        agency_name: "Nome pubblicazione/agenzia",
        agency_email: "Email pubblicazione/agenzia",
        freelance: "Freelance",
        number_card_order_of_journalists:
            "Numero tessera Ordine dei Giornalisti",
        list_published_docs:
            "Link a lavori (articoli/foto/video) pubblicati a propria firma",
        show_works: "Mostra lavori",
        published_works: "Lavori pubblicati",
        add_to_list: "Aggiungi alla lista",
        add_new: "Aggiungi nuovo link",
        expiration_date_id: "Data di scadenza documento di identità",
        expiration_date_doc_injuries:
            "Data di scadenza assicurazione infortuni",
        expiration_date_doc_cause_of_death:
            "Data di scadenza assicurazione causa morte",
        expiration_date_doc_rc: "Data di scadenza assicurazione RC terzi",
        notice_discharge_of_responsibility:
            "Ho letto e accetto il modulo per lo scarico di responsabilità",
        notice_general_rules: "Ho letto e accetto il regolamento generale",
        notice_contract: "Ho letto e accetto le condizioni contrattuali",
        notice_privacy_policy:
            "Dichiaro di manifestare il mio esplicito consenso al trattamento dei miei dati secondo quanto riportato nell'informativa sulla privacy",
        unset: "Non impostato",
        fill_all_fields: "Compila tutti i campi",
        duplicated_work_link:
            "Errore nell'inserimento dell'Url dell'articolo: Url già inserito",

        // Thanks Page
        thanks_page_text:
            "Hai completato la prima parte della registrazione. Puoi ora procedere al caricamento dei documenti assicurativi nella pagina dedicata, all'interno del tuo profilo.",
        go_to_profile: "Vai al Profilo",
        go_to_home: "Vai alla Home",

        // Home Utente
        user_profile: "Profilo utente",
        accreditations_history: "Storico accrediti",
        go_to_admin_panel: "Vai al pannello di amministrazione",
        logout: "Esci",
        go_to_event: "Vai all'evento",
        show_in_new_tab: "Mostra in una nuova scheda",

        // Profilo Utente
        edit: "Modifica",
        show: "Mostra",
        file_format_label:
            "Selezionare un file con formato .pdf o .jpg o .jpeg con una dimensione massima di 10 Mb",
        upload: "Carica",
        uploading: "Caricamento...",
        already_uploaded: "Caricato in precedenza",
        success_edit: "Modifiche apportate con successo",
        success_uploading: "Caricamento del File avvenuto con successo",
        not_uploaded: "Non caricato",
        file_not_valid:
            "Tipologia file non supportata. Selezionare un file .pdf o .jpg o .jpeg",
        doc_identity_front: "Documento di identità (fronte):",
        doc_identity_back: "Documento di identità (retro):",
        doc_cause_of_death: "Assicurazione causa morte:",
        doc_injuries: "Assicurazione infortuni:",
        doc_rc: "Assicurazione RC terzi:",
        apply: "Effettua modifiche",
        add: "Aggiungi",
        professional_doc: "Tessera Ordine dei Giornalisti",
        delete: "Elimina",

        // Storico Accrediti
        filter_by_event: "Filtra per evento",
        search_events: "Cerca eventi",
        search_users: "Cerca utenti",
        confirm: "Conferma",
        status: "Stato",
        yes: "Sì",
        no: "No",
        pending: "In attesa",
        approved: "Approvato",
        denied: "Negato",
        needs_user_update: "Richiede aggiornamento da parte dell'utente",
        confirmed: "Confermato",
        bib: "Pettorina",
        filter: "Filtro",
        load_more: "Carica altro",

        // Pagina dell'Evento
        event_name: "Nome evento",
        loading: "Caricamento...",
        request_bib: "Richiedo anche la pettorina/pista",
        request_accreditation: "Richiedo l'accredito per l'evento",
        event_accreditation_closed:
            "Non è possibile richiedere l'accredito perchè il termine per la richiesta è superato",
        event_not_opened_yet:
            "Non è possibile richiedere l'accredito perchè la richiesta per gli accrediti non è ancora stata aperta",
        user_not_validated:
            "Non è possibile richiedere l'accredito perchè il profilo non è stato ancora validato dall'ufficio stampa",
        unauthorized:
            "Non si dispone dei permessi per richiede l'accredito all'evento",
        already_accreditated: "L'utente ha già richiesto l'accredito",
        doc_cause_of_death_expiry_date_not_met:
            "Data di scadenza dell'assicurazione causa morte non valida",
        doc_injuries_expiry_date_not_met:
            "Data di scadenza dell'assicurazione infortuni non valida",
        doc_rc_expiry_date_not_met:
            "Data di scadenza dell'assicurazione RC terzi non valida",
        doc_identity_expiry_date_not_met:
            "Data di scadenza del documento d'identità non valida",
        request_accreditation_generic_error:
            "Errore durante la richiesta di accredito: ",

        // Notifiche Utente
        notifications: "Notifiche",
        no_notifications: "Nessuna nuova notifica ✅",

        // Varie
        accreditations_management: "Gestione accrediti",
        unknown_error: "Errore Sconosciuto: ",
        not_set: "Non impostato"
    },
    en: {
        // Login
        login_button: "Login",
        welcome: "Welcome!",
        insert_credentials: "Enter your credentials to login",
        no_account: "Don't have an account?",
        forgot_password: "Forgot your password?",
        incomplete_login: "Fill all fields",
        invalid_login: "Incorrect username or password",

        // Password Dimenticata
        create_new_password: "Create a new password",
        check_email: "Check your email inbox",
        check_email_for_event:
            "You will receive a confirm or reject email for your accreditation request close to the start of the event",
        request_password_reset_link: "Request a link to reset your password",
        done: "Done!",
        incorrect_email_forgot_password: "Incorrect email, please retry",
        error_forgot_password_request: "Request failed, Error: ",

        // Registrazione
        already_have_account: "Already have an account?",
        signup: "Sign up",
        create_credentials: "Create your credentials",
        email: "Email",
        email_confirmation: "Email confirmation",
        email_mismatch: "Email addresses don't match",
        password_not_valid:
            "Password should be at least 9 characters long, contains at least 1 capital letter, at least 1 lower-case letter and at least 1 number.",
        insert_email: "Insert your email",
        password: "Password",
        name: "Name",
        surname: "Surname",
        birthdate: "Date of birth",
        birthplace: "Place of birth",
        fiscal_code: "Italian fiscal code",
        nationality: "Country of residence",
        city: "City",
        postal_code: "Postal Code",
        address: "Address",
        phone: "Telephone number",
        user_type: "User type for the pass",
        user_role: "Qualification",
        media_pr: "PR/press officer",
        handicapped: "Disabled people",
        journalist: "Journalist",
        video_operator: "Videomaker",
        photographer: "Photographer",
        work_entity: "Work entity",
        agency: "Publication/Agency",
        agency_name: "Name publication/agency",
        agency_email: "Email publication/agency",
        freelance: "Freelance",
        number_card_order_of_journalists: "Journalist association card number",
        list_published_docs:
            "Links to works (article/photos/videos) published with your signature",
        show_works: "Show works",
        published_works: "Published works",
        add_to_list: "Add to the list",
        add_new: "Add a new link",
        expiration_date_id: "ID card expiration date",
        expiration_date_doc_injuries: "Accident case insurance expiration date",
        expiration_date_doc_cause_of_death:
            "Death case insurance expiration date",
        expiration_date_doc_rc:
            "Third party liability insurance expiration date",
        notice_discharge_of_responsibility:
            "I have read and accept the liability waiver document",
        notice_general_rules: "I have read and accept the general regulations",
        notice_contract: "I have read and accept the terms and conditions",
        notice_privacy_policy:
            "I declare to express my explicit consent to the processing of my data as described in the privacy policy",
        unset: "Not set",
        fill_all_fields: "Complete all field",
        duplicated_work_link: "Error: Duplicated Published Work Link",

        // Thanks Page
        thanks_page_text:
            "You have completed the first part of the registration process. You can now proceed to upload your insurance documents on the dedicated page, within your profile.",
        go_to_profile: "Go to profile page",
        go_to_home: "Go to home page",

        // Home Utente
        user_profile: "User profile",
        accreditations_history: "Accreditation history",
        go_to_admin_panel: "Go to the administration panel",
        logout: "Logout",
        go_to_event: "Go to the event",
        show_in_new_tab: "Show in new tab",

        // Profilo Utente
        edit: "Edit",
        show: "Show",
        file_format_label:
            "Select a file with a format as .pdf or .jpg or .jpeg with a maximum size of 10 Mb",
        upload: "Upload",
        uploading: "Uploading...",
        already_uploaded: "Already uploaded",
        success_edit: "Changes applied successfully",
        success_uploading: "File Successfully Uploaded",
        not_uploaded: "Not uploaded",
        file_not_valid:
            "File not supported. Please select a file format as .pdf or .jpg or .jpeg",
        doc_identity_front: "ID card (front):",
        doc_identity_back: "ID card (back):",
        doc_cause_of_death: "Death case insurance:",
        doc_injuries: "Accident case insurance:",
        doc_rc: "Third party liability insurance:",
        apply: "Apply",
        add: "Add",
        professional_doc: "Journalist association card number",
        delete: "Delete",

        // Storico Accrediti
        filter_by_event: "Filter by event",
        search_events: "Search event",
        search_users: "Search users",
        confirm: "Confirm",
        status: "Status",
        yes: "Yes",
        no: "No",
        pending: "Pending",
        approved: "Approved",
        denied: "Denied",
        needs_user_update: "Requires user update",
        confirmed: "Confirmed",
        bib: "Tabard",
        filter: "Filter",
        load_more: "Load more",

        // Pagina dell'Evento
        event_name: "Name event",
        loading: "Loading...",
        request_bib: "I also require the track tabard",
        request_accreditation: "I request an accreditation for the event",
        event_accreditation_closed:
            "It's not possible to request the accreditation because the deadline for the request has passed",
        event_not_opened_yet:
            "It's not possible to request the accreditation because the accreditation request time window is not opened yet",
        user_not_validated:
            "It's not possible to request the accreditation because the profile has not yet been validated by the press office",
        unauthorized:
            "You don't have permission to apply for an accreditation to the event",
        already_accreditated: "User has already requested an accreditation",
        doc_cause_of_death_expiry_date_not_met:
            "Cause of death insurance expiration date invalid",
        doc_injuries_expiry_date_not_met:
            "Accident case insurance expiration date invalid",
        doc_rc_expiry_date_not_met:
            "Third party liability insurance expiration date invalid",
        doc_identity_expiry_date_not_met: "ID card expiration date invalid",
        request_accreditation_generic_error:
            "Error during accreditation request: ",

        // Notifiche Utente
        notifications: "Notifications",
        no_notifications: "No new notifications ✅",

        // Varie
        accreditations_management: "Accreditations management",
        unknown_error: "Unknown Error: ",
        not_set: "Not set"
    }
}

i18n.defaultLocale = "it"
i18n.locale = navigator.language.split("-")[0]
i18n.fallbacks = true

const t = i18n.t

export { t }
