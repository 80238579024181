import { NativeStackScreenProps } from "@react-navigation/native-stack"
import React, { useState } from "react"
import {
    Button,
    Div,
    Icon,
    Image,
    Input,
    Overlay,
    Text
} from "react-native-magnus"
import { createNewPassword } from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import { t } from "../../../strings"

export type ForgotPasswordUpdateProps = undefined

type ForgotPasswordUpdateRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "ForgotPasswordUpdate"
>

const ForgotPasswordUpdate = ({
    navigation
}: ForgotPasswordUpdateRouteProp) => {
    const [password, setPassword] = useState<string>()

    const [dialog, setDialog] = useState<string>()

    return (
        <Content
            style={{
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}
        >
            <Overlay
                visible={Boolean(dialog)}
                p="xl"
                w={"80%"}
                style={{
                    maxHeight: "60vh",
                    maxWidth: 350
                }}
            >
                <Div>
                    <Div row alignItems="center" justifyContent="space-between">
                        <Text mr={12} fontSize={18} fontWeight="bold">
                            Avviso
                        </Text>
                        <Button
                            bg="transparent"
                            alignSelf="flex-end"
                            onPress={() => {
                                setDialog(undefined)
                            }}
                        >
                            <Icon
                                name="times"
                                color="#424242"
                                fontFamily="FontAwesome5"
                                fontSize="16px"
                            />
                        </Button>
                    </Div>
                </Div>
                <Div mt={24} justifyContent="center" alignItems="center">
                    <Text w={"100%"} textAlign="center">
                        {dialog}
                    </Text>
                </Div>
            </Overlay>

            <Div>
                <Image
                    mb={56}
                    w={220}
                    h={220}
                    alignSelf="center"
                    source={require("../../../assets/icon.png")}
                />

                <Text fontSize={24} mb={24} fontWeight="bold">
                    {t("create_new_password")}
                </Text>

                <Input
                    maxW="256px"
                    mb={24}
                    onChangeText={(e) => {
                        setPassword(e)
                    }}
                />

                <Button
                    maxW="256px"
                    w="100%"
                    mr={12}
                    onPress={() => {
                        if (password && password?.length > 6) {
                            const token = new URL(
                                window.location.href
                            ).searchParams.get("token")
                            if (token) {
                                createNewPassword(password, token)
                                    .then((res) => {
                                        setDialog(t("done"))

                                        if (res.success) {
                                            navigation.navigate("Login")
                                        }
                                    })
                                    .catch((err) => {
                                        setDialog(
                                            t("error_forgot_password_request") +
                                                err
                                        )
                                    })
                            }
                        }
                    }}
                >
                    {t("apply")}
                </Button>
            </Div>
        </Content>
    )
}

export default ForgotPasswordUpdate
