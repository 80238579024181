import { Div, Input, Text } from "react-native-magnus"
import { useAtom } from "jotai"
import React, { useEffect, useRef, useState } from "react"
import { search } from "../api/fetches"
import { tokenAtomStorage } from "../context/atom"
import { t } from "../strings"

interface Props {
    label: string
    subLabel?: () => JSX.Element | JSX.Element[]
    result: (item: any) => JSX.Element | JSX.Element[]
    style?: any
    searchType: "events" | "users"
    onChange: (q: string) => void
    onClickLabel?: () => void
}

// barra di ricerca usata per i filtri nelle tabelle
export default function SearchBar({
    label,
    subLabel,
    result,
    style,
    searchType,
    onChange,
    onClickLabel
}: Props) {
    const [userTokenStorage] = useAtom(tokenAtomStorage)

    const [query, setQuery] = useState<string>()
    const [data, setData] = useState<any[]>([])
    const firstRender = useRef<Boolean>(true)

    useEffect(() => {
        if (userTokenStorage && query && query.length > 3) {
            search(userTokenStorage, query, [searchType]).then((res) => {
                setData(res.data[searchType])
            })
        } else {
            setData([])
        }
        if (!firstRender.current) {
            onChange(query || "")
        } else {
            firstRender.current = false
        }
    }, [query])

    return (
        <>
            <Text
                fontWeight={"bold"}
                fontSize={"md"}
                style={style}
                onPress={() => {
                    if (onClickLabel) {
                        onClickLabel()
                    }
                }}
            >
                {label}
            </Text>
            {subLabel?.()}

            <Input
                placeholder={
                    searchType === "users"
                        ? t("search_users")
                        : t("search_events")
                }
                value={query}
                onChangeText={(e) => {
                    setQuery(e)
                }}
                mt={"4"}
            />
            <Div>{data.map(result)}</Div>
        </>
    )
}
