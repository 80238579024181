import { NativeStackScreenProps } from "@react-navigation/native-stack"
import React, { useState } from "react"
import {
    Button,
    Div,
    Icon,
    Image,
    Input,
    Overlay,
    Text
} from "react-native-magnus"
import { forgotPasswordRequest } from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import { t } from "../../../strings"

export type ForgotPasswordProps = undefined

type ForgotPasswordRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "ForgotPassword"
>

const ForgotPassword = ({ navigation }: ForgotPasswordRouteProp) => {
    const [email, setEmail] = useState<string>()

    const [dialog, setDialog] = useState<string>()

    return (
        <Content
            style={{
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}
        >
            <Overlay
                visible={Boolean(dialog)}
                p="xl"
                w={"80%"}
                style={{
                    maxHeight: "60vh",
                    maxWidth: 350
                }}
            >
                <Div>
                    <Div row alignItems="center" justifyContent="space-between">
                        <Text mr={12} fontSize={18} fontWeight="bold">
                            Avviso
                        </Text>
                        <Button
                            bg="transparent"
                            alignSelf="flex-end"
                            onPress={() => {
                                setDialog(undefined)
                            }}
                        >
                            <Icon
                                name="times"
                                color="#424242"
                                fontFamily="FontAwesome5"
                                fontSize="16px"
                            />
                        </Button>
                    </Div>
                </Div>
                <Div mt={24} justifyContent="center" alignItems="center">
                    <Text w={"100%"} textAlign="center">
                        {dialog}
                    </Text>
                </Div>
            </Overlay>

            <Div maxW="512px">
                <Image
                    mb={56}
                    w={220}
                    h={220}
                    alignSelf="center"
                    source={require("../../../assets/icon.png")}
                />

                <Text fontSize={24} mb={24} fontWeight="bold">
                    {t("create_new_password")}
                </Text>

                <Text fontSize={16} mb={16}>
                    {t("insert_email")}
                </Text>

                <Input
                    mb={24}
                    onChangeText={(e) => {
                        setEmail(e)
                    }}
                />

                <Div row w="100%">
                    <Button
                        w="100%"
                        mr={12}
                        onPress={() => {
                            if (email) {
                                forgotPasswordRequest(email)
                                    .then((res) => {
                                        if (res.success) {
                                            setDialog(
                                                t("done") +
                                                    " " +
                                                    t("check_email")
                                            )
                                        }

                                        if (res.error === "RECORD NOT FOUND") {
                                            setDialog(
                                                t(
                                                    "incorrect_email_forgot_password"
                                                )
                                            )
                                        }
                                    })
                                    .catch((err) => {
                                        setDialog(
                                            t("error_forgot_password_request") +
                                                err
                                        )
                                    })
                            }
                        }}
                    >
                        {t("request_password_reset_link")}
                    </Button>
                </Div>
            </Div>
        </Content>
    )
}

export default ForgotPassword
