import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useState } from "react"
import { Button, Div, Icon, Input, Overlay, Text } from "react-native-magnus"
import { sendEmail } from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import HomeButton from "../../../components/HomeButton"
import { tokenAtomStorage } from "../../../context/atom"
import useRoleVerifier from "../../../hooks/useRoleVerifier"

interface IProps {
    eventName: string
    eventStartTime: Date
    eventEndTime: Date
    userIds: number[]
}

export type BackOfficeClosingAccreditationConfirmedProps = IProps

type BackOfficeClosingAccreditationConfirmedRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "BackOfficeClosingAccreditationConfirmed"
>

const BackOfficeClosingAccreditationConfirmed = ({
    navigation,
    route
}: BackOfficeClosingAccreditationConfirmedRouteProp) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)

    const [dialog, setDialog] = useState<string>()

    const [standardConfirmEmailObject, setStandardConfirmEmailObject] =
        useState<string>(
            `Conferma Richiesta di Accredito per l'evento "${route.params.eventName}"`
        )

    const [standardConfirmEmailText, setStandardConfirmEmailText] =
        useState<string>(`Buongiorno, con la presente La informiamo che il Suo accredito per l'evento "${
            route.params.eventName
        }", in programma nei giorni ${new Date(
            route.params.eventStartTime
        ).toLocaleDateString?.()} - ${new Date(
            route.params.eventEndTime
        ).toLocaleDateString?.()}, all'Autodromo Nazionale Monza, è stato accettato. 
La invitiamo a leggere con attenzione il testo di questa e-mail. 
L'accesso in circuito potrà avvenire solo se si è provvisti di Green Pass e solo dal GATE A (Porta Vedano - Via Vedano, 5 Monza) dal (DATA) al (DATA) con parcheggio nel P Media (P5). 
Qualora intendesse rinunciare all'accredito, La preghiamo di comunicarcelo tempestivamente rispondendo a questa e-mail. I media accreditati riceveranno un bracciale all'ingresso che consentirà l'accesso 
all'Autodromo.
Successivamente dovranno ritirare il pass personale e l'eventuale pettorina per accedere in pista direttamente in Sala Stampa. 
Non sarà possibile ritirare il pass prima di (DATA E ORA APERTURA SALA STAMPA). All'arrivo in Sala Stampa sarà necessario per tutti effettuare una procedura di registrazione e assegnazione del posto.
Ricordiamo che è obbligatorio indossare la mascherina e seguire le norme di prevenzione indicate dalla cartellonistica presente a tutti gli accessi. La pettorina verrà rilasciata agli aventi diritto previa
cauzione di €20 in contanti. La cauzione non potrà essere accettata con carta di credito o documento di identità. La stessa procedura di cauzione sarà necessaria per utilizzare gli armadietti presenti in
Sala Stampa. Di seguito gli orari della Sala Stampa per l'evento: GIORNI E ORARI APERTURA SALA STAMPA La Sala Stampa non sarà accessibile prima dell'orario di apertura. 
Vi aspettiamo in circuito`)

    useRoleVerifier()

    return (
        <>
            <Content>
                <Overlay
                    visible={Boolean(dialog)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    setDialog(undefined)
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {dialog}
                        </Text>
                    </Div>
                </Overlay>

                <Text fontSize={48} mt={36} fontWeight="bold">
                    Chiusura Accrediti
                </Text>

                <Div mt={12}>
                    <Text fontSize={24} fontWeight="bold" mt={12}>
                        Email di CONFERMA
                    </Text>

                    <Text fontSize={18} mt={12}>
                        Oggetto
                    </Text>
                    <Input
                        pr={24}
                        pl={24}
                        numberOfLines={1}
                        mt={12}
                        fontSize={12}
                        defaultValue={standardConfirmEmailObject}
                        onChangeText={(e) => {
                            setStandardConfirmEmailObject(e)
                        }}
                    />

                    <Text fontSize={18} mt={12}>
                        Corpo
                    </Text>
                    <Input
                        pr={24}
                        pl={24}
                        multiline
                        numberOfLines={10}
                        mt={12}
                        fontSize={12}
                        h={200}
                        defaultValue={standardConfirmEmailText}
                        onChangeText={(e) => {
                            setStandardConfirmEmailText(e)
                        }}
                    />
                </Div>

                <Button
                    alignSelf="center"
                    mt={12}
                    mb={12}
                    onPress={() => {
                        if (tokenStorage && route.params.userIds) {
                            sendEmail(
                                tokenStorage,
                                standardConfirmEmailText,
                                standardConfirmEmailObject,
                                route.params.userIds
                            )
                            setDialog("Email inviate con successo")
                            navigation.navigate("BackOfficeAccreditationTable")
                        }
                    }}
                >
                    Chiudi Accredito ed Invia Email
                </Button>

                <HomeButton
                    mb={24}
                    alignSelf="center"
                    navigateHome={() => {
                        navigation.navigate("BackOfficeHome")
                    }}
                />
            </Content>
        </>
    )
}

export default BackOfficeClosingAccreditationConfirmed
