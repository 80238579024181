import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { Button, Div, Icon, ScrollDiv, Text } from "react-native-magnus"
import { getEvents } from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Col from "../../../components/Col"
import Content from "../../../components/Content"
import EventToShow from "../../../components/Event"
import Notifications from "../../../components/Notifications"
import Row from "../../../components/Row"
import {
    isLoaderVisibleAtom,
    tokenAtomStorage,
    userFirstNameAtomStorage,
    userIdAtomStorage,
    userRoleAtomStorage
} from "../../../context/atom"
import { t } from "../../../strings"
import { Event, UserRole } from "../../../types/prisma"

export type HomeProps = undefined

type HomeScreenRouteProp = NativeStackScreenProps<RootStackParamList, "Home">

const Home = ({ navigation }: HomeScreenRouteProp) => {
    const [tokenStorage, setTokenStorage] = useAtom(tokenAtomStorage)
    const [userRoleStorage, setUserRoleStorage] = useAtom(userRoleAtomStorage)
    const [userFirstNameStorage, setUserFirstNameStorage] = useAtom(
        userFirstNameAtomStorage
    )
    const [userIdStorage, setUserIdStorage] = useAtom(userIdAtomStorage)

    const [monzaEvents, setMonzaEvents] = useState<Event[]>()

    const dimension = useWindowDimensions()

    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    useEffect(() => {
        if (!tokenStorage) {
            return navigation.navigate("Login")
        }

        setIsLoaderVisible(true)

        getEvents(tokenStorage)
            .then((res) => {
                setMonzaEvents(res.data)
            })
            .finally(() => {
                setIsLoaderVisible(false)
            })
    }, [tokenStorage])

    return (
        <ScrollDiv>
            <Content>
                <Text fontWeight="bold" mt={64} fontSize={56}>
                    Home
                </Text>

                {userRoleStorage === UserRole.ADMIN && (
                    <Button
                        mt={24}
                        onPress={() => {
                            navigation.navigate("BackOfficeHome")
                        }}
                    >
                        {t("go_to_admin_panel")}
                    </Button>
                )}

                <Div row={dimension.width < 530 ? false : true}>
                    <Button
                        w={dimension.width < 530 ? "100%" : undefined}
                        mt={24}
                        mr={12}
                        onPress={() => {
                            navigation.navigate("UserSettings")
                        }}
                    >
                        <Text color="white" fontSize={16} mr={8}>
                            {t("user_profile")}
                        </Text>
                        <Icon
                            name="address-card"
                            color="white"
                            fontFamily="FontAwesome5"
                            fontSize="16px"
                        />
                    </Button>

                    <Button
                        w={dimension.width < 530 ? "100%" : undefined}
                        mt={24}
                        mr={12}
                        onPress={() => {
                            navigation.navigate("BackOfficeAccreditationTable")
                        }}
                    >
                        <Text color="white" fontSize={16} mr={8}>
                            {t("accreditations_history")}
                        </Text>
                        <Icon
                            name="book"
                            color="white"
                            fontFamily="FontAwesome5"
                            fontSize="16px"
                        />
                    </Button>

                    <Button
                        w={dimension.width < 530 ? "100%" : undefined}
                        mt={24}
                        mr={12}
                        alignSelf={
                            dimension.width < 530 ? "flex-start" : "flex-end"
                        }
                        onPress={() => {
                            setTokenStorage(undefined)
                            setUserRoleStorage(undefined)
                            setUserFirstNameStorage(undefined)
                            setUserIdStorage(undefined)

                            navigation.navigate("Login")
                        }}
                    >
                        <Text color="white" fontSize={16} mr={8}>
                            {t("logout")}
                        </Text>
                        <Icon
                            name="door-open"
                            color="white"
                            fontFamily="FontAwesome5"
                            fontSize="16px"
                        />
                    </Button>

                    <Notifications buttonStyle={{ height: 45.6 }} />
                </Div>

                <Div mt={32}>
                    <Row rowStyles={{ marginLeft: -16, marginRight: -16 }}>
                        {monzaEvents?.map((monzaEvent) => {
                            return (
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                    key={monzaEvent.id}
                                    colStyles={{ padding: 16 }}
                                >
                                    <EventToShow
                                        event={monzaEvent}
                                        navigateToEventScreen={(_) =>
                                            navigation.navigate("MonzaEvent", {
                                                eventId: monzaEvent.id!
                                            })
                                        }
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </Div>
            </Content>
        </ScrollDiv>
    )
}

export default Home
