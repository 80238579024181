export interface User {
    id?: number
    email: string
    password: string
    firstName: string
    lastName: string
    birthDate: Date
    fiscalCode: string
    role: UserRole
    birthPlace: string
    postalCode: string
    city: string
    country: string
    address: string
    phoneNumber: string
    expiringIdentityDocument?: Date
    expiringDateDocCauseOfDeath?: Date
    expiringDateDocInjuries?: Date
    expiringDateDocRc?: Date
    publishedDocUrls?: string[]
    isFreelancer?: boolean
    agencyName?: string
    agencyEmail?: string
    orderOfJournalistsCardNumber?: string
    Accreditation?: Accreditation[]
    hasBeenValidated: boolean
    hasCauseOfDeathDocUpdated: boolean
    hasIdentityDocumentFrontDocUpdated: boolean
    hasIdentityDocumentBackDocUpdated: boolean
    hasInjuriesDocUpdated: boolean
    hasRcDocUpdated: boolean
}

export interface Event {
    id?: number
    name: string
    description: string
    notes?: string
    allowedAccreditationUserRoles?: UserRole[]
    externalUrls?: string[]
    createdAt?: Date
    updatedAt?: Date
    startTime: Date
    endTime: Date
    accreditationStartTime: Date
    accreditationEndTime: Date
    Accreditation?: Accreditation[]
    accreditationsCount?: number
}

export interface Accreditation {
    id?: number
    userId: number
    user: User
    eventId: number
    event: Event
    notes?: string
    status: AccreditationStatus
    hasRequestedBib?: boolean
    createdAt: Date
    updatedAt: Date
    isReadyForConfirmationEmail: boolean
}

export enum UserRole {
    ADMIN = "ADMIN",
    MEDIA_JOURNALIST = "MEDIA_JOURNALIST",
    MEDIA_PHOTOGRAPHER = "MEDIA_PHOTOGRAPHER",
    MEDIA_VIDEO_OPERATOR = "MEDIA_VIDEO_OPERATOR",
    MEDIA_PR = "MEDIA_PR",
    HANDICAPPED = "HANDICAPPED"
}

export enum AccreditationStatus {
    PENDING = "PENDING",
    NEEDS_USER_UPDATE = "NEEDS_USER_UPDATE",
    APPROVED = "APPROVED",
    CONFIRMED = "CONFIRMED",
    DENIED = "DENIED"
}
