import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useEffect } from "react"
import { Image } from "react-native"
import { Div } from "react-native-magnus"
import { DEBUG, RootStackParamList } from "../../../App"
import { tokenAtomStorage, userRoleAtomStorage } from "../../../context/atom"
import { UserRole } from "../../../types/prisma/index"

export type SplashProps = undefined

type SplashScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "Splash"
>

const Splash = ({ navigation }: SplashScreenRouteProp) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)
    const [userRoleStorage] = useAtom(userRoleAtomStorage)

    useEffect(() => {
        setTimeout(
            () => {
                if (!tokenStorage) {
                    navigation.navigate("Login")
                    return
                }

                if (userRoleStorage === UserRole.ADMIN) {
                    navigation.navigate("BackOfficeHome")
                    return
                }

                navigation.navigate("Home")
            },
            DEBUG ? 0 : 3000
        )
    }, [])

    return (
        <Div
            w="100%"
            h="100%"
            bg="white"
            alignItems="center"
            justifyContent="center"
        >
            <Image
                style={{ width: 200, height: 200 }}
                source={require("../../../assets/icon.png")}
            />
        </Div>
    )
}

export default Splash
