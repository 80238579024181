import { Picker } from "@react-native-picker/picker"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { ScrollView } from "react-native-gesture-handler"
import {
    Button,
    Div,
    Icon,
    Image,
    Input,
    Overlay,
    ScrollDiv,
    Text
} from "react-native-magnus"
import {
    createEvent,
    editEvent,
    getEvent,
    getEventImage,
    uploadEventFiles
} from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import DateTimePicker from "../../../components/DateTimePicker"
import HomeButton from "../../../components/HomeButton"
import { isLoaderVisibleAtom, tokenAtomStorage } from "../../../context/atom"
import useRoleVerifier from "../../../hooks/useRoleVerifier"
import { Event, UserRole } from "../../../types/prisma/index"
import { pickFile } from "../../UserSettings"

const defaultImage = require("../../../assets/icon.png")

interface IProps {
    eventId?: number
}

export type BackOfficeEventManagementTableProps = IProps

type BackOfficeEventManagementTableScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "BackOfficeEventManagement"
>

const BackOfficeEventManagement = ({
    navigation,
    route
}: BackOfficeEventManagementTableScreenRouteProp) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)

    const [newEvent, setNewEvent] = useState<Partial<Event>>({
        allowedAccreditationUserRoles: []
    })
    const [eventImage, setEventImage] = useState<string>()

    const [externalUrlInput, setExternalUrlInput] = useState<string>()
    const [filePercentageOperation, setFilePercentageOperation] =
        useState<number>()

    const [dialog, setDialog] = useState<string>()

    const dimension = useWindowDimensions()

    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    useEffect(() => {
        if (route.params.eventId && tokenStorage) {
            setIsLoaderVisible(true)

            getEvent(route.params.eventId, tokenStorage)
                .then((res) => {
                    setNewEvent(res.data)
                })
                .finally(async () => {
                    await new Promise((res) => setTimeout(res, 1000))
                    setIsLoaderVisible(false)
                })

            getEventImage(route.params.eventId, "EVENT_IMAGE").then((res) => {
                if (res) {
                    setEventImage(res)
                    return
                }
                setDialog("Errore caricamento immagine anteprima")
            })
        }
    }, [route.params.eventId])

    useEffect(() => {
        setExternalUrlInput(newEvent.externalUrls?.join?.(","))
    }, [newEvent?.externalUrls])

    useRoleVerifier()

    return (
        <ScrollDiv>
            <Content>
                <Overlay
                    visible={Boolean(dialog)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    if (
                                        dialog ===
                                            "Evento Creato con Successo" ||
                                        dialog ===
                                            "Evento Modificato con Successo"
                                    ) {
                                        setDialog(undefined)
                                        navigation.replace(
                                            "BackOfficeEventTable"
                                        )
                                    } else {
                                        setDialog(undefined)
                                    }
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {dialog}
                        </Text>
                    </Div>
                </Overlay>

                <Text fontSize={52} fontWeight="bold" my={24}>
                    {route.params.eventId
                        ? "Modifica Evento"
                        : "Crea Nuovo Evento"}
                </Text>

                <Div>
                    <Text fontSize={24} mb={6}>
                        Nome
                    </Text>
                    <Input
                        p={10}
                        mb={18}
                        mr={12}
                        w={"100%"}
                        placeholder="Inserisci Nome"
                        value={newEvent?.name}
                        focusBorderColor="blue700"
                        onChangeText={(e: string) => {
                            setNewEvent({
                                ...newEvent,
                                name: e
                            })
                        }}
                    />

                    <Text fontSize={24} mb={6}>
                        Descrizione
                    </Text>
                    <Input
                        p={10}
                        mb={18}
                        mr={12}
                        w={"100%"}
                        placeholder="Inserisci Descrizione"
                        value={newEvent?.description}
                        focusBorderColor="blue700"
                        onChangeText={(e: string) => {
                            setNewEvent({
                                ...newEvent,
                                description: e
                            })
                        }}
                    />

                    <Text fontSize={24} mb={6}>
                        Note
                    </Text>
                    <Input
                        p={10}
                        mb={18}
                        mr={12}
                        w={"100%"}
                        placeholder="Inserisci Note"
                        value={newEvent?.notes}
                        focusBorderColor="blue700"
                        onChangeText={(e: string) => {
                            setNewEvent({
                                ...newEvent,
                                notes: e
                            })
                        }}
                    />

                    <Text fontSize={24} mb={6}>
                        Ruoli per Accredito Consentiti
                    </Text>
                    <ScrollView>
                        <Div row mb={12}>
                            {newEvent &&
                                newEvent.allowedAccreditationUserRoles?.map(
                                    (role, index) => {
                                        return (
                                            <Text
                                                onPress={() => {
                                                    let newAllowedAccreditationUserRoles =
                                                        newEvent.allowedAccreditationUserRoles?.filter(
                                                            (r) => {
                                                                return r != role
                                                            }
                                                        )
                                                    setNewEvent({
                                                        ...newEvent,
                                                        allowedAccreditationUserRoles:
                                                            newAllowedAccreditationUserRoles
                                                    })
                                                }}
                                                key={index}
                                                px={6}
                                                color="white"
                                                fontSize={18}
                                                textAlignVertical="center"
                                                style={{
                                                    borderRadius: 5,
                                                    alignItems: "center",
                                                    marginRight: 8
                                                }}
                                                bg="#dd5a5a"
                                            >
                                                {role === UserRole.MEDIA_PR
                                                    ? "PR/Addetto Stampa"
                                                    : role ===
                                                      UserRole.MEDIA_JOURNALIST
                                                    ? "Giornalista"
                                                    : role ===
                                                      UserRole.MEDIA_PHOTOGRAPHER
                                                    ? "Fotografo"
                                                    : role ===
                                                      UserRole.MEDIA_VIDEO_OPERATOR
                                                    ? "Video Operatore"
                                                    : null}
                                                <Icon
                                                    alignSelf="center"
                                                    name="times"
                                                    fontFamily="FontAwesome5"
                                                    fontSize={12}
                                                    color="white"
                                                    h={20}
                                                    w={20}
                                                    rounded="md"
                                                />
                                            </Text>
                                        )
                                    }
                                )}
                        </Div>
                    </ScrollView>
                    <Picker
                        // @ts-ignore
                        style={{
                            height: "46px",
                            marginBottom: "18px",
                            borderRadius: "6px",
                            border: "1px solid rgb(203, 213, 224)",
                            fontSize: 18
                        }}
                        selectedValue={
                            newEvent.allowedAccreditationUserRoles &&
                            newEvent.allowedAccreditationUserRoles[
                                newEvent.allowedAccreditationUserRoles?.length -
                                    1
                            ]
                        }
                        onValueChange={(itemValue, itemIndex) => {
                            let newAllowedAccreditationUserRoles =
                                newEvent.allowedAccreditationUserRoles || []
                            if (newAllowedAccreditationUserRoles.length != 0) {
                                let testArray =
                                    newAllowedAccreditationUserRoles.filter(
                                        (e) => {
                                            return e === itemValue
                                        }
                                    )
                                if (testArray.length === 0) {
                                    newAllowedAccreditationUserRoles.push(
                                        itemValue
                                    )
                                }
                            } else {
                                newAllowedAccreditationUserRoles.push(itemValue)
                            }

                            setNewEvent({
                                ...newEvent,
                                allowedAccreditationUserRoles:
                                    newAllowedAccreditationUserRoles
                            })
                        }}
                    >
                        <Picker.Item
                            label="Giornalista"
                            value={UserRole.MEDIA_JOURNALIST}
                        />
                        <Picker.Item
                            label="Fotografo"
                            value={UserRole.MEDIA_PHOTOGRAPHER}
                        />
                        <Picker.Item
                            label="Video Operatore"
                            value={UserRole.MEDIA_VIDEO_OPERATOR}
                        />
                        <Picker.Item
                            label="PR/Addetto Stampa"
                            value={UserRole.MEDIA_PR}
                        />
                    </Picker>

                    <Text fontSize={24} mb={6}>
                        Data di Inizio Evento:{" "}
                    </Text>
                    {newEvent?.startTime && (
                        <Text fontSize={24} mb={6}>
                            {new Date(
                                newEvent?.startTime
                            ).toLocaleDateString?.()}
                        </Text>
                    )}
                    <DateTimePicker
                        style={{ textAlign: "center", marginBottom: "18px" }}
                        onChange={(e) => {
                            const actualE = { ...e }
                            setNewEvent((_ne) => ({
                                ..._ne,
                                startTime: new Date(actualE.target.value)
                            }))
                        }}
                    />

                    <Text fontSize={24} mb={6}>
                        Data di Fine Evento:{" "}
                    </Text>
                    {newEvent?.endTime && (
                        <Text fontSize={24} mb={6}>
                            {new Date(newEvent?.endTime).toLocaleDateString?.()}
                        </Text>
                    )}
                    <DateTimePicker
                        style={{ textAlign: "center", marginBottom: "18px" }}
                        onChange={(e) => {
                            const actualE = { ...e }
                            setNewEvent((_ne) => ({
                                ..._ne,
                                endTime: new Date(actualE.target.value)
                            }))
                        }}
                    />

                    <Text fontSize={24} mb={6}>
                        Data di Inizio Accredito:{" "}
                    </Text>
                    {newEvent?.accreditationStartTime && (
                        <Text fontSize={24} mb={6}>
                            {new Date(
                                newEvent?.accreditationStartTime
                            ).toLocaleDateString?.()}
                        </Text>
                    )}
                    <DateTimePicker
                        style={{ textAlign: "center", marginBottom: "18px" }}
                        onChange={(e) => {
                            const actualE = { ...e }
                            setNewEvent((_ne) => ({
                                ..._ne,
                                accreditationStartTime: new Date(
                                    actualE.target.value
                                )
                            }))
                        }}
                    />

                    <Text fontSize={24} mb={6}>
                        Data di Fine Accredito:{" "}
                    </Text>
                    {newEvent?.accreditationEndTime && (
                        <Text fontSize={24} mb={6}>
                            {new Date(
                                newEvent?.accreditationEndTime
                            ).toLocaleDateString?.()}
                        </Text>
                    )}
                    <DateTimePicker
                        style={{ textAlign: "center", marginBottom: "18px" }}
                        onChange={(e) => {
                            const actualE = { ...e }
                            setNewEvent((_ne) => ({
                                ..._ne,
                                accreditationEndTime: new Date(
                                    actualE.target.value
                                )
                            }))
                        }}
                    />

                    <Text fontSize={24} mb={6}>
                        Link Esterni (separati da virgola)
                    </Text>
                    <Input
                        p={10}
                        mb={18}
                        mr={12}
                        w={"100%"}
                        placeholder="Inserisci un URL esterno"
                        value={externalUrlInput}
                        focusBorderColor="blue700"
                        onChangeText={(e: string) => {
                            setExternalUrlInput(e)
                        }}
                    />

                    {dimension.width > 530 ? (
                        <Div row>
                            <Div style={{ marginRight: 42 }}>
                                <Text fontSize={24} mb={24}>
                                    Anteprima Copertina Evento
                                </Text>
                                <Button
                                    mb={18}
                                    onPress={() => {
                                        pickFile("image/")
                                            .then((b64) => {
                                                setEventImage(b64)
                                            })
                                            .catch((err) => {
                                                setDialog(
                                                    err?.message.toString?.() ||
                                                        "Errore durante il caricamente"
                                                )
                                            })
                                    }}
                                >
                                    Carica
                                </Button>
                            </Div>
                            <Image
                                mb={6}
                                w={150}
                                h={150}
                                source={{ uri: eventImage || defaultImage }}
                            />
                        </Div>
                    ) : (
                        <>
                            <Div>
                                <Text fontSize={24} mb={24}>
                                    Anteprima Copertina Evento
                                </Text>
                                <Image
                                    alignSelf="center"
                                    mb={6}
                                    w={150}
                                    h={150}
                                    source={{ uri: eventImage || defaultImage }}
                                />
                                <Button
                                    alignSelf="center"
                                    w={"60%"}
                                    mb={18}
                                    onPress={() => {
                                        pickFile("image/")
                                            .then((b64) => {
                                                setEventImage(b64)
                                            })
                                            .catch((err) => {
                                                setDialog(
                                                    err?.message.toString?.() ||
                                                        "Errore durante il caricamente"
                                                )
                                            })
                                    }}
                                >
                                    Carica
                                </Button>
                            </Div>
                        </>
                    )}

                    <Div row justifyContent="center" mt={24}>
                        <Button
                            w={dimension.width > 530 ? 123 : undefined}
                            alignSelf={"center"}
                            fontSize="2xl"
                            fontWeight="bold"
                            mb={24}
                            style={{ marginLeft: 6, marginRight: 6 }}
                            onPress={() => {
                                newEvent.externalUrls = !externalUrlInput
                                    ? []
                                    : externalUrlInput.includes(",")
                                    ? externalUrlInput
                                          .split(",")
                                          .map((u) => u.trim())
                                    : [externalUrlInput]
                                newEvent.updatedAt = new Date()
                                if (route.params.eventId) {
                                    if (
                                        newEvent.name &&
                                        newEvent.description &&
                                        newEvent.startTime &&
                                        newEvent.endTime &&
                                        newEvent.createdAt &&
                                        newEvent.updatedAt &&
                                        newEvent.accreditationStartTime &&
                                        newEvent.accreditationEndTime &&
                                        newEvent.notes &&
                                        newEvent.allowedAccreditationUserRoles &&
                                        newEvent.externalUrls &&
                                        tokenStorage
                                    ) {
                                        editEvent(
                                            route.params.eventId,
                                            tokenStorage,
                                            newEvent
                                        )
                                            .then((res) => {
                                                if (res.success) {
                                                    setDialog(
                                                        "Evento Modificato con Successo"
                                                    )
                                                } else {
                                                    setDialog(
                                                        "Errore: " + res.error
                                                    )
                                                }
                                            })
                                            .catch((err) => {
                                                setDialog("Errore: " + err)
                                            })
                                    }
                                } else {
                                    if (
                                        newEvent.name &&
                                        newEvent.description &&
                                        newEvent.startTime &&
                                        newEvent.endTime &&
                                        newEvent.accreditationStartTime &&
                                        newEvent.accreditationEndTime &&
                                        newEvent.notes &&
                                        newEvent.allowedAccreditationUserRoles &&
                                        newEvent.externalUrls &&
                                        tokenStorage
                                    ) {
                                        const createdEvent: Event = {
                                            name: newEvent.name,
                                            description: newEvent.description,
                                            startTime: newEvent.startTime,
                                            endTime: newEvent.endTime,
                                            accreditationStartTime:
                                                newEvent.accreditationStartTime,
                                            accreditationEndTime:
                                                newEvent.accreditationEndTime,
                                            notes: newEvent.notes,
                                            allowedAccreditationUserRoles:
                                                newEvent.allowedAccreditationUserRoles,
                                            externalUrls: newEvent.externalUrls
                                        }

                                        if (createdEvent) {
                                            createEvent(
                                                createdEvent,
                                                tokenStorage
                                            )
                                                .then(async (res) => {
                                                    if (res.success) {
                                                        if (eventImage) {
                                                            await uploadEventFiles(
                                                                res.data.id,
                                                                tokenStorage,
                                                                eventImage,
                                                                (
                                                                    progressEvent: any
                                                                ) => {
                                                                    const percentage =
                                                                        Math.round(
                                                                            (progressEvent.loaded *
                                                                                100) /
                                                                                progressEvent.total
                                                                        )
                                                                    setFilePercentageOperation(
                                                                        percentage ==
                                                                            100
                                                                            ? undefined
                                                                            : percentage
                                                                    )
                                                                }
                                                            )
                                                        }
                                                        setDialog(
                                                            "Evento Creato con Successo"
                                                        )
                                                    } else {
                                                        setDialog(
                                                            "Errore: " +
                                                                res.error
                                                        )
                                                    }
                                                })
                                                .catch((err) => {
                                                    setDialog("Errore: " + err)
                                                })
                                        }
                                    } else {
                                        setDialog(
                                            "E' necessario compilare tutti i campi prima di procedere con la conferma"
                                        )
                                    }
                                }
                            }}
                        >
                            {filePercentageOperation
                                ? `Caricamento... ${filePercentageOperation}%`
                                : "Conferma"}
                        </Button>

                        <HomeButton
                            ml={12}
                            mb={24}
                            navigateHome={() => {
                                navigation.navigate("BackOfficeHome")
                            }}
                        />
                    </Div>
                </Div>
            </Content>
        </ScrollDiv>
    )
}

export default BackOfficeEventManagement
