import { useNavigation } from "@react-navigation/native"
import React, { useEffect, useMemo, useState } from "react"
import { useWindowDimensions } from "react-native"
import { Text } from "react-native-magnus"

export interface DataTableProps<T> {
    keys: { [key in keyof Partial<T>]: () => JSX.Element | string }
    cells: { [key in keyof Partial<T>]: (e: T) => JSX.Element | string }
    data: Array<T>
}

const DataTable = ({ keys, cells, data }: DataTableProps<any>) => {
    const tableId = useMemo(() => new Date().getTime().toString(), [])

    const keyStrings = useMemo(() => Object.keys(keys), [keys])

    const { height: windowHeight } = useWindowDimensions()

    const [height, setHeight] = useState<number>(100)

    const navigation = useNavigation()

    useEffect(() => {
        const handleTableHeight = () => {
            const el = document.getElementById(`table_${tableId}`)
            if (!el) {
                return
            }

            const { y } = el.getBoundingClientRect()
            setHeight(windowHeight - y - 2 - 68)
        }

        navigation.addListener("state", handleTableHeight)
        window.addEventListener("resize", handleTableHeight)

        return () => {
            navigation.removeListener("state", handleTableHeight)
            window.removeEventListener("resize", handleTableHeight)
        }
    }, [])

    return (
        <div
            id={`table_${tableId}`}
            style={{
                overflow: "auto",
                width: "100%",
                height,
                marginTop: 12,
                borderRadius: 8
            }}
        >
            <table
                style={{
                    borderCollapse: "collapse",
                    position: "relative"
                }}
            >
                <tr
                    style={{
                        background: "#11aaff",
                        marginBottom: 8,
                        position: "sticky",
                        top: 0,
                        zIndex: Number.MAX_SAFE_INTEGER
                    }}
                >
                    {keyStrings.map((_keyString, index) => {
                        const renderCell = keys[_keyString]
                        if (!renderCell) {
                            // No cell handler
                            return
                        }

                        let cell = renderCell()
                        if (typeof cell === "string") {
                            cell = (
                                <Text
                                    fontWeight="bold"
                                    fontSize={14}
                                    color="white"
                                    style={{
                                        flexWrap: "nowrap"
                                    }}
                                >
                                    {cell.trim()}
                                </Text>
                            )
                        }

                        return (
                            <td key={`${tableId}-header-${_keyString}`}>
                                <div
                                    style={{
                                        paddingInline: 16,
                                        paddingTop: 4,
                                        paddingBottom: 4
                                    }}
                                >
                                    {cell}
                                </div>
                            </td>
                        )
                    })}
                </tr>

                {data.map((_item: any, _rowIndex: number) => (
                    <tr
                        style={{
                            marginTop: 20,
                            paddingBottom: 20,
                            background: _rowIndex % 2 === 0 ? "#eee" : "white"
                        }}
                    >
                        {keyStrings.map((_keyString, index) => {
                            const renderCell = cells[_keyString]
                            if (!renderCell) {
                                // Not cell handler
                                return
                            }

                            let cell = renderCell(_item)
                            if (typeof cell === "string") {
                                cell = (
                                    <Text
                                        key={`${tableId}-content-row${_rowIndex}-${_keyString}`}
                                        fontSize={12}
                                        color="black"
                                    >
                                        {cell.trim()}
                                    </Text>
                                )
                            }

                            return (
                                <td
                                    key={`${tableId}-content-row${_rowIndex}-${_keyString}`}
                                    style={{
                                        paddingInline: 16,
                                        paddingTop: 4,
                                        paddingBottom: 4
                                    }}
                                >
                                    {cell}
                                </td>
                            )
                        })}
                    </tr>
                ))}
            </table>
        </div>
    )
}

export default DataTable
