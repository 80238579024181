import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { t } from "i18n-js"
import { useAtom } from "jotai"
import React, { useEffect, useMemo, useState } from "react"
import {
    Button,
    Div,
    Icon,
    Modal,
    Overlay,
    ScrollDiv,
    Text
} from "react-native-magnus"
import {
    deleteEvent,
    getEvent,
    getEvents,
    ITEMS_PER_PAGE
} from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import DataTable, { DataTableProps } from "../../../components/DataTable"
import HomeButton from "../../../components/HomeButton"
import SearchBar from "../../../components/SearchBar"
import { isLoaderVisibleAtom, tokenAtomStorage } from "../../../context/atom"
import useRoleVerifier from "../../../hooks/useRoleVerifier"
import { Event, UserRole } from "../../../types/prisma/index"

export type BackOfficeEventTableProps = undefined

type BackOfficeEventTableScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "BackOfficeEventTable"
>

const BackOfficeEventTable = ({
    navigation,
    route
}: BackOfficeEventTableScreenRouteProp) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)

    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    const [isLoadMoreButtonVisible, setIsLoadMoreButtonVisible] =
        useState<boolean>(true)

    const [eventsList, setEventsList] = useState<Event[]>()
    const [openAddDialog, setOpenAddDialog] = useState<boolean>()
    const [openEditDialog, setOpenEditDialog] = useState<Event>()

    const [dialog, setDialog] = useState<string>()

    const loadEvents = () => {
        if (tokenStorage) {
            setIsLoaderVisible(true)

            getEvents(tokenStorage, { first: ITEMS_PER_PAGE })
                .then((res) => {
                    if (res.success) {
                        setEventsList(res.data)
                        if (res.data.length < ITEMS_PER_PAGE) {
                            setIsLoadMoreButtonVisible(false)
                        }
                    }
                })
                .catch((e) => {
                    setDialog("Errore nel caricamento dei dati: " + e)
                })
                .finally(() => {
                    setIsLoaderVisible(false)
                })
        }
    }

    useEffect(() => {
        loadEvents()
    }, [route.path])

    const dataTable = useMemo(() => {
        const props: DataTableProps<
            Event & { modifica: undefined; elimina: undefined }
        > = {
            keys: {
                id: () => "Id",
                name: () => "Nome Evento",
                startTime: () => "Inizio Evento",
                endTime: () => "Fine Evento",
                description: () => "Descrizione",
                createdAt: () => "Data Creazione",
                updatedAt: () => "Data Ultima Modifica",
                accreditationStartTime: () => "Inizio Accrediti",
                accreditationEndTime: () => "Fine Accrediti",
                notes: () => "Note",
                accreditationsCount: () => "# Richieste Accredito",
                allowedAccreditationUserRoles: () => "Ruoli Accredito",
                externalUrls: () => "Collegamenti Esterni",
                modifica: () => "Modifica",
                elimina: () => "Elimina"
            },
            cells: {
                id: (e) => e.id?.toString?.() || "No ID",
                name: (e) => e.name || "No Name",
                startTime: (e) =>
                    new Date(e.startTime).toLocaleDateString?.() ||
                    "No Start Time",
                endTime: (e) =>
                    new Date(e.endTime).toLocaleDateString?.() || "No End Time",
                description: (e) => e.description || "No Description",
                createdAt: (e) =>
                    (e.createdAt &&
                        new Date(e.createdAt).toLocaleDateString?.()) ||
                    "No data for Created At",
                updatedAt: (e) =>
                    (e.updatedAt &&
                        new Date(e.updatedAt).toLocaleDateString?.()) ||
                    "No data for Updated At",
                accreditationStartTime: (e) =>
                    new Date(
                        e.accreditationStartTime ||
                            "No Accreditation Start Time"
                    ).toLocaleDateString?.(),
                accreditationEndTime: (e) =>
                    new Date(
                        e.accreditationEndTime || "No Accreditation End Time"
                    ).toLocaleDateString?.(),
                notes: (e) => e.notes || "No Notes",
                accreditationsCount: (e) =>
                    e.accreditationsCount?.toString() || "0",
                allowedAccreditationUserRoles: (e) =>
                    e.allowedAccreditationUserRoles
                        ?.map((role) => {
                            return role === UserRole.ADMIN
                                ? "Admin"
                                : role === UserRole.MEDIA_JOURNALIST
                                ? "Giornalista"
                                : role === UserRole.MEDIA_PHOTOGRAPHER
                                ? "Fotografo"
                                : role === UserRole.MEDIA_VIDEO_OPERATOR
                                ? "Video Operatore"
                                : role === UserRole.HANDICAPPED
                                ? "Disabile"
                                : null
                        })
                        ?.join("\n") || "No Roles",
                externalUrls: (e) =>
                    e.externalUrls && e.externalUrls.length > 0 ? (
                        <>
                            {e.externalUrls.map((url) => (
                                <Text
                                    w={200}
                                    textDecorLine={"underline"}
                                    onPress={() => {
                                        window.open(url, "_blank")
                                    }}
                                >
                                    {url}
                                </Text>
                            ))}
                        </>
                    ) : (
                        "Nessun Link Esterno"
                    ),

                modifica: (e) => (
                    <Div pr={12}>
                        <Button
                            w={100}
                            onPress={() => {
                                getEvent(e.id!, tokenStorage!)
                                    .then((res) => {
                                        if (res.success) {
                                            navigation.navigate(
                                                "BackOfficeEventManagement",
                                                {
                                                    eventId: e.id
                                                }
                                            )
                                            return
                                        }
                                    })
                                    .catch((e) => {
                                        setDialog(
                                            "Errore nel caricamento dell'evento: " +
                                                e
                                        )
                                    })
                            }}
                        >
                            Modifica
                        </Button>
                    </Div>
                ),
                elimina: (e) => (
                    <Div pr={12}>
                        <Button
                            w={100}
                            onPress={() => {
                                if (
                                    confirm(
                                        `Sei sicuro di voler eliminare l'evento "${e.id}"?`
                                    )
                                ) {
                                    deleteEvent(e.id!, tokenStorage!)
                                    navigation.replace("BackOfficeEventTable")
                                }
                            }}
                        >
                            Elimina
                        </Button>
                    </Div>
                )
            },
            data: (eventsList || []).map((u, index) => ({
                ...u,
                modifica: undefined,
                elimina: undefined
            }))
        }
        return <DataTable {...props} />
    }, [eventsList])

    useRoleVerifier()

    return (
        <ScrollDiv>
            <Content>
                <Overlay
                    visible={Boolean(dialog)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    setDialog(undefined)
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {dialog}
                        </Text>
                    </Div>
                </Overlay>

                <Text fontSize={52} fontWeight="bold" my={24}>
                    Gestione Eventi
                </Text>

                <Div row justifyContent="space-between">
                    <Button
                        mb={24}
                        fontWeight="bold"
                        fontSize="2xl"
                        alignSelf="flex-start"
                        style={{}}
                        onPress={() => {
                            navigation.navigate("BackOfficeEventManagement", {})
                        }}
                    >
                        Aggiungi
                    </Button>

                    <HomeButton
                        mb={24}
                        navigateHome={() => {
                            navigation.navigate("BackOfficeHome")
                        }}
                    />
                </Div>

                <SearchBar
                    style={{ marginTop: 6 }}
                    label={t("filter_by_event")}
                    searchType={"events"}
                    onChange={(q) => {
                        if (q.length === 0) {
                            loadEvents()
                        }
                    }}
                    result={(item) => (
                        <p
                            key={`data-search-suggestion-${item.id}`}
                            style={{
                                fontWeight: "bold",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                const newEventsList = eventsList?.filter(
                                    (_ac) => _ac.id === item.id
                                )
                                setEventsList(newEventsList)
                            }}
                        >
                            <Text>{item.name}</Text>
                        </p>
                    )}
                />

                {dataTable}

                {isLoadMoreButtonVisible && (
                    <Button
                        mt={12}
                        mb={12}
                        alignSelf="center"
                        onPress={() => {
                            if (tokenStorage) {
                                getEvents(tokenStorage, {
                                    first: ITEMS_PER_PAGE,
                                    lastEventId:
                                        eventsList?.[eventsList.length - 1].id
                                }).then((res) => {
                                    const newEventsList = (
                                        eventsList || []
                                    ).concat(res.data)
                                    setEventsList(newEventsList)
                                    if (res.data.length < ITEMS_PER_PAGE) {
                                        setIsLoadMoreButtonVisible(false)
                                    }
                                })
                            }
                        }}
                    >
                        Carica Altri Eventi
                    </Button>
                )}

                <Modal isVisible={openAddDialog} h="40%">
                    <Button
                        bg="gray400"
                        h={35}
                        w={35}
                        position="absolute"
                        top={50}
                        right={15}
                        rounded="circle"
                        onPress={() => {
                            setOpenAddDialog(false)
                        }}
                    >
                        <Icon color="black900" name="close" />
                    </Button>
                </Modal>

                <Modal isVisible={Boolean(openEditDialog)} h="40%">
                    <Text>{openEditDialog?.id}</Text>

                    <Button
                        bg="gray400"
                        h={35}
                        w={35}
                        position="absolute"
                        top={50}
                        right={15}
                        rounded="circle"
                        onPress={() => {
                            setOpenEditDialog(undefined)
                        }}
                    >
                        <Icon color="black900" name="close" />
                    </Button>
                </Modal>
            </Content>
        </ScrollDiv>
    )
}

export default BackOfficeEventTable
