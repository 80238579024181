import { Picker } from "@react-native-picker/picker"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import * as DocumentPicker from "expo-document-picker"
import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import {
    Button,
    Collapse,
    Div,
    Icon,
    Input,
    Overlay,
    ScrollDiv,
    Text
} from "react-native-magnus"
import {
    deleteUser,
    downloadUserFiles,
    editUser,
    getUser,
    getUserFileExistance,
    uploadUserFiles,
    UserFiles
} from "../../api/fetches"
import { RootStackParamList } from "../../App"
import Content from "../../components/Content"
import DateTimePicker from "../../components/DateTimePicker"
import HomeButton from "../../components/HomeButton"
import {
    isLoaderVisibleAtom,
    tokenAtomStorage,
    userFirstNameAtomStorage,
    userIdAtomStorage,
    userRoleAtomStorage
} from "../../context/atom"
import { t } from "../../strings"
import { User, UserRole } from "../../types/prisma/index"
import { isChromium } from "../../utils/browser"
import { EntityWork } from "../UserFrontend/SignUp"

export type UserSettingsProps = undefined

type UserSettingsScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "UserSettings"
>

export const pickFile = async (type: string) => {
    const document = await DocumentPicker.getDocumentAsync({
        type,
        copyToCacheDirectory: false,
        multiple: false
    })
    if (document.type === "success") {
        if (
            document.name.toLowerCase().endsWith(".pdf") ||
            document.name.toLowerCase().endsWith(".jpg") ||
            document.name.toLowerCase().endsWith(".jpeg")
        ) {
            const content = document.uri
            return content
        } else {
            throw new Error(t("file_not_valid"))
        }
    }
}

const UserSettings = ({ navigation }: UserSettingsScreenRouteProp) => {
    const [tokenStorage, setTokenStorage] = useAtom(tokenAtomStorage)
    const [userRoleStorage, setUserRoleStorage] = useAtom(userRoleAtomStorage)
    const [userIdStorage, setUserIdStorage] = useAtom(userIdAtomStorage)
    const [user, setUser] = useState<User>()

    const [userFirstNameStorage, setUserFirstNameStorage] = useAtom(
        userFirstNameAtomStorage
    )
    const [newUser, setNewUser] = useState<Partial<User>>({})

    const [docIdentityFront, setDocIdentityFront] = useState<string>()
    const [docIdentityFrontExist, setDocIdentityFrontExist] =
        useState<boolean>()

    const [docIdentityBack, setDocIdentityBack] = useState<string>()
    const [docIdentityBackExist, setDocIdentityBackExist] = useState<boolean>()

    const [docCauseOfDeath, setDocCauseOfDeath] = useState<string>()
    const [docCauseOfDeathExist, setDocCauseOfDeathExist] = useState<boolean>()

    const [docInjuries, setDocInjuries] = useState<string>()
    const [docInjuriesExist, setDocInjuriesExist] = useState<boolean>()

    const [docRc, setDocRc] = useState<string>()
    const [docRcExist, setDocRcExist] = useState<boolean>()

    const [publishedDocUrlsInput, setPublishedDocUrlsInput] = useState<string>()

    const [
        identityDocFrontUploadPercentage,
        setIdentityDocFrontUploadPercentage
    ] = useState<number>()
    const [
        identityDocBackUploadPercentage,
        setIdentityDocBackUploadPercentage
    ] = useState<number>()
    const [
        causeOfDeathDocUploadPercentage,
        setCauseOfDeathDocUploadPercentage
    ] = useState<number>()
    const [injuriesDocUploadPercentage, setInjuriesDocUploadPercentage] =
        useState<number>()
    const [rcDocUploadPercentage, setRcDocUploadPercentage] = useState<number>()

    const [identityDocFrontPreviewUrl, setIdentityDocFrontPreviewUrl] =
        useState<string>()
    const [identityDocBackPreviewUrl, setIdentityDocBackPreviewUrl] =
        useState<string>()
    const [causeOfDeathDocPreviewUrl, setCauseOfDeathDocPreviewUrl] =
        useState<string>()
    const [injuriesDocPreviewUrl, setInjuriesDocPreviewUrl] = useState<string>()
    const [rcDocPreviewUrl, setRcDocPreviewUrl] = useState<string>()

    const [comunicateEditResults, setComunicateEditResults] = useState<string>()

    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    const onEditSubmit = (newUser: Partial<User>) => {
        if (userIdStorage && tokenStorage) {
            setIsLoaderVisible(true)

            editUser(userIdStorage, tokenStorage, newUser)
                .then((res) => {
                    if (res.success) {
                        setComunicateEditResults(t("success_edit"))
                        if (user) {
                            setUser({ ...user, ...newUser })
                        }
                    }
                })
                .catch((err) => {
                    setComunicateEditResults(
                        "Error: " + err || t("unknown_error")
                    )
                })
                .finally(() => {
                    setIsLoaderVisible(false)
                })
        }
    }

    const onDeleteSubmit = () => {
        if (tokenStorage && userIdStorage) {
            setIsLoaderVisible(true)

            deleteUser(userIdStorage, tokenStorage)
                .then((res) => {
                    if (res.success) {
                        setTokenStorage(undefined)
                        setUserRoleStorage(undefined)
                        setUserFirstNameStorage(undefined)
                        setUserIdStorage(undefined)

                        navigation.navigate("Home")
                    }
                })
                .catch((e) => {
                    setComunicateEditResults(e)
                })
                .finally(() => {
                    setIsLoaderVisible(false)
                })
        }
    }

    useEffect(() => {
        if (tokenStorage && userIdStorage) {
            setIsLoaderVisible(true)

            getUser(userIdStorage, tokenStorage)
                .then((res) => {
                    if (res.success) {
                        setUser(res.data)

                        setNewUser({
                            ...newUser,
                            publishedDocUrls: res.data.publishedDocUrls
                        })
                    } else {
                        setComunicateEditResults(
                            res.error || t("unknown_error")
                        )
                    }
                })
                .finally(() => {
                    setIsLoaderVisible(false)
                })

            getUserFileExistance(
                userIdStorage,
                tokenStorage,
                UserFiles.IDENTITY_DOCUMENT_FRONT
            ).then((res) => {
                setDocIdentityFrontExist(res.data?.exists)
            })

            getUserFileExistance(
                userIdStorage,
                tokenStorage,
                UserFiles.IDENTITY_DOCUMENT_BACK
            ).then((res) => {
                setDocIdentityBackExist(res.data?.exists)
            })

            getUserFileExistance(
                userIdStorage,
                tokenStorage,
                UserFiles.CAUSE_OF_DEATH
            ).then((res) => {
                setDocCauseOfDeathExist(res.data?.exists)
            })

            getUserFileExistance(
                userIdStorage,
                tokenStorage,
                UserFiles.INJURIES
            ).then((res) => {
                setDocInjuriesExist(res.data?.exists)
            })

            getUserFileExistance(
                userIdStorage,
                tokenStorage,
                UserFiles.RC
            ).then((res) => {
                setDocRcExist(res.data?.exists)
            })
        }
    }, [])

    useEffect(() => {
        if (userIdStorage && tokenStorage) {
            downloadUserFiles(
                userIdStorage,
                tokenStorage,
                UserFiles.IDENTITY_DOCUMENT_FRONT
            ).then((res) => {
                const fileUrl = URL.createObjectURL(res)
                if (res) {
                    setIdentityDocFrontPreviewUrl(fileUrl)
                } else {
                    alert("Documento Identità Fronte non trovato")
                }
            })

            downloadUserFiles(
                userIdStorage,
                tokenStorage,
                UserFiles.IDENTITY_DOCUMENT_BACK
            ).then((res) => {
                const fileUrl = URL.createObjectURL(res)
                if (res) {
                    setIdentityDocBackPreviewUrl(fileUrl)
                } else {
                    alert("Documento Identità Retro non trovato")
                }
            })

            downloadUserFiles(
                userIdStorage,
                tokenStorage,
                UserFiles.CAUSE_OF_DEATH
            ).then((res) => {
                const fileUrl = URL.createObjectURL(res)
                if (res) {
                    setCauseOfDeathDocPreviewUrl(fileUrl)
                } else {
                    alert("Documento Causa Morte non trovato")
                }
            })

            downloadUserFiles(
                userIdStorage,
                tokenStorage,
                UserFiles.INJURIES
            ).then((res) => {
                const fileUrl = URL.createObjectURL(res)
                if (res) {
                    setInjuriesDocPreviewUrl(fileUrl)
                } else {
                    alert("Documento Infortuni non trovato")
                }
            })

            downloadUserFiles(userIdStorage, tokenStorage, UserFiles.RC).then(
                (res) => {
                    const fileUrl = URL.createObjectURL(res)
                    if (res) {
                        setRcDocPreviewUrl(fileUrl)
                    } else {
                        alert("Documento RC non trovato")
                    }
                }
            )
        }
    }, [])

    useEffect(() => {
        if (!tokenStorage) {
            navigation.navigate("Login")
        }
    }, [tokenStorage])

    return (
        <ScrollDiv>
            <Content style={{ paddingBottom: 32 }}>
                <Overlay
                    visible={Boolean(comunicateEditResults)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    setComunicateEditResults(undefined)
                                    if (
                                        comunicateEditResults ===
                                            t("success_edit") ||
                                        comunicateEditResults ===
                                            t("success_uploading")
                                    ) {
                                        navigation.replace("UserSettings")
                                    }
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {comunicateEditResults}
                        </Text>
                    </Div>
                </Overlay>

                <Text fontWeight="700" fontSize={32} mt={32} mb={8}>
                    {t("user_profile")}
                </Text>

                <Div w={"100%"}>
                    <Text fontSize={18} mt={32} mb={8}>
                        {t("email")}
                    </Text>
                    <Div
                        alignItems="center"
                        justifyContent="space-between"
                        row
                        w={"100%"}
                    >
                        <Input
                            w={"100%"}
                            placeholder={user?.email || t("unset")}
                            p={10}
                            focusBorderColor="blue700"
                            mr={12}
                            onChangeText={(e) => {
                                setNewUser((_u) => ({ ..._u, email: e }))
                            }}
                        />
                    </Div>
                    <Button
                        mt={12}
                        alignSelf="flex-start"
                        onPress={() => {
                            onEditSubmit(newUser)
                        }}
                    >
                        {t("edit")}
                    </Button>
                </Div>

                {userRoleStorage !== UserRole.ADMIN && (
                    <>
                        <Text fontSize={18} mb={8} mt={32}>
                            {t("nationality")}
                        </Text>

                        <Div alignItems="center" row>
                            <Input
                                w="100%"
                                placeholder={user?.country || t("unset")}
                                value={newUser?.country}
                                p={10}
                                focusBorderColor="blue700"
                                mr={12}
                                onChangeText={(e: string) => {
                                    setNewUser({
                                        ...newUser,
                                        country: e
                                    })
                                }}
                            />
                        </Div>
                        <Button
                            mt={12}
                            alignSelf="flex-start"
                            onPress={() => {
                                onEditSubmit(newUser)
                            }}
                        >
                            {t("edit")}
                        </Button>

                        <Text fontSize={18} mb={8} mt={32}>
                            {t("city")}
                        </Text>

                        <Div alignItems="center" row>
                            <Input
                                w="100%"
                                placeholder={user?.city || t("unset")}
                                value={newUser?.city}
                                p={10}
                                focusBorderColor="blue700"
                                mr={12}
                                onChangeText={(e: string) => {
                                    setNewUser({
                                        ...newUser,
                                        city: e
                                    })
                                }}
                            />
                        </Div>
                        <Button
                            mt={12}
                            alignSelf="flex-start"
                            onPress={() => {
                                onEditSubmit(newUser)
                            }}
                        >
                            {t("edit")}
                        </Button>

                        <Text fontSize={18} mb={8} mt={32}>
                            {t("address")}
                        </Text>
                        <Div alignItems="center" row>
                            <Input
                                w="100%"
                                placeholder={user?.address || t("unset")}
                                value={newUser?.address}
                                p={10}
                                focusBorderColor="blue700"
                                mr={12}
                                onChangeText={(e: string) => {
                                    setNewUser({
                                        ...newUser,
                                        address: e
                                    })
                                }}
                            />
                        </Div>
                        <Button
                            mt={12}
                            alignSelf="flex-start"
                            onPress={() => {
                                onEditSubmit(newUser)
                            }}
                        >
                            {t("edit")}
                        </Button>

                        <Text fontSize={18} mb={8} mt={32}>
                            {t("postal_code")}
                        </Text>

                        <Div alignItems="center" row>
                            <Input
                                w="100%"
                                placeholder={user?.postalCode || t("unset")}
                                value={newUser?.postalCode}
                                p={10}
                                focusBorderColor="blue700"
                                mr={12}
                                onChangeText={(e: string) => {
                                    setNewUser({
                                        ...newUser,
                                        postalCode: e
                                    })
                                }}
                            />
                        </Div>
                        <Button
                            mt={12}
                            alignSelf="flex-start"
                            onPress={() => {
                                onEditSubmit(newUser)
                            }}
                        >
                            {t("edit")}
                        </Button>

                        <Text fontSize={18} mb={6} mt={32}>
                            {t("phone")}
                        </Text>

                        <Div alignItems="center" row>
                            <Input
                                w="100%"
                                placeholder={user?.phoneNumber || t("unset")}
                                value={newUser?.phoneNumber}
                                p={10}
                                focusBorderColor="blue700"
                                mr={12}
                                onChangeText={(e: string) => {
                                    setNewUser({
                                        ...newUser,
                                        phoneNumber: e
                                    })
                                }}
                            />
                        </Div>
                        <Button
                            mt={12}
                            alignSelf="flex-start"
                            onPress={() => {
                                onEditSubmit(newUser)
                            }}
                        >
                            {t("edit")}
                        </Button>

                        <Text fontSize={18} mt={32} mb={4}>
                            {t("doc_identity_front")}{" "}
                            {docIdentityFrontExist
                                ? t("already_uploaded")
                                : t("not_uploaded")}
                        </Text>
                        <Text fontSize={12} mb={6}>
                            {t("file_format_label")}
                        </Text>

                        <Div row>
                            <Button
                                mr={12}
                                onPress={() => {
                                    pickFile("*/*")
                                        .then((b64) => {
                                            setDocIdentityFront(b64)
                                            if (userIdStorage && tokenStorage) {
                                                uploadUserFiles(
                                                    userIdStorage,
                                                    tokenStorage,
                                                    {
                                                        [UserFiles.IDENTITY_DOCUMENT_FRONT]:
                                                            b64
                                                    },
                                                    (progressEvent: any) => {
                                                        const percentage =
                                                            Math.round(
                                                                (progressEvent.loaded *
                                                                    100) /
                                                                    progressEvent.total
                                                            )
                                                        setIdentityDocFrontUploadPercentage(
                                                            percentage == 100
                                                                ? undefined
                                                                : percentage
                                                        )
                                                    }
                                                )
                                                    .then((res) => {
                                                        if (res.success) {
                                                            setComunicateEditResults(
                                                                t(
                                                                    "success_uploading"
                                                                )
                                                            )
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        setComunicateEditResults(
                                                            "Error: " + err
                                                        )
                                                    })
                                            }
                                        })
                                        .catch((err) => {
                                            setComunicateEditResults(
                                                err?.message.toString?.() ||
                                                    "Errore durante il caricamente"
                                            )
                                        })
                                }}
                            >
                                {identityDocFrontUploadPercentage
                                    ? `${t(
                                          "uploading"
                                      )} ${identityDocFrontUploadPercentage}%`
                                    : t("upload")}
                            </Button>

                            {identityDocFrontPreviewUrl && (
                                <>
                                    <details>
                                        <summary
                                            style={{
                                                backgroundColor:
                                                    "rgb(49, 130, 206)",
                                                height: 44,
                                                display: "flex",
                                                alignItems: "center",
                                                borderRadius: 5,
                                                border: 2,
                                                padding: "0px 12px 0px 12px",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <Text fontSize={15} color="white">
                                                ▶ {t("show")}
                                            </Text>
                                        </summary>
                                        {isChromium && (
                                            <iframe
                                                style={{
                                                    marginTop: 4,
                                                    borderRadius: 5
                                                }}
                                                frameBorder={0}
                                                src={identityDocFrontPreviewUrl}
                                                height={300}
                                                width={200}
                                            ></iframe>
                                        )}
                                        <Button
                                            mt={2}
                                            onPress={() => {
                                                window.open(
                                                    identityDocFrontPreviewUrl,
                                                    "_blank"
                                                )
                                            }}
                                        >
                                            {t("show_in_new_tab")}
                                        </Button>
                                    </details>
                                </>
                            )}
                        </Div>

                        <Text fontSize={18} mt={18} mb={4}>
                            {t("doc_identity_back")}{" "}
                            {docIdentityBackExist
                                ? t("already_uploaded")
                                : t("not_uploaded")}
                        </Text>
                        <Text fontSize={12} mb={6}>
                            {t("file_format_label")}
                        </Text>

                        <Div row>
                            <Button
                                mr={12}
                                onPress={() => {
                                    pickFile("*/*")
                                        .then((b64) => {
                                            setDocIdentityBack(b64)
                                            if (userIdStorage && tokenStorage) {
                                                uploadUserFiles(
                                                    userIdStorage,
                                                    tokenStorage,
                                                    {
                                                        [UserFiles.IDENTITY_DOCUMENT_BACK]:
                                                            b64
                                                    },
                                                    (progressEvent: any) => {
                                                        const percentage =
                                                            Math.round(
                                                                (progressEvent.loaded *
                                                                    100) /
                                                                    progressEvent.total
                                                            )
                                                        setIdentityDocBackUploadPercentage(
                                                            percentage == 100
                                                                ? undefined
                                                                : percentage
                                                        )
                                                    }
                                                )
                                                    .then((res) => {
                                                        if (res.success) {
                                                            setComunicateEditResults(
                                                                t(
                                                                    "success_uploading"
                                                                )
                                                            )
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        setComunicateEditResults(
                                                            "Error: " + err
                                                        )
                                                    })
                                            }
                                        })
                                        .catch((err) => {
                                            setComunicateEditResults(
                                                err?.message.toString?.() ||
                                                    "Errore durante il caricamente"
                                            )
                                        })
                                }}
                            >
                                {identityDocBackUploadPercentage
                                    ? `${t(
                                          "uploading"
                                      )} ${identityDocBackUploadPercentage}%`
                                    : t("upload")}
                            </Button>

                            {identityDocBackPreviewUrl && (
                                <>
                                    <details>
                                        <summary
                                            style={{
                                                backgroundColor:
                                                    "rgb(49, 130, 206)",
                                                height: 44,
                                                display: "flex",
                                                alignItems: "center",
                                                borderRadius: 5,
                                                border: 2,
                                                padding: "0px 12px 0px 12px",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <Text fontSize={15} color="white">
                                                ▶ {t("show")}
                                            </Text>
                                        </summary>
                                        {isChromium && (
                                            <iframe
                                                style={{
                                                    marginTop: 4,
                                                    borderRadius: 5
                                                }}
                                                frameBorder={0}
                                                src={identityDocBackPreviewUrl}
                                                height={300}
                                                width={200}
                                            ></iframe>
                                        )}
                                        <Button
                                            mt={2}
                                            onPress={() => {
                                                window.open(
                                                    identityDocBackPreviewUrl,
                                                    "_blank"
                                                )
                                            }}
                                        >
                                            {t("show_in_new_tab")}
                                        </Button>
                                    </details>
                                </>
                            )}
                        </Div>

                        <Div row mt={18}>
                            <Text fontSize={18} mb={6} mr={12}>
                                {t("expiration_date_id")}:{" "}
                            </Text>

                            {user?.expiringIdentityDocument && (
                                <Text fontSize={18} mb={6}>
                                    {newUser &&
                                        new Date(
                                            user?.expiringIdentityDocument
                                        ).toLocaleDateString?.()}
                                </Text>
                            )}
                        </Div>

                        <Div row>
                            <DateTimePicker
                                onChange={(e) => {
                                    const actualE = { ...e }
                                    setNewUser((_u) => ({
                                        ..._u,
                                        expiringIdentityDocument: new Date(
                                            actualE.target.value
                                        )
                                    }))
                                }}
                            />
                        </Div>
                        <Button
                            mt={12}
                            alignSelf="flex-start"
                            onPress={() => {
                                onEditSubmit(newUser)
                            }}
                        >
                            {t("edit")}
                        </Button>

                        {userRoleStorage === UserRole.MEDIA_PHOTOGRAPHER ||
                        userRoleStorage === UserRole.MEDIA_VIDEO_OPERATOR ? (
                            <>
                                <Text fontSize={18} mt={32} mb={4}>
                                    {t("doc_cause_of_death")}{" "}
                                    {docCauseOfDeathExist
                                        ? t("already_uploaded")
                                        : t("not_uploaded")}
                                </Text>
                                <Text fontSize={12} mb={6}>
                                    {t("file_format_label")}
                                </Text>

                                <Div row>
                                    <Button
                                        mr={12}
                                        onPress={() => {
                                            pickFile("*/*")
                                                .then((b64) => {
                                                    setDocCauseOfDeath(b64)
                                                    if (
                                                        userIdStorage &&
                                                        tokenStorage
                                                    ) {
                                                        uploadUserFiles(
                                                            userIdStorage,
                                                            tokenStorage,
                                                            {
                                                                [UserFiles.CAUSE_OF_DEATH]:
                                                                    b64
                                                            },
                                                            (
                                                                progressEvent: any
                                                            ) => {
                                                                const percentage =
                                                                    Math.round(
                                                                        (progressEvent.loaded *
                                                                            100) /
                                                                            progressEvent.total
                                                                    )
                                                                setCauseOfDeathDocUploadPercentage(
                                                                    percentage ==
                                                                        100
                                                                        ? undefined
                                                                        : percentage
                                                                )
                                                            }
                                                        )
                                                            .then((res) => {
                                                                if (
                                                                    res.success
                                                                ) {
                                                                    setComunicateEditResults(
                                                                        t(
                                                                            "success_uploading"
                                                                        )
                                                                    )
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                setComunicateEditResults(
                                                                    "Error: " +
                                                                        err
                                                                )
                                                            })
                                                    }
                                                })
                                                .catch((err) => {
                                                    setComunicateEditResults(
                                                        err?.message.toString?.() ||
                                                            "Errore durante il caricamente"
                                                    )
                                                })
                                        }}
                                    >
                                        {causeOfDeathDocUploadPercentage
                                            ? `${t(
                                                  "uploading"
                                              )} ${causeOfDeathDocUploadPercentage}%`
                                            : t("upload")}
                                    </Button>

                                    {causeOfDeathDocPreviewUrl && (
                                        <>
                                            <details>
                                                <summary
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(49, 130, 206)",
                                                        height: 44,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderRadius: 5,
                                                        border: 2,
                                                        padding:
                                                            "0px 12px 0px 12px",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <Text
                                                        fontSize={15}
                                                        color="white"
                                                    >
                                                        ▶ {t("show")}
                                                    </Text>
                                                </summary>
                                                {isChromium && (
                                                    <iframe
                                                        style={{
                                                            marginTop: 4,
                                                            borderRadius: 5
                                                        }}
                                                        frameBorder={0}
                                                        src={
                                                            causeOfDeathDocPreviewUrl
                                                        }
                                                        height={300}
                                                        width={200}
                                                    ></iframe>
                                                )}
                                                <Button
                                                    mt={2}
                                                    onPress={() => {
                                                        window.open(
                                                            causeOfDeathDocPreviewUrl,
                                                            "_blank"
                                                        )
                                                    }}
                                                >
                                                    {t("show_in_new_tab")}
                                                </Button>
                                            </details>
                                        </>
                                    )}
                                </Div>

                                <Div row mt={18}>
                                    <Text fontSize={18} mb={6} mr={12}>
                                        {t(
                                            "expiration_date_doc_cause_of_death"
                                        )}
                                        :{" "}
                                    </Text>

                                    {user?.expiringDateDocCauseOfDeath && (
                                        <Text fontSize={18} mb={6}>
                                            {newUser &&
                                                new Date(
                                                    user?.expiringDateDocCauseOfDeath
                                                ).toLocaleDateString?.()}
                                        </Text>
                                    )}
                                </Div>

                                <Div row>
                                    <DateTimePicker
                                        onChange={(e) => {
                                            const actualE = { ...e }
                                            setNewUser((_u) => ({
                                                ..._u,
                                                expiringDateDocCauseOfDeath:
                                                    new Date(
                                                        actualE.target.value
                                                    )
                                            }))
                                        }}
                                    />
                                    <Button
                                        alignSelf="flex-start"
                                        ml={12}
                                        onPress={() => {
                                            onEditSubmit(newUser)
                                        }}
                                    >
                                        {t("edit")}
                                    </Button>
                                </Div>

                                <Text fontSize={18} mt={32} mb={4}>
                                    {t("doc_injuries")}{" "}
                                    {docInjuriesExist
                                        ? t("already_uploaded")
                                        : t("not_uploaded")}
                                </Text>
                                <Text fontSize={12} mb={6}>
                                    {t("file_format_label")}
                                </Text>

                                <Div row>
                                    <Button
                                        mr={12}
                                        onPress={() => {
                                            pickFile("*/*")
                                                .then((b64) => {
                                                    setDocInjuries(b64)
                                                    if (
                                                        userIdStorage &&
                                                        tokenStorage
                                                    ) {
                                                        uploadUserFiles(
                                                            userIdStorage,
                                                            tokenStorage,
                                                            {
                                                                [UserFiles.INJURIES]:
                                                                    b64
                                                            },
                                                            (
                                                                progressEvent: any
                                                            ) => {
                                                                const percentage =
                                                                    Math.round(
                                                                        (progressEvent.loaded *
                                                                            100) /
                                                                            progressEvent.total
                                                                    )
                                                                setInjuriesDocUploadPercentage(
                                                                    percentage ==
                                                                        100
                                                                        ? undefined
                                                                        : percentage
                                                                )
                                                            }
                                                        )
                                                            .then((res) => {
                                                                if (
                                                                    res.success
                                                                ) {
                                                                    setComunicateEditResults(
                                                                        t(
                                                                            "success_uploading"
                                                                        )
                                                                    )
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                setComunicateEditResults(
                                                                    "Error: " +
                                                                        err
                                                                )
                                                            })
                                                    }
                                                })
                                                .catch((err) => {
                                                    setComunicateEditResults(
                                                        err?.message.toString?.() ||
                                                            "Errore durante il caricamente"
                                                    )
                                                })
                                        }}
                                    >
                                        {injuriesDocUploadPercentage
                                            ? `${t(
                                                  "uploading"
                                              )} ${injuriesDocUploadPercentage}%`
                                            : t("upload")}
                                    </Button>

                                    {injuriesDocPreviewUrl && (
                                        <>
                                            <details>
                                                <summary
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(49, 130, 206)",
                                                        height: 44,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderRadius: 5,
                                                        border: 2,
                                                        padding:
                                                            "0px 12px 0px 12px",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <Text
                                                        fontSize={15}
                                                        color="white"
                                                    >
                                                        ▶ {t("show")}
                                                    </Text>
                                                </summary>
                                                {isChromium && (
                                                    <iframe
                                                        style={{
                                                            marginTop: 4,
                                                            borderRadius: 5
                                                        }}
                                                        frameBorder={0}
                                                        src={
                                                            injuriesDocPreviewUrl
                                                        }
                                                        height={300}
                                                        width={200}
                                                    ></iframe>
                                                )}
                                                <Button
                                                    mt={2}
                                                    onPress={() => {
                                                        window.open(
                                                            injuriesDocPreviewUrl,
                                                            "_blank"
                                                        )
                                                    }}
                                                >
                                                    {t("show_in_new_tab")}
                                                </Button>
                                            </details>
                                        </>
                                    )}
                                </Div>

                                <Div row mt={18}>
                                    <Text fontSize={18} mb={6} mr={12}>
                                        {t("expiration_date_doc_injuries")}:{" "}
                                    </Text>

                                    {user?.expiringDateDocInjuries && (
                                        <Text fontSize={18} mb={6}>
                                            {newUser &&
                                                new Date(
                                                    user?.expiringDateDocInjuries
                                                ).toLocaleDateString?.()}
                                        </Text>
                                    )}
                                </Div>

                                <Div row>
                                    <DateTimePicker
                                        style={{ maxWidth: "500px" }}
                                        onChange={(e) => {
                                            const actualE = { ...e }
                                            setNewUser((_u) => ({
                                                ..._u,
                                                expiringDateDocInjuries:
                                                    new Date(
                                                        actualE.target.value
                                                    )
                                            }))
                                        }}
                                    />
                                    <Button
                                        alignSelf="flex-start"
                                        ml={12}
                                        onPress={() => {
                                            onEditSubmit(newUser)
                                        }}
                                    >
                                        {t("edit")}
                                    </Button>
                                </Div>

                                <Text fontSize={18} mt={32} mb={4}>
                                    {t("doc_rc")}{" "}
                                    {docRcExist
                                        ? t("already_uploaded")
                                        : t("not_uploaded")}
                                </Text>
                                <Text fontSize={12} mb={6}>
                                    {t("file_format_label")}
                                </Text>

                                <Div row>
                                    <Button
                                        mr={12}
                                        onPress={() => {
                                            pickFile("*/*")
                                                .then((b64) => {
                                                    setDocRc(b64)
                                                    if (
                                                        userIdStorage &&
                                                        tokenStorage
                                                    ) {
                                                        uploadUserFiles(
                                                            userIdStorage,
                                                            tokenStorage,
                                                            {
                                                                [UserFiles.RC]:
                                                                    b64
                                                            },
                                                            (
                                                                progressEvent: any
                                                            ) => {
                                                                const percentage =
                                                                    Math.round(
                                                                        (progressEvent.loaded *
                                                                            100) /
                                                                            progressEvent.total
                                                                    )
                                                                setRcDocUploadPercentage(
                                                                    percentage ==
                                                                        100
                                                                        ? undefined
                                                                        : percentage
                                                                )
                                                            }
                                                        )
                                                            .then((res) => {
                                                                if (
                                                                    res.success
                                                                ) {
                                                                    setComunicateEditResults(
                                                                        t(
                                                                            "success_uploading"
                                                                        )
                                                                    )
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                setComunicateEditResults(
                                                                    "Error: " +
                                                                        err
                                                                )
                                                            })
                                                    }
                                                })
                                                .catch((err) => {
                                                    setComunicateEditResults(
                                                        err?.message.toString?.() ||
                                                            "Errore durante il caricamente"
                                                    )
                                                })
                                        }}
                                    >
                                        {rcDocUploadPercentage
                                            ? `${t(
                                                  "uploading"
                                              )} ${rcDocUploadPercentage}%`
                                            : t("upload")}
                                    </Button>

                                    {rcDocPreviewUrl && (
                                        <>
                                            <details>
                                                <summary
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(49, 130, 206)",
                                                        height: 44,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderRadius: 5,
                                                        border: 2,
                                                        padding:
                                                            "0px 12px 0px 12px",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <Text
                                                        fontSize={15}
                                                        color="white"
                                                    >
                                                        ▶ {t("show")}
                                                    </Text>
                                                </summary>
                                                {isChromium && (
                                                    <iframe
                                                        style={{
                                                            marginTop: 4,
                                                            borderRadius: 5
                                                        }}
                                                        frameBorder={0}
                                                        src={rcDocPreviewUrl}
                                                        height={300}
                                                        width={200}
                                                    ></iframe>
                                                )}
                                                <Button
                                                    mt={2}
                                                    onPress={() => {
                                                        window.open(
                                                            rcDocPreviewUrl,
                                                            "_blank"
                                                        )
                                                    }}
                                                >
                                                    {t("show_in_new_tab")}
                                                </Button>
                                            </details>
                                        </>
                                    )}
                                </Div>

                                <Div row mt={18}>
                                    <Text fontSize={18} mb={6} mr={12}>
                                        {t("expiration_date_doc_rc")}:{" "}
                                    </Text>

                                    {user?.expiringDateDocRc && (
                                        <Text fontSize={18} mb={6}>
                                            {newUser &&
                                                new Date(
                                                    user?.expiringDateDocRc
                                                ).toLocaleDateString?.()}
                                        </Text>
                                    )}
                                </Div>

                                <Div row>
                                    <DateTimePicker
                                        style={{ maxWidth: "500px" }}
                                        onChange={(e) => {
                                            const actualE = { ...e }
                                            setNewUser((_u) => ({
                                                ..._u,
                                                expiringDateDocRc: new Date(
                                                    actualE.target.value
                                                )
                                            }))
                                        }}
                                    />
                                    <Button
                                        alignSelf="flex-start"
                                        ml={12}
                                        onPress={() => {
                                            onEditSubmit(newUser)
                                        }}
                                    >
                                        {t("edit")}
                                    </Button>
                                </Div>
                            </>
                        ) : null}

                        {user?.role != UserRole.HANDICAPPED ? (
                            <>
                                <Text fontSize={18} mb={6} mt={32}>
                                    {t("published_works")}
                                </Text>
                                <Div>
                                    <Input
                                        value={publishedDocUrlsInput}
                                        mb={6}
                                        p={10}
                                        onChangeText={(e: string) => {
                                            setPublishedDocUrlsInput(e)
                                        }}
                                    />
                                </Div>
                                <Div row>
                                    <Button
                                        alignSelf="flex-start"
                                        onPress={() => {
                                            if (publishedDocUrlsInput) {
                                                let newPublishedDocUrls =
                                                    newUser.publishedDocUrls ||
                                                    []
                                                newPublishedDocUrls.push(
                                                    publishedDocUrlsInput
                                                )
                                                setNewUser({
                                                    ...newUser,
                                                    publishedDocUrls:
                                                        newPublishedDocUrls
                                                })

                                                setPublishedDocUrlsInput("")
                                            }
                                        }}
                                    >
                                        {t("add")}
                                    </Button>

                                    <Button
                                        ml={12}
                                        alignSelf="flex-start"
                                        onPress={() => {
                                            if (newUser.publishedDocUrls) {
                                                onEditSubmit(newUser)
                                            }
                                        }}
                                    >
                                        {t("apply")}
                                    </Button>
                                </Div>
                                <Collapse mb={18}>
                                    <Collapse.Header
                                        active
                                        justifyContent="center"
                                        fontSize="md"
                                        color="black"
                                        bg="white"
                                        p="sm"
                                        px="none"
                                        borderStyle="solid"
                                        borderColor="lightgray"
                                        h={30}
                                        borderWidth={1}
                                    >
                                        {t("show_works")}
                                    </Collapse.Header>
                                    <Collapse.Body pb="xl" mb={12}>
                                        {newUser &&
                                            newUser.publishedDocUrls?.map(
                                                (url, index) => {
                                                    return (
                                                        <Div
                                                            row
                                                            mb={6}
                                                            key={index}
                                                        >
                                                            <Button
                                                                mr={6}
                                                                h={20}
                                                                w={20}
                                                                bg="#dd5a5a"
                                                                onPress={() => {
                                                                    let newPublishedDocUrls =
                                                                        newUser.publishedDocUrls ||
                                                                        []
                                                                    newPublishedDocUrls =
                                                                        newPublishedDocUrls.filter(
                                                                            (
                                                                                element
                                                                            ) => {
                                                                                return (
                                                                                    element !=
                                                                                    url
                                                                                )
                                                                            }
                                                                        )
                                                                    setNewUser({
                                                                        ...newUser,
                                                                        publishedDocUrls:
                                                                            newPublishedDocUrls
                                                                    })
                                                                }}
                                                            >
                                                                <Icon
                                                                    name="trash"
                                                                    fontFamily="FontAwesome5"
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                    color="black"
                                                                    h={20}
                                                                    w={20}
                                                                    rounded="md"
                                                                />
                                                            </Button>

                                                            <Text
                                                                key={index}
                                                                style={{
                                                                    marginBottom: 6,
                                                                    backgroundColor:
                                                                        "lightred"
                                                                }}
                                                            >
                                                                - {url}
                                                            </Text>
                                                        </Div>
                                                    )
                                                }
                                            )}
                                    </Collapse.Body>
                                </Collapse>
                            </>
                        ) : null}

                        {user?.role != UserRole.HANDICAPPED && (
                            <>
                                <Text fontSize={18} mt={14} mb={8}>
                                    {t("professional_doc")}
                                </Text>
                                <Div row>
                                    <Input
                                        w="100%"
                                        placeholder={
                                            user?.orderOfJournalistsCardNumber
                                        }
                                        p={10}
                                        focusBorderColor="blue700"
                                        mr={12}
                                        onChangeText={(e) => {
                                            if (e.length <= 200) {
                                                setNewUser((_u) => ({
                                                    ..._u,
                                                    orderOfJournalistsCardNumber:
                                                        e
                                                }))
                                            }
                                        }}
                                    />
                                </Div>
                                <Button
                                    mt={12}
                                    alignSelf="flex-start"
                                    onPress={() => {
                                        onEditSubmit(newUser)
                                    }}
                                >
                                    {t("edit")}
                                </Button>
                            </>
                        )}

                        {user && user.role !== UserRole.HANDICAPPED ? (
                            <>
                                <Text fontSize={18} mb={6} mt={32}>
                                    {t("work_entity")}
                                </Text>
                                <Div alignItems="center" row>
                                    <Picker
                                        style={{
                                            height: "48px",
                                            width: "100%",
                                            fontSize: 18,
                                            borderRadius: 5,
                                            borderColor: "lightgray"
                                        }}
                                        selectedValue={
                                            newUser.isFreelancer === true
                                                ? EntityWork.FREELANCER
                                                : EntityWork.AGENCY
                                        }
                                        onValueChange={(
                                            itemValue,
                                            itemIndex
                                        ) => {
                                            if (
                                                itemValue ===
                                                EntityWork.FREELANCER
                                            ) {
                                                setNewUser({
                                                    ...newUser,
                                                    agencyName: "",
                                                    agencyEmail: "",
                                                    isFreelancer: true
                                                })
                                            } else {
                                                setNewUser({
                                                    ...newUser,
                                                    isFreelancer: false
                                                })
                                            }
                                        }}
                                    >
                                        <Picker.Item
                                            label={t("freelance")}
                                            value={EntityWork.FREELANCER}
                                        />
                                        <Picker.Item
                                            label={t("agency")}
                                            value={EntityWork.AGENCY}
                                        />
                                    </Picker>
                                </Div>
                                <Button
                                    mt={12}
                                    alignSelf="flex-start"
                                    onPress={() => {
                                        onEditSubmit(newUser)
                                    }}
                                >
                                    {t("edit")}
                                </Button>

                                {!newUser.isFreelancer && (
                                    <>
                                        <Text fontSize={18} mb={6} mt={32}>
                                            {t("agency_name")}
                                        </Text>
                                        <Div alignItems="center" row>
                                            <Input
                                                w="100%"
                                                placeholder={
                                                    typeof user?.agencyName ===
                                                    "undefined"
                                                        ? t("unset")
                                                        : user.agencyName
                                                }
                                                p={10}
                                                focusBorderColor="blue700"
                                                mr={12}
                                                onChangeText={(e: string) => {
                                                    setNewUser((_u) => ({
                                                        ..._u,
                                                        agencyName: e
                                                    }))
                                                }}
                                            />
                                        </Div>
                                        <Button
                                            mt={12}
                                            alignSelf="flex-start"
                                            onPress={() => {
                                                onEditSubmit(newUser)
                                            }}
                                        >
                                            {t("edit")}
                                        </Button>

                                        <Text fontSize={18} mb={6} mt={32}>
                                            {t("agency_email")}
                                        </Text>

                                        <Div alignItems="center" row>
                                            <Input
                                                w="100%"
                                                placeholder={
                                                    typeof user?.agencyEmail ===
                                                    "undefined"
                                                        ? t("unset")
                                                        : user.agencyEmail
                                                }
                                                p={10}
                                                focusBorderColor="blue700"
                                                mr={12}
                                                onChangeText={(e: string) => {
                                                    setNewUser((_u) => ({
                                                        ..._u,
                                                        agencyEmail: e
                                                    }))
                                                }}
                                            />
                                        </Div>
                                        <Button
                                            mt={12}
                                            alignSelf="flex-start"
                                            onPress={() => {
                                                onEditSubmit(newUser)
                                            }}
                                        >
                                            {t("edit")}
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : null}
                    </>
                )}

                <Div row justifyContent="center">
                    <Button
                        bg="red600"
                        mt={32}
                        mr={12}
                        onPress={() => {
                            onDeleteSubmit()
                        }}
                    >
                        <Text
                            color="white"
                            fontSize={"2xl"}
                            fontWeight="bold"
                            mr={8}
                        >
                            {t("delete")}
                        </Text>
                        <Icon
                            name="trash"
                            color="white"
                            fontFamily="FontAwesome5"
                            fontSize="18px"
                        />
                    </Button>

                    <HomeButton
                        mt={32}
                        navigateHome={() => {
                            if (userRoleStorage === UserRole.ADMIN) {
                                navigation.navigate("BackOfficeHome")
                            } else {
                                navigation.navigate("Home")
                            }
                        }}
                    />
                </Div>
            </Content>
        </ScrollDiv>
    )
}

export default UserSettings
