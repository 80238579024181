import axios from "axios"
import { t } from "../strings"
import { Accreditation, Event, User } from "../types/prisma"

type RequestData<T> = T & any

// export const BASE_HOST = "https://monzapass.up.railway.app"

// export const BASE_HOST = "https://a4d1-95-235-235-104.ngrok.io"

// export const BASE_HOST = "https://tunnel.matteolobello.com"

const BASE_HOST = "https://pass.monzanet.it:3000"

export enum UserFiles {
    CAUSE_OF_DEATH = "CAUSE_OF_DEATH",
    INJURIES = "INJURIES",
    RC = "RC",
    IDENTITY_DOCUMENT_FRONT = "IDENTITY_DOCUMENT_FRONT",
    IDENTITY_DOCUMENT_BACK = "IDENTITY_DOCUMENT_BACK"
}

export const ITEMS_PER_PAGE = 30

export const refreshToken = async (userToken: string, params?: any) => {
    const res = await axios.get("/auth/refresh-token", {
        headers: { Authorization: "Bearer " + userToken }
    })
    if (!res.data.success) {
        throw res.data.error
    }
    return res.data
}

// CREDITS - Register as a user
export function signUp(
    {
        email,
        password,
        firstName,
        lastName,
        fiscalCode,
        birthDate,
        role,
        city,
        country,
        postalCode,
        address,
        phoneNumber,
        birthPlace,
        isFreelancer,
        agencyName,
        agencyEmail,
        orderOfJournalistsCardNumber,
        publishedDocUrls,
        expiringIdentityDocument,
        expiringDateDocCauseOfDeath,
        expiringDateDocInjuries,
        expiringDateDocRc
    }: User,
    tokenAtomStorage?: string
) {
    return axios({
        url: BASE_HOST + "/auth/signup",
        method: "POST",
        data: JSON.stringify({
            email,
            password,
            firstName,
            lastName,
            fiscalCode,
            birthDate,
            role,
            city,
            country,
            postalCode,
            address,
            phoneNumber,
            birthPlace,
            isFreelancer,
            agencyName,
            agencyEmail,
            orderOfJournalistsCardNumber,
            publishedDocUrls,
            expiringIdentityDocument,
            expiringDateDocCauseOfDeath,
            expiringDateDocInjuries,
            expiringDateDocRc
        }),
        headers: {
            "Content-type": "application/json",
            authorization: "Bearer " + tokenAtomStorage
        }
    }).then((res) => res.data)
}

// CREDITS - Login with a user
export function login(email: string, password: string) {
    return axios({
        url: BASE_HOST + "/auth/login",
        method: "POST",
        data: JSON.stringify({
            email,
            password
        }),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// CREDITS - Get user info
export function getUser(userId: number, tokenAtomStorage: string) {
    return axios({
        url: BASE_HOST + "/users/" + userId,
        method: "GET",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-Type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// CREDITS - Get users List
export function getUsers(tokenAtomStorage: string, params?: any) {
    return axios({
        url: BASE_HOST + "/users",
        params,
        method: "GET",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-Type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// CREDITS - Get users List
export function getUsersWithoutCompleteDocs(
    tokenAtomStorage: string,
    params?: any
) {
    return axios({
        url: BASE_HOST + "/users/files/check",
        params,
        method: "GET",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-Type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// CREDITS - Edit User
export function editUser(
    userId: number,
    tokenAtomStorage: string,
    userParameterToEdit: Partial<User>
) {
    return axios({
        method: "PATCH",
        url: BASE_HOST + `/users/${userId}`,
        data: userParameterToEdit,
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// CREDITS - Delete User
export function deleteUser(userId: number, tokenAtomStorage: string) {
    return axios({
        method: "DELETE",
        url: BASE_HOST + `/users/${userId}`,
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// FILES - Get user file
export function uploadUserFiles(
    userId: number,
    tokenAtomStorage: string,
    data: any,
    onUploadProgress: (progressEvent: any) => void
) {
    return axios({
        url: BASE_HOST + `/users/${userId}/files`,
        method: "POST",
        data: data,
        onUploadProgress,
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-Type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// FILES - Get user file
export function downloadUserFiles(
    userId: number,
    tokenAtomStorage: string,
    type: UserFiles
) {
    return axios({
        url: BASE_HOST + `/users/${userId}/files/${type}`,
        method: "GET",
        responseType: "blob",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-Type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// FILES - Get User file existance
export function getUserFileExistance(
    userId: number,
    tokenAtomStorage: string,
    type: string
) {
    return axios({
        url: BASE_HOST + `/users/${userId}/files/${type}/exists`,
        method: "GET",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-Type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// EVENTS - Create a new Event
export function createEvent(event: Event, tokenAtomStorage: string) {
    return axios({
        url: BASE_HOST + "/events",
        method: "POST",
        data: JSON.stringify(event),
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// EVENTS - Get all Events
export function getEvents(
    //select: Array<keyof Event>,
    tokenAtomStorage: string,
    params?: any
) {
    return axios({
        url: BASE_HOST + "/events",
        // + "?select=" + select.join(" "),
        method: "GET",
        params,
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// EVENTS - Get a single Event
export function getEvent(eventId: number, tokenAtomStorage: string) {
    return axios({
        url: BASE_HOST + "/events/" + eventId,
        method: "GET",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// EVENTS - Edit Event by User Join
export function editEvent(
    eventId: number,
    tokenAtomStorage: string,
    eventParameterToEdit: Partial<Event>
) {
    return axios({
        url: BASE_HOST + `/events/${eventId}`,
        method: "PATCH",
        data: JSON.stringify(eventParameterToEdit),
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// EVENTS - Delete Event
export function deleteEvent(eventId: number, tokenAtomStorage: string) {
    return axios({
        method: "DELETE",
        url: BASE_HOST + `/events/${eventId}`,
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// ACCREDITATIONS - Make a request for Accreditation on an Event
export function requestAccreditation(
    accreditation: RequestData<Partial<Accreditation>>,
    tokenAtomStorage: string
) {
    return axios({
        url: BASE_HOST + "/accreditations",
        method: "POST",
        data: JSON.stringify(accreditation),
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// ACCREDITATIONS - Get all Accreditations
export function getAccreditations(tokenAtomStorage: string, params?: any) {
    return axios({
        url: BASE_HOST + "/accreditations",
        method: "GET",
        params,
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// ACCREDITATIONS - Get a single Accreditation
export function getAccreditation(
    accreditationId: number,
    tokenAtomStorage: string
) {
    return axios({
        url: BASE_HOST + `/accreditations/${accreditationId}`,
        method: "GET",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// ACCREDITATIONS - Edit an Accreditation
export function editAccreditation(
    accreditationId: number,
    tokenAtomStorage: string,
    accreditationParameterToEdit: Partial<Accreditation>
) {
    return axios({
        url: BASE_HOST + `/accreditations/${accreditationId}`,
        method: "PATCH",
        data: JSON.stringify(accreditationParameterToEdit),
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// ACCREDITATIONS - Delete an Accreditation
export function deleteAccreditation(
    accreditationId: number,
    tokenAtomStorage: string
) {
    return axios({
        url: BASE_HOST + `/accreditations/${accreditationId}`,
        method: "DELETE",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// FRONTEND RESOURCES - Get Event Images
export function getEventImage(eventId: number, typeImage: string) {
    return axios({
        responseType: "blob",
        url: BASE_HOST + `/events/${eventId}/files/${typeImage}`,
        method: "GET"
    }).then((res) => {
        return new Promise<string | undefined>((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(res.data)
            reader.onload = () => {
                const imageDataUrl = reader.result
                resolve(imageDataUrl as string | undefined)
            }
        })
    })
}

// FRONTEND RESOURCES - Set Event Images
export function uploadEventFiles(
    eventId: number,
    tokenAtomStorage: string,
    file: string,
    onUploadProgress: (progressEvent: any) => void
) {
    return axios({
        url: BASE_HOST + `/events/${eventId}/files`,
        method: "POST",
        data: JSON.stringify({ EVENT_IMAGE: file }),
        onUploadProgress,
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// FRONTEND RESOURCES - Make a Search Request with a minimum of 3 letters query among users and/or events
export function getSearch(
    searchTypes: string,
    query: string,
    tokenAtomStorage: string
) {
    return axios({
        url: BASE_HOST + `/search?types=${searchTypes}&q=${query}`,
        method: "GET",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// FRONTEND NOTIFICATIONS - Take informations about notifications from server
export function getNotifications(tokenAtomStorage: string) {
    return axios({
        url: BASE_HOST + `/notifications`,
        method: "GET",
        headers: {
            authorization: "Bearer " + tokenAtomStorage,
            "Content-type": "application/json"
        }
    })
        .then((res) => res.data)
        .catch((error) =>
            Promise.reject(error.response.data?.error || t("unknown_error"))
        )
}

// CREDITS - Forgot password request
export function forgotPasswordRequest(email: string) {
    return fetch(BASE_HOST + "/auth/forgot-password", {
        method: "POST",
        body: JSON.stringify({
            email
        }),
        headers: new Headers({
            "Content-type": "application/json"
        })
    }).then((res) => res.json())
}

// CREDITS - Create new password
export function createNewPassword(password: string, token: string) {
    return fetch(BASE_HOST + "/auth/forgot-password/update", {
        method: "POST",
        body: JSON.stringify({
            password
        }),
        headers: new Headers({
            authorization: "Bearer " + token,
            "Content-type": "application/json"
        })
    }).then((res) => res.json())
}

// Search - Search Request
export const search = async (
    userToken: string,
    query: string,
    types: Array<"events" | "users">
) => {
    const res = await axios.get(BASE_HOST + "/search", {
        params: {
            q: query,
            types: types.join(",")
        },
        headers: { Authorization: "Bearer " + userToken }
    })
    if (!res.data.success) {
        throw res.data.error
    }
    return res.data
}

// EMAIL - Call Server for send email
export const sendEmail = async (
    token: string,
    msg: string,
    subject: string,
    userIds: number[]
) => {
    const res = await axios.post(
        BASE_HOST + "/email",
        { message: msg, subject, userIds: userIds },
        {
            headers: { Authorization: "Bearer " + token }
        }
    )
    if (!res.data.success) {
        throw res.data.error
    }
    return res.data
}
