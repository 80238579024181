import React from "react"
import { LayoutChangeEvent, View } from "react-native"
import useGridStyles from "./styles"

interface IProps {
    children: React.ReactNode
    rowStyles?: any
    onLayout?: (e: LayoutChangeEvent) => void
}

const Row = ({ children, rowStyles, onLayout }: IProps) => {
    const gridStyles = useGridStyles()

    return (
        <View onLayout={onLayout} style={[gridStyles.row, rowStyles]}>
            {children}
        </View>
    )
}

export default Row
