import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import { Div, Input, Text } from "react-native-magnus"
import { getSearch } from "../api/fetches"
import { tokenAtomStorage } from "../context/atom"
import { Event, User } from "../types/prisma"
import { toCapitalCase } from "../utils/capitalCaser"

interface IProps {
    searchTypes: string
    style?: any
    onSelect: (x: Partial<User> | Partial<Event>) => void
}

// barra di ricerca usata negli input
const SearchInput = ({ style, searchTypes, onSelect }: IProps) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)

    const [query, setQuery] = useState<string>()
    const [queryResult, setQueryResult] = useState<{
        events?: Partial<Event>[]
        users?: Partial<User>[]
    }>({})

    const [selectedValue, setSelectedValue] = useState<string>()

    useEffect(() => {
        if (
            !tokenStorage ||
            !query ||
            query.length < 3 ||
            query == selectedValue
        ) {
            setQueryResult({})
            return
        }

        getSearch(searchTypes, query, tokenStorage).then((res) => {
            if (res.success) {
                setQueryResult(res.data)
            } else {
                alert("Errore nel caricamento della ricerca: " + res.error)
            }
        })
    }, [query])

    return (
        <>
            <Input
                value={query}
                style={style}
                mb={18}
                placeholder={"Cerca..."}
                p={10}
                focusBorderColor="blue700"
                mr={12}
                onChangeText={(e: string) => {
                    setQuery(e)
                }}
            />

            <Div row>
                {queryResult.users &&
                    queryResult.users.map((user, index) => {
                        return (
                            <Text
                                key={index}
                                mt={6}
                                mb={12}
                                px={6}
                                py={6}
                                onPress={() => {
                                    onSelect(user)
                                    setQuery(user.email)
                                    setSelectedValue(user.email)
                                }}
                                color="black"
                                fontSize={16}
                                textAlignVertical="center"
                                style={{
                                    borderRadius: 5,
                                    alignItems: "center",
                                    marginRight: 8
                                }}
                                bg="#dd5a5a"
                            >
                                {user.email && toCapitalCase(user.email)}
                            </Text>
                        )
                    })}
            </Div>

            <Div row>
                {queryResult.events &&
                    queryResult.events.map((event, index) => {
                        return (
                            <Text
                                key={index}
                                mt={6}
                                mb={12}
                                px={6}
                                py={6}
                                onPress={() => {
                                    onSelect(event)
                                    setQuery(event.name)
                                    setSelectedValue(event.name)
                                }}
                                color="black"
                                fontSize={16}
                                textAlignVertical="center"
                                style={{
                                    borderRadius: 5,
                                    alignItems: "center",
                                    marginRight: 8
                                }}
                                bg="#dd5a5a"
                            >
                                {event.name && toCapitalCase(event.name)}
                            </Text>
                        )
                    })}
            </Div>
        </>
    )
}

export default SearchInput
