import { NativeStackScreenProps } from "@react-navigation/native-stack"
import React from "react"
import { Button, Div, Text } from "react-native-magnus"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import { t } from "../../../strings"

export type ThanksPageProps = undefined

type ThanksPageScreenRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "ThanksPage"
>

export const ThanksPage = ({ navigation }: ThanksPageScreenRouteProp) => {
    return (
        <Content
            style={{
                height: "100vh"
            }}
        >
            <Div
                alignItems="center"
                justifyContent="center"
                pl={"15vw"}
                pr="15vw"
                h={"100vh"}
            >
                <Text mb={36} fontSize={72}>
                    ✅
                </Text>
                <Text mb={36} fontSize={16} textAlign={"center"}>
                    {t("thanks_page_text")}
                </Text>

                <Button
                    alignSelf="center"
                    mb={12}
                    onPress={() => {
                        navigation.navigate("UserSettings")
                    }}
                >
                    {t("go_to_profile")}
                </Button>

                <Button
                    alignSelf="center"
                    onPress={() => {
                        navigation.navigate("Home")
                    }}
                >
                    {t("go_to_home")}
                </Button>
            </Div>
        </Content>
    )
}
