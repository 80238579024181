import { useAtom } from "jotai"
import { useEffect } from "react"
import { userRoleAtomStorage } from "../context/atom"
import { UserRole } from "../types/prisma"

export default function useRoleVerifier() {
    const [userRoleStorage] = useAtom(userRoleAtomStorage)

    useEffect(() => {
        if (userRoleStorage !== UserRole.ADMIN) {
            location.href = "https://monzanet.it"
        }
    }, [])
}
