import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useState } from "react"
import { Button, Div, Icon, Input, Overlay, Text } from "react-native-magnus"
import { sendEmail } from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import HomeButton from "../../../components/HomeButton"
import { tokenAtomStorage } from "../../../context/atom"
import useRoleVerifier from "../../../hooks/useRoleVerifier"

interface IProps {
    eventName: string
    eventStartTime: Date
    eventEndTime: Date
    userIds: number[]
}

export type BackOfficeClosingAccreditationRefusedProps = IProps

type BackOfficeClosingAccreditationRefusedRouteProp = NativeStackScreenProps<
    RootStackParamList,
    "BackOfficeClosingAccreditationRefused"
>

const BackOfficeClosingAccreditation = ({
    navigation,
    route
}: BackOfficeClosingAccreditationRefusedRouteProp) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)

    const [dialog, setDialog] = useState<string>()

    const [standardRefuseEmailObject, setStandardRefuseEmailObject] =
        useState<string>(
            `Rifiuto Richiesta di Accredito per l'evento "${route.params.eventName}"`
        )

    const [standardRefuseEmailText, setStandardRefuseEmailText] =
        useState<string>(`Buongiorno, con la presente siamo spiacenti di informarLa che il suo accredito per l'evento "${
            route.params.eventName
        }", in programma nei giorni ${new Date(
            route.params.eventStartTime
        ).toLocaleDateString?.()} - ${new Date(
            route.params.eventEndTime
        ).toLocaleDateString?.()}, all'Autodromo
Nazionale Monza, non è stato accettato. Ai sensi delle normative vigenti e dello spazio limitato a nostra disposizione, siamo stati costretti ad effettuare una selezione degli accessi, tra le numerose domande di accredito pervenute per l’evento. 
Certi che comprenderà la particolare situazione, speriamo di poterLa nuovamente accogliere nei prossimi eventi con accredito stampa. 
Cordialmente`)

    useRoleVerifier()

    return (
        <>
            <Content>
                <Overlay
                    visible={Boolean(dialog)}
                    p="xl"
                    w={"80%"}
                    style={{
                        maxHeight: "60vh",
                        maxWidth: 350
                    }}
                >
                    <Div>
                        <Div
                            row
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text mr={12} fontSize={18} fontWeight="bold">
                                Avviso
                            </Text>
                            <Button
                                bg="transparent"
                                alignSelf="flex-end"
                                onPress={() => {
                                    setDialog(undefined)
                                }}
                            >
                                <Icon
                                    name="times"
                                    color="#424242"
                                    fontFamily="FontAwesome5"
                                    fontSize="16px"
                                />
                            </Button>
                        </Div>
                    </Div>
                    <Div mt={24} justifyContent="center" alignItems="center">
                        <Text w={"100%"} textAlign="center">
                            {dialog}
                        </Text>
                    </Div>
                </Overlay>

                <Text fontSize={48} mt={36} fontWeight="bold">
                    Chiusura Accrediti
                </Text>

                <Div mt={12}>
                    <Text fontSize={24} fontWeight="bold" mt={12}>
                        Email di RIFIUTO
                    </Text>

                    <Text fontSize={18} mt={12}>
                        Oggetto
                    </Text>
                    <Input
                        pr={24}
                        pl={24}
                        numberOfLines={1}
                        mt={12}
                        fontSize={12}
                        defaultValue={standardRefuseEmailObject}
                        onChangeText={(e) => {
                            setStandardRefuseEmailObject(e)
                        }}
                    />

                    <Text fontSize={18} mt={12}>
                        Corpo
                    </Text>
                    <Input
                        pr={24}
                        pl={24}
                        multiline
                        numberOfLines={10}
                        mt={12}
                        fontSize={12}
                        h={200}
                        defaultValue={standardRefuseEmailText}
                        onChangeText={(e) => {
                            setStandardRefuseEmailText(e)
                        }}
                    />
                </Div>

                <Button
                    alignSelf="center"
                    mt={12}
                    mb={12}
                    onPress={() => {
                        if (tokenStorage && route.params.userIds) {
                            sendEmail(
                                tokenStorage,
                                standardRefuseEmailText,
                                standardRefuseEmailObject,
                                route.params.userIds
                            )
                            setDialog("Email inviate con successo")
                            navigation.navigate("BackOfficeAccreditationTable")
                        }
                    }}
                >
                    Chiudi Accredito Rifiutati ed Invia Email
                </Button>

                <HomeButton
                    mb={24}
                    alignSelf="center"
                    navigateHome={() => {
                        navigation.navigate("BackOfficeHome")
                    }}
                />
            </Content>
        </>
    )
}

export default BackOfficeClosingAccreditation
