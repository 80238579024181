export const downloadFile = (
    data: any,
    fileName: string,
    blobOpt?: BlobPropertyBag
) => {
    const blob = new Blob([data], blobOpt)
    const link = document.createElement("a")
    if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute("href", url)
        link.setAttribute("download", fileName)
        link.style.visibility = "hidden"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}
