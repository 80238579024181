import { Picker } from "@react-native-picker/picker"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAtom } from "jotai"
import React, { useEffect, useState } from "react"
import {
    Button,
    Checkbox,
    Div,
    Icon,
    Input,
    Overlay,
    ScrollDiv,
    Text
} from "react-native-magnus"
import {
    editAccreditation,
    getAccreditation,
    requestAccreditation
} from "../../../api/fetches"
import { RootStackParamList } from "../../../App"
import Content from "../../../components/Content"
import HomeButton from "../../../components/HomeButton"
import SearchInput from "../../../components/SearchInput"
import { isLoaderVisibleAtom, tokenAtomStorage } from "../../../context/atom"
import useRoleVerifier from "../../../hooks/useRoleVerifier"
import { Accreditation, AccreditationStatus } from "../../../types/prisma/index"

interface IProps {
    accreditationId?: number
}

export type BackOfficeAccreditationManagementTableProps = IProps

type BackOfficeAccreditationManagementTableScreenRouteProp =
    NativeStackScreenProps<
        RootStackParamList,
        "BackOfficeAccreditationManagement"
    >
const BackOfficeAccreditationManagement = ({
    navigation,
    route
}: BackOfficeAccreditationManagementTableScreenRouteProp) => {
    const [tokenStorage] = useAtom(tokenAtomStorage)

    const [newAccreditation, setNewAccreditation] = useState<
        Partial<Accreditation>
    >({ status: AccreditationStatus.PENDING, hasRequestedBib: false })
    const [hasRequestedBib, setHasRequestedBib] = useState<boolean>(false)

    const [dialog, setDialog] = useState<string>()

    const [_, setIsLoaderVisible] = useAtom(isLoaderVisibleAtom)

    useEffect(() => {
        if (route.params.accreditationId && tokenStorage) {
            setIsLoaderVisible(true)

            getAccreditation(route.params.accreditationId, tokenStorage)
                .then((res) => {
                    setNewAccreditation(res.data)
                })
                .finally(async () => {
                    await new Promise((res) => setTimeout(res, 1000))
                    setIsLoaderVisible(false)
                })
        }
    }, [route.params.accreditationId])

    useRoleVerifier()

    return (
        <>
            <ScrollDiv>
                <Content>
                    <Overlay
                        visible={Boolean(dialog)}
                        p="xl"
                        w={"80%"}
                        style={{
                            maxHeight: "60vh",
                            maxWidth: 350
                        }}
                    >
                        <Div>
                            <Div
                                row
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text mr={12} fontSize={18} fontWeight="bold">
                                    Avviso
                                </Text>
                                <Button
                                    bg="transparent"
                                    alignSelf="flex-end"
                                    onPress={() => {
                                        if (
                                            dialog ===
                                                "Richiesta di Accredito creata con Successo" ||
                                            dialog ===
                                                "Accredito Modificato con Successo"
                                        ) {
                                            setDialog(undefined)
                                            navigation.replace(
                                                "BackOfficeAccreditationTable"
                                            )
                                        } else {
                                            setDialog(undefined)
                                        }
                                    }}
                                >
                                    <Icon
                                        name="times"
                                        color="#424242"
                                        fontFamily="FontAwesome5"
                                        fontSize="16px"
                                    />
                                </Button>
                            </Div>
                        </Div>
                        <Div
                            mt={24}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Text w={"100%"} textAlign="center">
                                {dialog}
                            </Text>
                        </Div>
                    </Overlay>

                    <Text fontSize={52} fontWeight="bold" my={24}>
                        {route.params.accreditationId
                            ? "Modifica Accredito"
                            : "Crea Nuovo Accredito"}
                    </Text>

                    <Text fontSize={24} mb={6}>
                        Note
                    </Text>
                    <Input
                        mb={18}
                        mr={12}
                        p={10}
                        value={newAccreditation.notes}
                        placeholder="Inserisci Note"
                        focusBorderColor="blue700"
                        onChangeText={(e: string) => {
                            if (e.length <= 250) {
                                setNewAccreditation({
                                    ...newAccreditation,
                                    notes: e
                                })
                            }
                        }}
                    />

                    {!route.params.accreditationId && (
                        <>
                            <Text fontSize={24} mb={6}>
                                Email Utente
                            </Text>

                            <SearchInput
                                searchTypes="users"
                                onSelect={(u) => {
                                    setNewAccreditation({
                                        ...newAccreditation,
                                        userId: u.id
                                    })
                                }}
                            />
                        </>
                    )}

                    {!route.params.accreditationId && (
                        <>
                            <Text fontSize={24} mb={6}>
                                Nome Evento
                            </Text>

                            <SearchInput
                                searchTypes="events"
                                onSelect={(e) => {
                                    setNewAccreditation({
                                        ...newAccreditation,
                                        eventId: e.id
                                    })
                                }}
                            />
                        </>
                    )}

                    <Text fontSize={24} mb={6}>
                        Stato
                    </Text>

                    <Picker
                        style={{ marginBottom: "18px", marginRight: "12px" }}
                        selectedValue={newAccreditation.status}
                        onValueChange={(itemValue, itemIndex) => {
                            setNewAccreditation({
                                ...newAccreditation,
                                status: itemValue
                            })
                        }}
                    >
                        <Picker.Item
                            label="Approvato"
                            value={AccreditationStatus.APPROVED}
                        />
                        <Picker.Item
                            label="In Attesa"
                            value={AccreditationStatus.PENDING}
                        />
                        <Picker.Item
                            label="Rifiutato"
                            value={AccreditationStatus.DENIED}
                        />
                        <Picker.Item
                            label="Aggiornamenti Necessari da parte dell'Utente"
                            value={AccreditationStatus.NEEDS_USER_UPDATE}
                        />
                    </Picker>

                    {!route.params.accreditationId && (
                        <>
                            <Text fontSize={24} mb={6}>
                                Richiede Pettorina?
                            </Text>

                            <Div>
                                <Div>
                                    <Checkbox
                                        value={1}
                                        checked={hasRequestedBib}
                                        prefix={<Text flex={1}>Si</Text>}
                                        onChange={(e) => {
                                            setHasRequestedBib(true)
                                            setNewAccreditation({
                                                ...newAccreditation,
                                                hasRequestedBib: true
                                            })
                                        }}
                                    />
                                    <Checkbox
                                        value={2}
                                        checked={!hasRequestedBib}
                                        prefix={<Text flex={1}>No</Text>}
                                        onChange={(e) => {
                                            setHasRequestedBib(false)
                                            setNewAccreditation({
                                                ...newAccreditation,
                                                hasRequestedBib: false
                                            })
                                        }}
                                    />
                                </Div>
                            </Div>
                        </>
                    )}

                    <Div row justifyContent="center" mt={24}>
                        <Button
                            fontWeight="bold"
                            fontSize="2xl"
                            alignSelf="center"
                            mb={24}
                            style={{ marginLeft: 6, marginRight: 6 }}
                            onPress={() => {
                                if (route.params.accreditationId) {
                                    if (
                                        newAccreditation.status &&
                                        tokenStorage
                                    ) {
                                        editAccreditation(
                                            route.params.accreditationId,
                                            tokenStorage,
                                            newAccreditation
                                        )
                                            .then((res) => {
                                                if (res.success) {
                                                    setDialog(
                                                        "Accredito Modificato con Successo"
                                                    )
                                                } else {
                                                    setDialog(
                                                        "Error: " + res.error
                                                    )
                                                }
                                            })
                                            .catch((err) => {
                                                setDialog("Error: " + err)
                                            })
                                    }
                                } else {
                                    if (
                                        newAccreditation.status &&
                                        newAccreditation.userId &&
                                        newAccreditation.eventId &&
                                        tokenStorage
                                    ) {
                                        let data = {
                                            userId: newAccreditation.userId,
                                            eventId: newAccreditation.eventId
                                        }
                                        delete newAccreditation.userId
                                        delete newAccreditation.eventId
                                        requestAccreditation(
                                            {
                                                ...newAccreditation,
                                                user: {
                                                    connect: {
                                                        id: data.userId
                                                    }
                                                },
                                                event: {
                                                    connect: {
                                                        id: data.eventId
                                                    }
                                                }
                                            },
                                            tokenStorage
                                        )
                                            .then((res) => {
                                                if (res.success) {
                                                    setDialog(
                                                        "Richiesta di Accredito creata con Successo"
                                                    )
                                                } else {
                                                    setDialog(
                                                        "Errore nella creazione della Richiesta di Accredito: " +
                                                            res.error
                                                    )
                                                }
                                            })
                                            .catch((err) => {
                                                setDialog("Errore: " + err)
                                            })
                                    } else {
                                        setDialog(
                                            "E' necessario compilare tutti i campi prima di procedere con la conferma"
                                        )
                                    }
                                }
                            }}
                        >
                            Conferma
                        </Button>

                        <HomeButton
                            ml={12}
                            mb={24}
                            navigateHome={() => {
                                navigation.navigate("BackOfficeHome")
                            }}
                        />
                    </Div>
                </Content>
            </ScrollDiv>
        </>
    )
}

export default BackOfficeAccreditationManagement
